import { gql } from "@apollo/client";

export const dashboard_query = gql`
  query getSocietyDashboard($area_id: String, $id: String) {
    getSocietyDashboard(area_id: $area_id, id: $id) {
      society {
        society_name
        total_members
        active_members
        inactive_members
        closed_members
        pending_transaction
        share_capital_balance
        sb_account_balance
        suspense_cr_balance
        suspense_dr_balance
        sundry_creditors_balance
        sundry_debtors_balance
        total_society_count
        cash_balance
        bank_balance
      }
      deposit {
        id
        name
        code
        deposit_count
        deposit_accumulated_amount
      }
      loan {
        id
        name
        code
        loan_request_count
        amount
        outstanding
        overdue
      }
      overdue {
        overdue_group
        surety_loan_overdue
        jewel_loan_overdue
        rd_loan_overdue
        fd_loan_overdue
        staff_loan_overdue
        total_overdue
      }
      fd {
        id
        name
        code
        total_deposit_amount
        total_outstanding_amount
        overdue_amount
        deposit_count
        overdue_count
        loan_count
        deposit_count
        overdue_count
        loan_count
      }
      rd {
        id
        name
        code
        total_deposit_amount
        total_outstanding_amount
        overdue_amount
        loan_count
        deposit_count
        overdue_count
      }
      jl {
        id
        name
        code
        total_loan_amount
        total_outstanding_balance
        overdue_amount
      }
      staff_loan {
        id
        name
        code
        total_loan_amount
        total_outstanding_balance
        overdue_amount
        society_count
        loan_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_cash_transfer = gql`
  mutation cashTransfer($gl_id: String!, $amount: Float!, $type: String!) {
    cashTransfer(gl_id: $gl_id, amount: $amount, type: $type) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_back_account_account_transfer = gql`
  mutation bankAccountToAccountTransfer(
    $from_gl_id: String!
    $to_gl_id: String!
    $amount: Float!
  ) {
    bankAccountToAccountTransfer(
      from_gl_id: $from_gl_id
      to_gl_id: $to_gl_id
      amount: $amount
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_data_migration_dashboard = gql`
  query getDataMigrationDashboard($district_ids: [String]) {
    getDataMigrationDashboard(district_ids: $district_ids) {
      total_society
      not_started_process
      live_society_count
      testing_society_count
      accounting_date_live_society_count
      uploaded_without_error_count
      uploaded_with_error_count
      uploaded_with_od_error_count
      requested_to_revert_count
      error {
        message
      }
    }
  }
`;

export const get_migration_districts = gql`
  query {
    getDistricts {
      items {
        id
        name
      }
      error {
        message
      }
    }
  }
`;

export const get_migration_dashboard_district_wise_list_query = gql`
  query getReport($type: String) {
    getReport(type: $type) {
      items {
        s_no
        district_name
        total_society_count
        accounting_date_live_society_count
        testing_society_count
        not_started_process
        live_society_count
        uploaded_without_error_count
        uploaded_with_error_count
        uploaded_manual_error_count
        avg_days_to_complete
        avg_day_to_verify
        error {
          message
        }
      }
    }
  }
`;

export const get_migration_society_list_query = gql`
  query getMigrationSocieties(
    $search_string: String
    $district_id: String
    $status: String
    $page_number: Int
    $page_limit: Int
  ) {
    getMigrationSocieties(
      search_string: $search_string
      district_id: $district_id
      status: $status
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        district_name
        username
      }
      pagination {
        page_limit
        total_count
      }
    }
  }
`;

export const get_migration_society_list_download_excel_query = gql`
  query getMigrationSocieties(
    $search_string: String
    $district_id: String
    $status: String
    $page_number: Int
    $page_limit: Int
    $from_date: String
    $to_date: String
  ) {
    getMigrationSocietiesDownloadExcel: getMigrationSocieties(
      search_string: $search_string
      district_id: $district_id
      status: $status
      page_number: $page_number
      page_limit: $page_limit
      from_date: $from_date
      to_date: $to_date
    ) {
      items {
        id
        name
        district_name
        username
      }
      pagination {
        page_limit
        total_count
      }
    }
  }
`;
