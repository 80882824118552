import { gql } from "@apollo/client";

export const get_all_recurring_deposits = gql`
  query getRecurringDeposits(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $recurring_deposit_product_id: String
    $member_id: String
  ) {
    getRecurringDeposits(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      recurring_deposit_product_id: $recurring_deposit_product_id
      member_id: $member_id
    ) {
      items {
        id
        amount

        request_number
        deposit_request_number
        tenure_in_months
        current_accumulated_amount
        demand_validity
        arrear_amount
        deposit_number
        opening_date
        maturity_date
        rate_of_interest
        nominee_id
        status
        recurring_deposit_product {
          id
          name
          code
          max_percentage_of_loan_on_deposit
          rate_of_interest
          is_loan_on_deposit_allowed
        }
        nominee {
          id
          name
          mobile_number
          relationship
        }
        member {
          id
          name
          member_number
        }
        rd_loans {
          id
          loan_number
          interest_paid_so_far
          penal_interest_paid_so_far
          status
          tenure_in_months
          rd_accumulated_amount
          requested_amount
          eligible_amount
          sanctioned_amount
          current_outstanding_amount
          sanction_date
          last_payment_date
          maturity_date
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_all_recurring_deposits_without_pagination = gql`
  query getRecurringDeposits(
    $search_string: String
    $recurring_deposit_product_id: String
    $member_id: String
  ) {
    recurring_deposits_without_pagination: getRecurringDeposits(
      search_string: $search_string
      recurring_deposit_product_id: $recurring_deposit_product_id
      member_id: $member_id
    ) {
      items {
        id
        amount
        request_number
        deposit_request_number
        tenure_in_months
        current_accumulated_amount
        demand_validity
        arrear_amount
        deposit_number
        opening_date
        maturity_date
        rate_of_interest
        nominee_id
        recurring_deposit_product {
          id
          name
          code
          max_percentage_of_loan_on_deposit
          rate_of_interest
          is_loan_on_deposit_allowed
        }
        nominee {
          id
          name
          mobile_number
          relationship
        }
        member {
          id
          name
          member_number
        }
        rd_loans {
          id
          loan_number
          status
          rd_accumulated_amount
          sanctioned_amount
          current_outstanding_amount
          sanction_date
          maturity_date
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const get_one_recurring_deposit = gql`
  query getRecurringDeposit($id: String!) {
    getRecurringDeposit(id: $id) {
      id
      amount
      request_number
      deposit_request_number
      tenure_in_months
      current_accumulated_amount
      ob_accumulated_amount
      demand_validity
      arrear_amount
      ob_arrear_amount
      last_deposited_date
      total_generated_interest
      current_unpaid_interest
      ob_unpaid_interest
      last_interest_accrued_date
      total_paid_out_interest
      last_paid_out_interest
      last_interest_paid_date
      ob_last_interest_paid_date
      number
      deposit_number
      opening_date
      maturity_date
      rate_of_interest
      penal_interest_percentage
      created_by_journal_entry_id
      transaction_id
      maker_id
      checker_id
      checker_status
      is_deleted
      status
      member {
        id
        name
        member_number
      }
      transaction {
        id
        journal_entry_lineitem_id
      }
      nominee {
        id
        name
        mobile_number
        address
        relationship
      }
      recurring_deposit_product {
        id
        name
        penal_interest_percentage
        rate_of_interest
      }
      rd_loans {
        id
        loan_number
        tenure_in_months
        rd_accumulated_amount
        requested_amount
        eligible_amount
        sanctioned_amount
        current_outstanding_amount
        sanction_date
        maturity_date
        last_payment_date
        penal_interest_paid_so_far
      }
    }
  }
`;

export const create_recurring_deposit = gql`
  mutation createRecurringDeposit($json: create_recurring_deposit_input) {
    createRecurringDeposit(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_recurring_deposit = gql`
  mutation updateRecurringDepositLoan(
    $json: update_recurring_deposit_loan_input
  ) {
    updateRecurringDepositLoan(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_recurring_deposit = gql`
  mutation createFdLoan($json: create_recurring_deposit_loan_input) {
    deleteFdLoan(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_recurring_interest_payable_sheet = gql`
  query getAllRecurringDepositInterestPayout(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $member_id: String
    $recurring_deposit_product_id: String
  ) {
    getAllRecurringDepositInterestPayout(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      member_id: $member_id
      recurring_deposit_product_id: $recurring_deposit_product_id
    ) {
      items {
        recurring_deposit_id
        data {
          id
          recurring_deposit {
            id
            deposit_number
            current_accumulated_amount
            member {
              id
              name
              member_number
            }
          }
          projected_interest_payable
          payable_date
          recorded_date
          maturity_date
          status
          checker_status
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;
export const mutation_create_recurring_deposit_interest_payout = gql`
  mutation createRecurringDepositInterestPayout(
    $json: create_recurring_deposit_interest_payout_input
  ) {
    createRecurringDepositInterestPayout(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const recurring_interest_disbursement_list = gql`
  query getInterestDisbursementList(
    $search_string: String
    $page_number: Int
    $page_limit: Int
    $member_id: String
    $recurring_deposit_product_id: String
  ) {
    getInterestDisbursementList(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
      member_id: $member_id
      recurring_deposit_product_id: $recurring_deposit_product_id
    ) {
      items {
        deposit_amount
        recurring_deposit_product_id
        member_id
        nominee_id
        tenure_in_days
        nominee {
          name
          mobile_number
          address
          member_id
          relationship
        }
        payment_to
      }
    }
  }
`;

export const createRecurringDepositLoanMutation = gql`
  mutation createRecurringDepositLoan(
    $json: create_recurring_deposit_loan_input
  ) {
    createRecurringDepositLoan(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_rd_loan_interest = gql`
  query getRDLoanInterest($id: String!) {
    getRDLoanInterest(id: $id) {
      interest
      penal_interest
    }
  }
`;

export const get_recurring_deposit_loan_pre_summary = gql`
  query getRDLoanPrePaymentSummary(
    $json: recurring_deposit_prepayment_summary_input
  ) {
    getRDLoanPrePaymentSummary(json: $json) {
      fd_loan {
        id
        sanctioned_amount
        current_outstanding_amount
      }
      prepayment_history {
        amount
        payment_towards
      }
    }
  }
`;

export const get_rd_loan_make_payment = gql`
  mutation createRDLoanPayment($json: rd_loan_payment_input) {
    createRDLoanPayment(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_rd_payment_history = gql`
  query getRDLoanHistory($id: String) {
    getRDLoanHistory(id: $id) {
      items {
        reference_name
        gl_name
        receipttype
        credit_debit
        entry_time
        amount
        sub_account_balance
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const recurring_deposit_interest_payout_history = gql`
  query getFDInterestPayoutHistory($id: String) {
    getFDInterestPayoutHistory(id: $id) {
      items {
        reference_name
        gl_name
        receipt_type
        credit_debit
        amount
        sub_account_balance
        entry_time
      }
    }
  }
`;

export const recurring_deposit_closure_summary = gql`
  query getRecurringDepositClosureSummary($id: String) {
    getRecurringDepositClosureSummary(id: $id) {
      id
      type
      error
      interest_payment_frequency
      current_accumulated_amount
      compounding_frequency
      deposit_amount
      tenure
      tenure_type
      deposit_number
      opening_date
      maturity_date
      rate_of_interest
      tenure_in_months
      payable_interest
      payable_amount
      rd_loan_payable
      rd_loan_interest
      rd_loan_outstanding_amount
      rd_loan_prepayment_summary {
        amount
        payment_towards
      }
    }
  }
`;

export const recurring_deposit_closure_mutation = gql`
  mutation recurringDepositClosure(
    $id: String
    $payment_to: String
    $loans_to_clear: [String]
  ) {
    recurringDepositClosure(
      id: $id
      payment_to: $payment_to
      loans_to_clear: $loans_to_clear
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const recurring_deposit_renewal_mutation = gql`
  mutation recurringDepositRenewal(
    $id: String
    $payment_to: String
    $is_interest_included: Boolean
  ) {
    recurringDepositRenewal(
      id: $id
      payment_to: $payment_to
      is_interest_included: $is_interest_included
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
