import layout1 from "@views/layouts/layout1";
import HomeLayout from "@views/layouts/homelayout";
import AccessRights from "@views/components/access_rights/access_rights";

import Dashboard from "@views/pages/dashboard/dashboard";
import Login from "@views/pages/sign_in";
import ChangeMemberPassword from "@views/pages/member_login/member_profile/change_password";
import MemberLedger from "@views/pages/member_login/member_ledger/member_ledger";
import MemberProfile from "@views/pages/member_login/member_profile";
import AreaList from "@views/pages/master_data/area/area_list";
import AreaDetails from "@views/pages/master_data/area/area_details";
import AssetList from "@views/pages/asset/asset_list";
import AssetDetails from "@views/pages/asset/asset_details";
import AssetCategoryList from "@views/pages/asset_category/asset_category_list";
import AssetCategoryDetails from "@views/pages/asset_category/asset_category_details";
import DivisionList from "@views/pages/division/division_list";
import DivisionDetails from "@views/pages/division/division_details";
import ExpenseCategoryList from "@views/pages/expense_category/expense_category_list";
import ExpenseCategoryDetails from "@views/pages/expense_category/expense_category_details";
import GlAccountList from "@views/pages/gl_accounts/gl_accounts_list";
import GlAccountDetails from "@views/pages/gl_accounts/gl_accounts_details";
import GlAccountGroupList from "@views/pages/master_data/gl_accounts_group/gl_accounts_group_list";
import GlAccountGroupDetails from "@views/pages/master_data/gl_accounts_group/gl_accounts_group_details";
import LevelList from "@views/pages/master_data/level/level_list";
import LevelDetails from "@views/pages/master_data/level/level_details";
import PaymentModeList from "@views/pages/payment_mode/payment_mode_list";
import PaymentModeDetails from "@views/pages/payment_mode/payment_mode_details";
import ReligionList from "@views/pages/master_data/religion/religion_list";
import ReligionDetails from "@views/pages/master_data/religion/religion_details";
import SubDivisionList from "@views/pages/sub_division/sub_division_list";
import SubDivisionDetails from "@views/pages/sub_division/sub_division_details";
import VendorList from "@views/pages/vendor/vendor_list";
import VendorDetails from "@views/pages/vendor/vendor_details";
import VendorCategoryList from "@views/pages/vendor_category/vendor_category_list";
import VendorCategoryDetails from "@views/pages/vendor_category/vendor_category_details";
import Redirect from "@views/pages/redirect/redirect";

import SocietyList from "@views/pages/society/society_list";
import SocietyTab from "@views/pages/society/society_tab";
import BankList from "@views/pages/bank/bank_list";
import BankDetails from "@views/pages/bank/bank_details";
import CommunityList from "@views/pages/master_data/community/community_list";
import CommunityDetails from "@views/pages/master_data/community/community_details";
import DesignationList from "@views/pages/designation/designation_list";
import DesignationDetails from "@views/pages/designation/designation_details";
import ChangePassword from "@views/pages/change_password";

import SocietyUserList from "@views/pages/society_user/society_user_list";
import SocietyUserDetails from "@views/pages/society_user/society_user_details";

import DepositList from "@views/pages/deposits/deposits_list";
import DepositDetails from "@views/pages/deposits/deposits_details";

import SavingAccountsList from "@views/pages/saving_accounts/saving_accounts_list";
import SavingAccountsDetails from "@views/pages/saving_accounts/saving_accounts_details";

import CMSList from "@views/pages/master_data/cms/cms_list";
import CMSDetails from "@views/pages/master_data/cms/cms_details";

import Home from "@views/pages/home/home";
import About from "@views/pages/about/about";
import Contact from "@views/pages/contact/contact";

import StationeryList from "@views/pages/stationery/stationery_list";
import StationeryDetails from "@views/pages/stationery/stationery_details";

import GstSlabList from "@views/pages/gst_slab/gst_slab_list";
import GstSlabDetails from "@views/pages/gst_slab/gst_slab_details";
import StationeryUsageEntryList from "@views/pages/stationery_usage_entry/stationery_usage_entry_list";
import StationeryUsageEntryDetails from "@views/pages/stationery_usage_entry/stationery_usage_entry_details";
import StationeryUsageEntryDetailList from "@views/pages/stationery_usage_entry_detail/stationery_usage_entry_detail_list";
import StationeryUsageEntryDetailDetails from "@views/pages/stationery_usage_entry_detail/stationery_usage_entry_detail_details";
import StationeryQuotationList from "@views/pages/stationery_quotation/stationery_quotation_list";
import StationeryQuotationDetails from "@views/pages/stationery_quotation/stationery_quotation_details";
import StationeryQuotationDetailList from "@views/pages/stationery_quotation_detail/stationery_quotation_detail_list";
import StationeryQuotationDetailDetails from "@views/pages/stationery_quotation_detail/stationery_quotation_detail_details";
import StationeryPurchaseList from "@views/pages/stationery_purchase/stationery_purchase_list";
import StationeryPurchaseDetails from "@views/pages/stationery_purchase/stationery_purchase_details";
import StationeryPurchaseDetailList from "@views/pages/stationery_purchase_detail/stationery_purchase_detail_list";
import StationeryPurchaseDetailDetails from "@views/pages/stationery_purchase_detail/stationery_purchase_detail_details";
import AssetProcurementList from "@views/pages/asset_procurement/asset_procurement_list";
import AssetProcurementDetails from "@views/pages/asset_procurement/asset_procurement_details";
import EmployeeList from "@views/pages/employee/employee_list";
import EmployeeDetails from "@views/pages/employee/employee_details";
import EmployeeLeaveList from "@views/pages/employee_leave/employee_leave_list";
import EmployeeLeaveDetails from "@views/pages/employee_leave/employee_leave_details";
import LoanOnDepositList from "@views/pages/loan_on_deposit/loan_on_deposit_list";
import LoanOnDepositDetails from "@views/pages/loan_on_deposit/loan_on_deposit_details";
import AmountDepositDetails from "@views/pages/amount_deposit/amount_deposit_details";
import LoansList from "@views/pages/loans/loans_list";
import LoansDetails from "@views/pages/loans/loans_details";
import FestivalLoan from "@views/pages/festival_loan/festival_loan";
import ExpensesList from "@views/pages/expenses/expenses_list";
import ExpensesDetails from "@views/pages/expenses/expenses_details";
import TentativeBudgetingList from "@views/pages/tentative_budgeting/tentative_budgeting_list";
import TentativeBudgetingDetails from "@views/pages/tentative_budgeting/tentative_budgeting_details";
import DemandRecoveryList from "@views/pages/demand_recovery/demand_recovery_list";
import ApplyLoan from "@views/pages/loans/apply_loan/loan_apply";

import DepositProductDetails from "@views/pages/product_deposite/product_deposite_details";

import AddProductList from "@views/pages/product_add/product_add_list";
import AddProductDetails from "@views/pages/product_add/product_add_details";
import MemberLoanDetails from "@views/pages/loans/member_payment";
import LoanRequestList from "@views/pages/loans/loan_request_details/loan_request_list";
import MemberReceiptTab from "@views/pages/member_receipt/member_reciept_tab";
import LoanProducts from "@views/pages/loans/apply_loan/loan_products";
import MemberManagementList from "@views/pages/member_management/member_details/member_list";
import MemberManagementDetails from "@views/pages/member_management/member_details/member_details";
import GoldLoanDetails from "@views/pages/jewel_loan/jewel_loan_details";
import GoldLoanList from "@views/pages/jewel_loan/jewel_loan_list";
import CollectionDetails from "@views/pages/collection/collection_details";
import MemberClosure from "@views/pages/member_closure/member_closure";
import SocietyExpenditure from "@views/pages/society_expenditure/society_expenditure";
import GoldLoanAddProductDetails from "@views/pages/jewel_loan_add_product/jewel_loan_add_product_details";
import ExpenditureCategoryList from "@views/pages/expenditure_category/expenditure_category";
import ExpenditureCategoryDetails from "@views/pages/expenditure_category/expenditure_category_details";
import SocietyExpenditureList from "@views/pages/society_expenditure/society_expenditure_list";
import Transaction from "@views/pages/transaction/transaction";
import DayBook from "@views/pages/receipt_payment/day_book";
import BorrowingList from "@views/pages/borrowing/borrowing_list";
import BankAccountList from "@views/pages/bank_accounts/bank_account_list";
import BankAccountDetails from "@views/pages/bank_accounts/bank_account_details";
import ForgotPassword from "@views/pages/forgot_password/forgot_password";
import BorrowingPayment from "@views/pages/borrowing/borrowing_payment";
import DividendDetails from "@views/pages/dividend/dividend_details";
import DividendList from "@views/pages/dividend/dividend_list";
import BulkDemandCollectionList from "@views/pages/bulk_demand_collection.jsx/bulk_demand_collection_list";
import DepositClosure from "@views/pages/deposit_closure/deposit_closure";
import LoanClosure from "@views/pages/loan_closure/loan_closure";
import LoanDetailsView from "@views/pages/loan_details_view/loan_details_view";
import ClosedMembersList from "@views/pages/closed_members/closed_members_list";
import AdminDashboard from "@views/pages/dashboard/admin_dashboard";
import NotRespondingMembers from "@views/pages/not_responding_members/not_responding_members_list";
import SocietyDepositInterestPayout from "@views/pages/society_deposit_interest_payout/society_deposit_interest_payout_list";
import SocietyDepositInterestPayoutDetails from "@views/pages/society_deposit_interest_payout/society_deposit_interest_payout_details";
import Overdue from "@views/pages/overdue/overdue";
import OverdueLoan from "@views/pages/overdue/overdue_loan/overdue_loan";
import OverdueDepositList from "@views/pages/overdue/overdue_deposit/overdue_deposit_list";
import Liability from "@views/pages/liability/liability";
import DividendLiability from "@views/pages/liability/dividend_liability";
import DepositLiability from "@views/pages/liability/deposit_liability";
import AccumulatedInterestLiability from "@views/pages/liability/accumulated_interest_liability";
import BorrowingLiability from "@views/pages/liability/borrowing_liability";
import MemberShareList from "@views/pages/member_shares/member_share_list";
import MemberShareDetails from "@views/pages/member_shares/member_share_details";
import ShareCapitals from "@views/pages/share_capitals/share_capitals";
import DepositProducts from "@views/pages/deposits/deposit_products";
import MemberTransactionList from "@views/pages/member_management/member_transactions/member_transaction_list";
import SearchResult from "@views/pages/admin_dashboard/search_results";
import GlAccountTransactionList from "@views/pages/gl_account_transaction/gl_account_transaction_list";
import BalanceSheet from "@views/pages/balance_sheet/balance_sheet";
import ProfitAndLossAccount from "@views/pages/profit_and_loss_account/profit_and_loss_account";
import GetOneLoanRequestView from "@views/pages/loans/loan_request_details/get_one_loan_request_view";
import ReceptionPaymentTable from "@views/pages/receipt_payment/day_book";
import LoanDetailsTabView from "@views/pages/loans/loan_details_tab_view/loan_details_tab_view";
import DemandGenerationView from "@views/pages/demand_generation/demand/demand_generation_view";
import Departments from "@views/pages/master_data/departments/departments";
import DemandViewLayout from "@views/layouts/demand_view_layout";
import BulkPayment from "@views/pages/bulk_payment/bulk_payment";
import SuspenseAccount from "@views/pages/suspense_account/suspense_account";
import ResolutionsListPage from "@views/pages/resolutions/resolutions_list_page";
import GeneratedDemandForSubDepartment from "@views/pages/demand_generation/generated_demand_for_sub_department";
import ReceiptList from "@views/pages/receipt/receipt_list";
import PaymentsList from "@views/pages/payments/payments_list";
import DemandFormatPage from "@views/pages/master_data/demand_format_page/demand_format_list";
import DemandFormatPageDetails from "@views/pages/master_data/demand_format_page/demand_format_details";
import FeedbackList from "@views/pages/feedback/feedback_list";
import GrievanceList from "@views/pages/grievance/grievance_list";
import UsersList from "@views/pages/users/users_list";
import GrievanceCategoryList from "@views/pages/master_data/grievance_category/grievance_category_list";
import GrievanceClassificationList from "@views/pages/master_data/grievance_classification/grievance_classification_list";
import SocietyAdminTab from "@views/pages/society/society_admin_tab";
import MemberProfileLoans from "@views/pages/member_login/member_loans/member_loan";
import MemberProfileDashboard from "@views/pages/member_login/member_dashboard/member_dashboard";
import MemberProfileDeposits from "@views/pages/member_login/member_deposits/member_deposits";
import MemberLayout from "@views/pages/member_login/member_layout/member_layout";
import InvestmentList from "@views/pages/investments/investment_list";
import SalaryAllowanceList from "@views/pages/master_data/salary_allowance/salary_allowance_list";
import FixedDepositList from "@views/pages/fixed_deposit/fixed_deposit_list";
import FixedDepositProduct from "@views/pages/fixed_deposit/fixed_deposit_products";
import RecurringDepositList from "@views/pages/recurring_deposit/recurring_deposit_list";
import RecurringDepositProduct from "@views/pages/recurring_deposit/recurring_product_list";
import FixedDepositTabView from "@views/pages/fixed_deposit/fixed_deposit_tab_view";
import RecurringDepositTabView from "@views/pages/recurring_deposit/recurring_deposit_tab_view";
import StaffLoanList from "@views/pages/staff_loan/staff_loan_list";
import StaffLoanApplyDetails from "@views/pages/staff_loan/staff_loan_apply_details";
import StockList from "@views/pages/master_data/stock/stock_list";
import AssetStockList from "@views/pages/asset/aaset_stocks/asset_stock_list";
import InitializeGlAccount from "@views/pages/initialize_gl_account/initialize_gl_account";
import StaffLoanPaymentHistory from "@views/pages/staff_loan/staff_loan_history";
import BorrowingRepaymentList from "@views/pages/borrowing/borrowing_repayment";
import DemandGeneration from "@views/pages/demand_generation/demand/demand_generation";
import LoginPage from "@views/pages/sign_in/login";
import LoanAndDepositPage from "@views/pages/loan_and_deposit/loan_and_deposit_page";
import InvestmentHistory from "../pages/investments/investment_history";
import RCSMemberList from "../pages/rcs_login_pages/rcs_login_members";
import DepositView from "../pages/deposits/deposit_view";
import StaffListWithLoan from "../pages/staff_and_loan/staff_and_loan_list";
import StaffLoanDetails from "../pages/staff_and_loan/staff_loan_details";
import OverdueDetails from "../pages/dashboard/overdue_details/overdue_details";
import FixedDepositInterestPayableSheet from "../pages/fixed_deposit/fixed_deposit_interest_payable/interest_payable_sheet";
import SocietiesList from "../pages/dashboard/societies/societies_list";
import SuretyLoanList from "../pages/dashboard/dashboard_surety_loan/surety_loan_list";
import JewelLoanList from "../pages/dashboard/dashboard_jewel_loan/jowel_loan_list";
import SocietyStaffLoanList from "../pages/dashboard/dashboard_staff_loan/staff_loan_list";
import SocietyFDLoanList from "../pages/dashboard/dashboard_fd_loan/fd_loan_list";
import SocietyRDLoanList from "../pages/dashboard/dashboard_rd_loan/rd_loan_list";
import DashboardDeposits from "../pages/dashboard/dashboard_deposits/dashboard_deposits";
import StaffList from "./../pages/staff/staff_list";
import RecurringDepositInterestPayableSheet from "../pages/recurring_deposit/recurring_deposit_interest_payable/interest_payable_sheet";
import StaffPayrollView from "../pages/staff/staff_payroll/staff_payroll_view";
import StaffPayrollList from "../pages/staff/staff_payroll/staff_payroll_list";
import SundryDebitors from "../pages/member_management/sundry/sundry_debtors/sundry_debitors";
import SundryCreditors from "../pages/member_management/sundry/sundry_creditors/sundry_creditors";
import DepositInterestPayout from "../pages/deposits/interest_payout/deposits_interest_payout_list";
import YearWiseInterestPayableList from "../pages/deposits/interest_payout/year_wise_interest_payable_list";
import NonMemberDueBy from "../pages/initialize_gl_account/member_dueto/non_member_dueby";
import NonMemberDueTo from "../pages/initialize_gl_account/member_dueto/non_member_dueto";
import SocietyJewelLoanSettingList from "../pages/society/jewel_product/society_jewel_loan_settings/society_jewel_loan_settings_list";
import DayBookPDFDownload from "../pages/receipt_payment/day_book_pdf_download";
import SBAccountList from "../pages/sb_account_list/sb_account_list";
import SBInterestPayableSheetList from "../pages/sb_account_list/interest_payable/interest_payable_sheet_list";
import SBAccountInterestFrequencyList from "../pages/sb_account_list/interest_payable/sb_account_interest_frequency_list";
import StationeryCategoryList from "../pages/master_data/stationery_category/stationery_category_list";
import SocietyShareCapitalList from "../pages/society_share_capital/society_share_capital_list";
import BalanceSheetNew from "../pages/balance_sheet_new/balance_sheet_new";
import LossToPDF from "../pages/profit_and_loss_account/loss_to_print";
import GLAmountTransfer from "../pages/gl_accounts/gl_amount_transfer";
import YearEndInterestPaid from "../pages/year_end_interest_received_paid/year_end_interest_paid/year_end_interest_paid";
import YearEndInterestReceived from "../pages/year_end_interest_received_paid/year_end_interest_received/year_end_interest_received";
import YearEndInterestPaidPage from "./../pages/year_end_interest_received_paid/year_end_interest_paid/year_end_interest_paid_page";
import YearEndInterestReceivedPage from "../pages/year_end_interest_received_paid/year_end_interest_received/year_end_interest_received_page";
import YearEndInterestPaidGLAdd from "../pages/year_end_interest_received_paid/year_end_interest_paid/year_end_interest_paid_gl_add";
import SocietyBankAccountList from "../pages/society_bank_accounts/society_bank_account_list";
import SubDayBook from "../pages/sub_day_book/sub_day_book";
import MemberConvenienceList from "../pages/member_convenience/member_convenience_list";
import MemberRDList from "../pages/recurring_deposit/member_rd/member_recurring_list";
import MemberFDLoanList from "./../pages/fixed_deposit/member_fixed_deposit/member_fixed_deposit_list";
import ReceiptAndCharges from "../pages/receipt_and_charges/receipt_and_charges";
import PayOfficerList from "../pages/pay_officer_bulk_upload/pay_officer_list";
import PayOfficerBulkBreakUp from "../pages/pay_officer_bulk_upload/pay_officer_bulk_break_up";
import GlTagsList from "../pages/master_data/gl_tags/gl_tags_list";
import LdrList from "../pages/master_data/ldr/ldr_list";
import MemberClosureList from "../pages/member_closure_statement/member_closure_statement_list";
import SalaryDeductionList from "../pages/salary_deduction/salary_deduction_list";
import HolidayCalendar from "../pages/holiday_calendar/holiday_calendar";
import GeneralSettingsList from "../pages/general_settings/general_settings_list";
import JewelLoanSettingList from "../pages/jewel_loan_settings/jewel_loan_settings_list";
import OfficerAdminDashboardTabs from "../pages/dashboard/officer_admin_dashboard_tabs";
import OfficerList from "../pages/officers/officer_list";
import SocietyTransaction from "../pages/society/society_transaction";
import JewelLoanTypeList from "@views/pages/jewel_loan_type/jewel_loan_type_list";
import RcsOfficersList from "@views/pages/rcs_officers/rcs_officer_list";
import MigrationSocietyList from "@views/pages/migration_society_list/migration_society_list";
import PasswordChange from "@views/pages/password_change/password_change";
import TutorialVideos from "@views/pages/tutorial_videos/tutorial_videos";
import MasterDepositProduct from "@views/pages/master_deposit_product/master_deposit_product";

export const ROUTES = {
  DASHBOARD: "/dashboard",
  BULK_PAYMENT: "/bulk-payment",
  CONTACT: "/contact",
  LOGIN: "/login",
  MEMBER_CHANGE_PASSWORD: "/member-change-password",
  MEMBER_LEDGER: "/member-ledger",
  MEMBER_PROFILE: "/member-profile",
  CHANGE_PASSWORD: "/change-password",
  EMPTY: "/",
  AREA_LIST: "/areas",
  AREA_DETAILS: "/area",
  ASSET_LIST: "/assets",
  ASSET_DETAILS: "/asset",
  ASSET_CATEGORY_LIST: "/asset-categories",
  ASSET_CATEGORY_DETAILS: "/asset-category",
  BANK_LIST: "/banks",
  BANK_DETAILS: "/bank",
  COMMUNITY_LIST: "/communities",
  COMMUNITY_DETAILS: "/community",
  DESIGNATION_LIST: "/designations",
  DESIGNATION_DETAILS: "/designation",
  DIVISION_LIST: "/divisions",
  DIVISION_DETAILS: "/division",
  EXPENSE_CATEGORY_LIST: "/expense-categories",
  EXPENSE_CATEGORY_DETAILS: "/expense-category",
  GL_ACCOUNTS_GROUP_LIST: "/gl-account-groups",
  GL_ACCOUNTS_GROUP_DETAILS: "/gl-account-group",
  GL_TAGS_LIST: "/gl-tags",
  LDR_LIST: "/ldr-list",
  GL_ACCOUNTS_LIST: "/gl-accounts",
  GL_ACCOUNTS_DETAILS: "/gl-account",
  LEVEL_LIST: "/levels",
  LEVEL_DETAILS: "/level",
  MEMBER_LIST: "/society-members",
  SUNDRY_CREDITORS: "/sundry-creditors",
  SUNDRY_DEBTORS: "/sundry-debtors",
  MEMBER_LOAN_DETAILS: "/member-payment",
  MEMBER_PAYMENTS: "/member-payments",
  MEMBER_DETAILS: "/society-member",
  DEPARTMENTS: "/departments",
  PAYMENT_MODE_LIST: "/payment-modes",
  PAYMENT_MODE_DETAILS: "/payment-mode",
  RELIGION_LIST: "/religions",
  RELIGION_DETAILS: "/religion",
  SOCIETY_LIST: "/societies",
  SOCIETY_DETAILS: "/society",
  SOCIETY_TRANSACTION: "/society-transaction",
  SUB_DIVISION_LIST: "/sub-divisions",
  SUB_DIVISION_DETAILS: "/sub-division",
  VENDOR_LIST: "/vendors",
  VENDOR_DETAILS: "/vendor",
  VENDOR_CATEGORY_LIST: "/vendor-categories",
  VENDOR_CATEGORY_DETAILS: "/vendor-category",

  DEPOSIT_LIST: "/deposits",
  DEPOSIT_DETAILS: "/deposit",
  DEPOSIT_INTEREST_PAYOUT_LIST: "/deposit-interest-payout-list",
  SAVING_ACCOUNT_LIST: "/saving-accounts",
  SAVING_ACCOUNT_DETAILS: "/saving-account",
  SOCIETY_USER_LIST: "/society-users",
  SOCIETY_USER_DETAILS: "/society-user",
  STATIONERY_LIST: "/stationeries",
  STATIONERY_DETAILS: "/stationery",
  CMS_LIST: "/cms-list",
  CMS_DETAILS: "/cms",
  HOME: "/home",
  ABOUT: "/about",
  CONTACT: "/contact",
  GST_SLAB_LIST: "/gst-slabs",
  GST_SLAB_DETAILS: "/gst-slab",
  MASTER_DEPOSIT_PRODUCT: "/master-deposit-product",
  STATIONERY_USAGE_ENTRY_LIST: "/stationery-usage-entries",
  STATIONERY_USAGE_ENTRY_DETAILS: "/stationery-usage-entry",
  STATIONERY_USAGE_ENTRY_DETAIL_LIST: "/stationery-usage-entry-details",
  STATIONERY_USAGE_ENTRY_DETAIL_DETAILS: "/stationery-usage-entry-detail",
  STATIONERY_QUOTATION_LIST: "/stationery-quotations",
  STATIONERY_QUOTATION_DETAILS: "/stationery-quotation",
  STATIONERY_QUOTATION_DETAIL_LIST: "/stationery-quotation-details",
  STATIONERY_QUOTATION_DETAIL_DETAILS: "/stationery-quotation-detail",
  STATIONERY_PURCHASE_LIST: "/stationery-purchases",
  STATIONERY_PURCHASE_DETAILS: "/stationery-purchase",
  STATIONERY_PURCHASE_DETAIL_LIST: "/stationery-purchase-details",
  STATIONERY_PURCHASE_DETAIL_DETAILS: "/stationery-purchase-detail",
  ASSET_PROCUREMENT_LIST: "/asset-procurements",
  ASSET_PROCUREMENT_DETAILS: "/asset-procurement",
  LOAN_ON_DEPOSIT_LIST: "/loan_on_deposits",
  LOAN_ON_DEPOSIT_DETAILS: "/loan_on_deposit",
  CONTACT: "/contact",
  AMOUNT_DEPOSIT_DETAILS: "/amount_deposit_details",
  LOANS_DETAILS: "/loans",
  CONTACT: "/contact",
  EXPENSES_LIST: "/expenses",
  EXPENSES_DETAILS: "/expense",
  TENTATIVE_BUDGETING_LIST: "/tentative-budgeting",
  TENTATIVE_BUDGETING_DETAILS: "/tentative-budgeting",
  DEMAND_GENERATION: "/demand-generation",
  DEMAND_RECOVERY: "/demand-recovery",
  FESTIVAL_LOAN: "/festival-loan",
  LOANS_LIST: "/loan-list",
  LOANS_DETAILS: "/loans",
  APPLY_LOAN: "/apply-loan",
  LOAN_PRODUCTS: "/loan-products",
  LOAN_REQUESTS: "/loan-request",
  BORROWING_LIST: "/borrowings",
  EMPLOYEE_LIST: "/employees",
  EMPLOYEE_DETAILS: "/employee",
  EMPLOYEE_LEAVE_LIST: "/employee-leaves",
  EMPLOYEE_LEAVE_DETAILS: "/employee-leave",
  DEPOSIT_PRODUCT_DETAILS: "/deposit-product",
  ADD_PRODUCT_LIST: "/add-products",
  ADD_PRODUCT_DETAILS: "/add-product",
  GOLD_LOAN_DETAILS: "/jewel-loan-details",
  GOLD_LOAN_LIST: "/jewel-loans-list",
  COLLECTION_DETAILS: "/collection-details",
  MEMBER_CLOSURE: "/member-closures",
  MEMBER_CLOSURE_STATEMENT: "/member-closures-statement",
  SOCIETY_EXPENDITURES: "/society-expenditures",
  GOLD_LOAN_ADD_PRODUCT_DETAILS: "/gold-loan-add-product-details",
  PAYMENT: "/payment",
  EXPENDITURE_CATEGORY: "/expenditure-category",
  EXPENDITURE_CATEGORY_DETAILS: "/expenditure-category-details",
  SOCIETY_EXPENDITURE_LIST: "/society-expenditure-list",
  TRANSACTION: "/transaction",
  CLOSURE: "/closure",
  DAYBOOK: "/daybook",
  BANK_ACCOUNT_DETAILS: "/bank-account-details",
  BANK_ACCOUNT_LIST: "/bank-account-list",
  FORGOT_PASSWORD: "/forgot-password",
  BORROWING_PAYMENT: "/borrowing-payment",
  DIVIDEND_DETAILS: "/dividend-details",
  DIVIDEND_LIST: "/dividend-list",
  BULK_DEMAND_COLLECTION_LIST: "/bulk-demand-collection-list",
  BULK_DEMAND_COLLECTION_VIEW: "/bulk-demand-collection-view",
  DEPOSIT_CLOSURE: "/deposit-closure",
  LOAN_CLOSURE: "/loan-closure",
  DEPOSIT_INTEREST_PAYOUT: "/deposit_interest_payout",
  LOAN_DETAILS_VIEW: "/loan-details-view",
  CLOSED_MEMBERS_LIST: "/closed-members-list",
  ADMIN_DASHBOARD: "/admin-dashboard",
  NOT_RESPONDING_MEMBERS: "/not-resending-members",
  SOCIETY_DEPOSIT_PAYOUT: "/society_deposit_interest_payout",
  SOCIETY_DEPOSIT_INTEREST_PAYOUT_DETAILS:
    "/society_deposit_interest_payout_details",
  OVERDUE: "/overdue",
  OVERDUE_LOAN: "/overdue_loan",
  OVERDUE_DEPOSIT_LIST: "/overdue_deposit_list",
  OVERDUE_LOAN_LIST: "/overdue_loan_list",
  LIABILITY: "/liability",
  DIVIDEND_LIABILITY: "/dividend_liability",
  ACCUMULATED_LIABILITY: "/accumulated_liability",
  BORROWING_LOAN_LIABILITY: "/borrowing_loan_liability",
  DEPOSIT_LIABILITY: "/depository_liability",
  MEMBER_SHARE_LIST: "/member_share_list",
  MEMBER_SHARE_DETAILS: "/member_share_details",
  SHARE_CAPITALS: "/share-capitals",
  DEPOSIT_PRODUCTS: "/deposit-products",
  MEMBER_TRANSACTIONS: "/member-transactions",
  SEARCH_RESULTS: "/search-results",
  GL_ACCOUNT_TRANSACTION: "/gl-account-transactions",
  BALANCE_SHEET: "/balance-sheets",
  BALANCE_SHEET_NEW: "/balance-sheets-new",
  PROFIT_AND_LOSS_ACCT: "/profit-and-loss-account",
  PROFIT_AND_LOSS_ACCT_NEW: "/profit-and-loss-account-new",
  LOAN_REQUEST_VIEW: "/loan-request-view",
  LOAN_TAB_VIEW: "/loan-tab-view",
  DAY_BOOK: "/day-book",
  DEMAND_VIEW: "/demand-view",
  SUSPENSE_ACCOUNT: "/suspense-account",
  RESOLUTIONS: "/resolution",
  GENERATED_DEMAND_FOR_SOCIETY: "/generated-demand-for-society",
  DEMAND_FORMAT_PAGE: "/demand-format",
  DEMAND_FORMAT_DETAILS: "/demand-format-details",
  FEEDBACK: "/feedbacks",
  RECEIPT_LIST: "/receipt",
  PAYMENT_LIST: "/payments",
  PDFGENERRAT: "/pdf",
  GRIEVANCE_LIST: "/grievance",
  USERS: "/users",
  SOCIETY_USER_DETAILS_LIST: "/society-details",
  GRIEVANCE_CATEGORY_LIST: "/grievance-category-list",
  GRIEVANCE_CLASSIFICATION_LIST: "/grievance-classification-list",
  MEMBER_PROFILE_LOAN: "/member-profile-loan",
  MEMBER_PROFILE_DASHBOARD: "/member-profile-dashboard",
  MEMBER_PROFILE_DEPOSITS: "/member-profile-deposits",
  INVESTMENT_LIST: "/investment-list",
  SALARY_ALLOWANCES_LIST: "/salary-allowance-list",
  FIXED_DEPOSIT_LIST: "/fixed-deposit-list",
  FIXED_DEPOSIT_PRODUCT: "/fixed-deposit-product",
  FIXED_DEPOSIT_DETAILS_TAB_VIEW: "/fixed-deposit-details-tab-view",
  RECURRING_DEPOSIT_PRODUCT: "/recurring-deposit-product",
  RECURRING_DEPOSIT_LIST: "/recurring-deposit-list",
  RECURRING_DEPOSIT_DETAILS_TAB_VIEW: "/recurring-deposit-details-tab",
  STAFF_LOAN_APPLY_DETAILS: "/staff-loan-apply",
  STAFF_PAYROLL_LIST: "/staff-payroll-list",
  STOCK_LIST: "/stocks-list",
  ASSET_STOCK_LIST: "/assets-stocks-list",
  STAFF_PAYROLL_VIEW: "/staff-payroll-view",
  STAFF_LOAN_DETAILS: "/staff-loan-details-view",
  INITIALIZE_GL_ACCOUNT: "/initialize-gl-account",
  STAFF_LOAN_PAYMENT_HISTORY: "/staff-loan-payment-history",
  BORROWING_REPAYMENT: "/borrowing-repayment",
  LOAN_AND_DEPOSIT: "/loan-deposit",
  INVESTMENT_HISTORY: "/investment-history",
  RCS_MEMBER_LIST: "/rcs-member-list",
  DEPOSIT_VIEW: "/deposit-view",
  STAFF_LIST: "/staff-list",
  STAFF_AND_LOAN_DETAILS: "/staff-details",
  OVERDUE_DETAILS: "/overdue-details",
  FIXED_DEPOSIT_INTEREST_DISBURSEMENT: "/fixed-deposit-interest-disbursement",
  SOCIETY_LISTS: "/societies-list",
  SURETY_LOAN_LIST: "/surety-loan-list",
  JEWEL_LOAN_LIST: "/societies-jewel-loans-list",
  SOCIETY_STAFF_LOAN_LIST: "/societies-staff-loans-list",
  SOCIETY_FD_LOAN_LIST: "/societies-fd-loans-list",
  SOCIETY_RD_LOAN_LIST: "/societies-rd-loans-list",
  SOCIETY_DEPOSIT_LIST: "/societies-deposit",
  STAFF_LOAN_LIST: "/staff-loan-list",
  RECURRING_DEPOSIT_INTEREST_DISBURSEMENT:
    "/recurring-deposit-interest-disbursement",
  YEAR_WISE_INTEREST_PAYOUT_LIST: "/year-wise-interest-payout",
  NON_MEMBER_DUEBY: "/non-member-dueby",
  NON_MEMBER_DUETO: "/non-member-dueto",
  JEWEL_LOAN_SETTINGS: "/jewel-loan-settings",
  SB_LIST: "/sb-list",
  SB_INTEREST_PAYABLE_SHEET: "/sb-interest-payable-sheet",
  SB_INTEREST_FREQUENCY_LIST: "/sb-interest-frequency-list",
  STATIONERY_CATEGORY_LIST: "/stationery-category-list",
  SOCIETY_SHARE_CAPITAL_LIST: "/society-share-capital-list",
  GL_AMOUNT_TRANSFER: "/gl-amount-transfer",
  YEAR_END_INTEREST_RECEIVED: "/year-end-interest-received",
  YEAR_END_INTEREST_PAID: "/year-end-interest-paid",
  YEAR_END_INTEREST_RECEIVED_PAGE: "/year-end-interest-received-page",
  YEAR_END_INTEREST_PAID_PAGE: "/year-end-interest-paid-page",
  YEAR_END_INTEREST_RECEIVED_ADD: "/year-end-interest-received-add",
  SOCIETY_BANK_ACCOUNTS: "/society-bank-accounts",
  SUB_DAY_BOOK: "/sub-day-book",
  MEMBER_CONVERSION_LIST: "/member-conversion",
  MEMBER_RECURRING_DEPOSIT: "/member-profile-recurring-deposit",
  MEMBER_FIXED_DEPOSIT: "/member-profile-fixed-deposit",
  RECEIPT_AND_CHARGES: "/receipt_and_charges",
  PAY_OFFICER_BULK: "/payofficer-bulk-receipts",
  PAY_OFFICER_BULK_DETAILS: "/payofficer-bulk-receipts-details",
  SALARY_DEDUCTION: "/salary-deduction",
  HOLIDAY_CALENDAR: "/holiday-calendar",
  GENERAL_SETTINGS: "/general-settings",
  SOCIETY_JEWEL_LOAN_SETTINGS: "/societies-jewel-loan-settings",
  OFFICER: "/officer",
  SOCIETY_JEWEL_LOAN_TYPE: "/society-jewel-type",
  RCS_OFFICER_LIST: "/rcs_officers",
  MIGRATION_SOCIETY_LIST: "/migration-society-list",
  PASSWORD_CHANGE: "/password-change",
  TUTORIAL_VIDEOS: "/tutorial-videos",
  LEAVE_CALENDAR: "/leave-calendar",
};

const myRoutes = [
  {
    path: "/access-rights",
    exact: true,
    page_key: "Access",
    component: AccessRights,
    authenticate: false,
  },
  {
    path: ROUTES.HOLIDAY_CALENDAR,
    exact: true,
    page_key: "Holiday Calendar",
    component: HolidayCalendar,
    authenticate: false,
    layout: layout1,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    page_key: "LOGIN",
    component: LoginPage,
    authenticate: false,
  },
  {
    path: ROUTES.PASSWORD_CHANGE,
    exact: true,
    page_key: "Password Change",
    component: PasswordChange,
    authenticate: false,
  },
  {
    path: ROUTES.COLLECTION_DETAILS,
    exact: true,
    page_key: "Collection",
    component: CollectionDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.MEMBER_CLOSURE,
    exact: true,
    page_key: "Member Closure",
    component: MemberClosure,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.MEMBER_CLOSURE_STATEMENT,
    exact: true,
    page_key: "Member Closure Statement",
    component: MemberClosureList,
    layout: layout1,
    authenticate: false,
  },

  {
    path: `${ROUTES.SOCIETY_EXPENDITURES}/:id?`,
    exact: true,
    page_key: "Society Expenditure",
    component: SocietyExpenditure,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.STATIONERY_LIST,
    exact: true,
    page_key: "Stationeries",
    component: StationeryList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.MEMBER_PAYMENTS,
    exact: true,
    page_key: "Member-Receipts",
    component: MemberReceiptTab,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STATIONERY_DETAILS}/:id?`,
    exact: true,
    page_key: "Stationery",
    component: StationeryDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DEPOSIT_LIST}/:id?`,
    exact: true,
    page_key: "Deposits",
    component: DepositList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DEPOSIT_DETAILS}/:id?`,
    exact: true,
    page_key: "Deposit",
    component: DepositDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DEPOSIT_INTEREST_PAYOUT_LIST}/:product_id?`,
    exact: true,
    page_key: "Deposit Interest Disbursement",
    component: DepositInterestPayout,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SAVING_ACCOUNT_LIST,
    exact: true,
    page_key: "Savings Accounts",
    component: SavingAccountsList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SAVING_ACCOUNT_DETAILS}/:id?`,
    exact: true,
    page_key: "Savings Account",
    component: SavingAccountsDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CMS_LIST,
    exact: true,
    page_key: "CMS",
    component: CMSList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.CMS_DETAILS}/:id?`,
    exact: true,
    page_key: "Bank",
    component: CMSDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SOCIETY_USER_LIST,
    exact: true,
    page_key: "Society Users",
    component: SocietyUserList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_USER_DETAILS}/:id?`,
    exact: true,
    page_key: "Society User",
    component: SocietyUserDetails,
    layout: layout1,
    authenticate: false,
  },

  {
    path: ROUTES.DASHBOARD,
    exact: true,
    page_key: "Dashboard",
    component: Dashboard,
    layout: layout1,
    authenticate: false,
  },

  {
    path: ROUTES.MIGRATION_SOCIETY_LIST,
    exact: true,
    page_key: "Migration Society List",
    component: MigrationSocietyList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.BULK_PAYMENT,
    exact: true,
    page_key: "Bulk Payment",
    component: BulkPayment,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CHANGE_PASSWORD,
    exact: true,
    page_key: "Change Password",
    component: ChangePassword,
    // layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    page_key: "Login",
    component: Login,
    authenticate: false,
  },

  {
    path: ROUTES.EMPTY,
    exact: true,
    page_key: "Redirect",
    component: Redirect,
    authenticate: false,
  },
  {
    path: ROUTES.AREA_LIST,
    exact: true,
    page_key: "Areas",
    component: AreaList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.AREA_DETAILS}/:id?`,
    exact: true,
    page_key: "Area",
    component: AreaDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ASSET_LIST,
    exact: true,
    page_key: "Assets",
    component: AssetList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.ASSET_DETAILS}/:id?`,
    exact: true,
    page_key: "Asset",
    component: AssetDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ASSET_CATEGORY_LIST,
    exact: true,
    page_key: "Asset Categories",
    component: AssetCategoryList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.ASSET_CATEGORY_DETAILS}/:id?`,
    exact: true,
    page_key: "Asset Category",
    component: AssetCategoryDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.BANK_LIST,
    exact: true,
    page_key: "Banks",
    component: BankList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.BANK_DETAILS}/:id?`,
    exact: true,
    page_key: "Bank",
    component: BankDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.COMMUNITY_LIST,
    exact: true,
    page_key: "communities",
    component: CommunityList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.COMMUNITY_DETAILS}/:id?`,
    exact: true,
    page_key: "Community",
    component: CommunityDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DESIGNATION_LIST,
    exact: true,
    page_key: "Designations",
    component: DesignationList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DESIGNATION_DETAILS}/:id?`,
    exact: true,
    page_key: "Designation",
    component: DesignationDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DIVISION_LIST,
    exact: true,
    page_key: "divisions",
    component: DivisionList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DIVISION_DETAILS}/:id?`,
    exact: true,
    page_key: "Division",
    component: DivisionDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EXPENSE_CATEGORY_LIST,
    exact: true,
    page_key: "Expense Categories",
    component: ExpenseCategoryList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.EXPENSE_CATEGORY_DETAILS}/:id?`,
    exact: true,
    page_key: "Expense Category",
    component: ExpenseCategoryDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GL_ACCOUNTS_LIST,
    exact: true,
    page_key: "G L-Accounts",
    component: GlAccountList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GL_ACCOUNTS_DETAILS}/:id?`,
    exact: true,
    page_key: "G L-Account",
    component: GlAccountDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GL_ACCOUNTS_GROUP_LIST,
    exact: true,
    page_key: "G L-Account Groups",
    component: GlAccountGroupList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GL_ACCOUNTS_GROUP_DETAILS}/:id?`,
    exact: true,
    page_key: "GL-Account Group",
    component: GlAccountGroupDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GL_TAGS_LIST,
    exact: true,
    page_key: "GL-tags",
    component: GlTagsList,
    layout: layout1,
    authenticate: false,
  },

  {
    path: ROUTES.LEVEL_LIST,
    exact: true,
    page_key: "Levels",
    component: LevelList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.LEVEL_DETAILS}/:id?`,
    exact: true,
    page_key: "Level",
    component: LevelDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.MEMBER_LIST}/:status?/:id?`,
    exact: true,
    page_key: "Members",
    component: MemberManagementList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SUNDRY_CREDITORS}/:id?`,
    exact: true,
    page_key: "Sundry Creditors",
    component: SundryCreditors,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SUNDRY_DEBTORS}/:id?`,
    exact: true,
    page_key: "Sundry Debtors",
    component: SundryDebitors,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.MEMBER_LOAN_DETAILS,
    exact: true,
    page_key: "Member Loan",
    component: MemberLoanDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.MEMBER_DETAILS}/:id?/:index_value?`,
    exact: true,
    page_key: "Member",
    component: MemberManagementDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DEPARTMENTS,
    exact: true,
    page_key: "Departments",
    component: Departments,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.PAYMENT_MODE_LIST,
    exact: true,
    page_key: "Payment Modes",
    component: PaymentModeList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.PAYMENT_MODE_DETAILS}/:id?`,
    exact: true,
    page_key: "Payment Mode",
    component: PaymentModeDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.RELIGION_LIST,
    exact: true,
    page_key: "Religions",
    component: ReligionList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.RELIGION_DETAILS}/:id?`,
    exact: true,
    page_key: "Religion",
    component: ReligionDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_LIST}/:loan_type?/:status?`,
    exact: true,
    page_key: "Societies",
    component: SocietyList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_DETAILS}/:id?`,
    exact: true,
    page_key: "Society",
    component: SocietyTab,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_TRANSACTION}/:id?`,
    exact: true,
    page_key: "Society Transaction",
    component: SocietyTransaction,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.PAYMENT_MODE_LIST,
    exact: true,
    page_key: "Payment Modes",
    component: PaymentModeList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SUB_DIVISION_LIST,
    exact: true,
    page_key: "Sub Divisions",
    component: SubDivisionList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SUB_DIVISION_DETAILS}/:id?`,
    exact: true,
    page_key: "Sub Division",
    component: SubDivisionDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GST_SLAB_LIST,
    exact: true,
    page_key: "GST Slabs",
    component: GstSlabList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GST_SLAB_DETAILS}/:id?`,
    exact: true,
    page_key: "GST Slab",
    component: GstSlabDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.MASTER_DEPOSIT_PRODUCT}/:id?`,
    exact: true,
    page_key: "Mater Deposit Product",
    component: MasterDepositProduct,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.STATIONERY_USAGE_ENTRY_LIST,
    exact: true,
    page_key: "Stationery Usage Entries",
    component: StationeryUsageEntryList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STATIONERY_USAGE_ENTRY_DETAILS}/:id?`,
    exact: true,
    page_key: "Stationery Usage Entry",
    component: StationeryUsageEntryDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.STATIONERY_USAGE_ENTRY_DETAIL_LIST,
    exact: true,
    page_key: "Stationery Usage Entry Details",
    component: StationeryUsageEntryDetailList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STATIONERY_USAGE_ENTRY_DETAIL_DETAILS}/:id?`,
    exact: true,
    page_key: "Stationery Usage Entry Detail",
    component: StationeryUsageEntryDetailDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.STATIONERY_QUOTATION_LIST,
    exact: true,
    page_key: "Stationery Quotations",
    component: StationeryQuotationList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STATIONERY_QUOTATION_DETAILS}/:id?`,
    exact: true,
    page_key: "Stationery Quotation",
    component: StationeryQuotationDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.STATIONERY_QUOTATION_DETAIL_LIST,
    exact: true,
    page_key: "Stationery Quotation Details",
    component: StationeryQuotationDetailList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STATIONERY_QUOTATION_DETAIL_DETAILS}/:id?`,
    exact: true,
    page_key: "Stationery Quotation Detail",
    component: StationeryQuotationDetailDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.STATIONERY_PURCHASE_LIST,
    exact: true,
    page_key: "Stationery Purchases",
    component: StationeryPurchaseList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STATIONERY_PURCHASE_DETAILS}/:id?`,
    exact: true,
    page_key: "Stationery Purchase",
    component: StationeryPurchaseDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.STATIONERY_PURCHASE_DETAIL_LIST,
    exact: true,
    page_key: "Stationery Purchase Details",
    component: StationeryPurchaseDetailList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STATIONERY_PURCHASE_DETAIL_DETAILS}/:id?`,
    exact: true,
    page_key: "Stationery Purchase Detail",
    component: StationeryPurchaseDetailDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ASSET_PROCUREMENT_LIST,
    exact: true,
    page_key: "Asset Procurements",
    component: AssetProcurementList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.ASSET_PROCUREMENT_DETAILS}/:id?`,
    exact: true,
    page_key: "Asset Procurement",
    component: AssetProcurementDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.VENDOR_LIST,
    exact: true,
    page_key: "Vendors",
    component: VendorList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.VENDOR_DETAILS}/:id?`,
    exact: true,
    page_key: "Vendor",
    component: VendorDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.VENDOR_CATEGORY_LIST,
    exact: true,
    page_key: "Vendor Categories",
    component: VendorCategoryList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.VENDOR_CATEGORY_DETAILS}/:id?`,
    exact: true,
    page_key: "Vendor Category",
    component: VendorCategoryDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.HOME,
    exact: true,
    page_key: "Home",
    component: Home,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: ROUTES.ABOUT,
    exact: true,
    page_key: "About",
    component: About,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: ROUTES.CONTACT,
    exact: true,
    page_key: "Contact",
    component: Contact,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: ROUTES.LOAN_ON_DEPOSIT_LIST,
    exact: true,
    page_key: "Loan on Deposit List",
    component: LoanOnDepositList,
    layout: HomeLayout,
    authenticate: false,
  },

  {
    path: `${ROUTES.LOAN_ON_DEPOSIT_DETAILS}/:id?`,
    exact: true,
    page_key: "Loan on Deposit",
    component: LoanOnDepositDetails,
    layout: layout1,
    authenticate: false,
  },

  {
    path: `${ROUTES.AMOUNT_DEPOSIT_DETAILS}/:id?`,
    exact: true,
    page_key: "Amount Deposit",
    component: AmountDepositDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.LOANS_LIST}/:id?/:status_url?/:society_id?/`,
    exact: true,
    page_key: "Loans",
    component: LoansList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.BORROWING_LIST}`,
    exact: true,
    page_key: "Borrowings",
    component: BorrowingList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.LOANS_DETAILS,
    exact: true,
    page_key: "Loan Details",
    component: LoansDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.APPLY_LOAN}/:loan_product_id?`,
    exact: true,
    page_key: "Apply Loan",
    component: ApplyLoan,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.LOAN_PRODUCTS,
    exact: true,
    page_key: "Loan Products",
    component: LoanProducts,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.LOAN_REQUESTS}/:id?`,
    exact: true,
    page_key: "Loan Request",
    component: LoanRequestList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.FESTIVAL_LOAN,
    exact: true,
    page_key: "Festival Loan",
    component: FestivalLoan,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EXPENSES_LIST,
    exact: true,
    page_key: "Header Menu",
    component: ExpensesList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EXPENSES_DETAILS,
    exact: true,
    page_key: "Header Menu",
    component: ExpensesDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TENTATIVE_BUDGETING_LIST,
    exact: true,
    page_key: "Header Menu",
    component: TentativeBudgetingList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TENTATIVE_BUDGETING_DETAILS,
    exact: true,
    page_key: "Header Menu",
    component: TentativeBudgetingDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DEMAND_GENERATION}/:generated_date?`,
    exact: true,
    page_key: "Demand",
    component: DemandGeneration,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DEMAND_RECOVERY,
    exact: true,
    page_key: "Header Menu",
    component: DemandRecoveryList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EMPLOYEE_LIST,
    exact: true,
    page_key: "Header Menu",
    component: EmployeeList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.EMPLOYEE_DETAILS}/:id?`,
    exact: true,
    page_key: "Header Menu",
    component: EmployeeDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EMPLOYEE_LEAVE_LIST,
    exact: true,
    page_key: "header-menu",
    component: EmployeeLeaveList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EMPLOYEE_LEAVE_DETAILS,
    exact: true,
    page_key: "Header Menu",
    component: EmployeeLeaveDetails,
    layout: layout1,
    authenticate: false,
  },
  // {
  //   path: ROUTES.DEPOSIT_PRODUCT_LIST,
  //   exact: true,
  //   page_key: "header-menu",
  //   component: DepositProductList,
  //   layout: layout1,
  //   authenticate: false,
  // },
  {
    path: ROUTES.DEPOSIT_PRODUCT_DETAILS,
    exact: true,
    page_key: "Header Menu",
    component: DepositProductDetails,
    layout: layout1,
    authenticate: false,
  },

  {
    path: ROUTES.ADD_PRODUCT_LIST,
    exact: true,
    page_key: "Header Menu",
    component: AddProductList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ADD_PRODUCT_DETAILS,
    exact: true,
    page_key: "Header Menu",
    component: AddProductDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GOLD_LOAN_DETAILS}/:product_id?/:id?`,
    exact: true,
    page_key: "Gold Loan Details",
    component: GoldLoanDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GOLD_LOAN_LIST}/:id?/:status?`,
    exact: true,
    page_key: "Jewel Loan",
    component: GoldLoanList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GOLD_LOAN_ADD_PRODUCT_DETAILS,
    exact: true,
    page_key: "Gold Loan Add Product",
    component: GoldLoanAddProductDetails,
    layout: layout1,
    authenticate: false,
  },

  {
    path: ROUTES.EXPENDITURE_CATEGORY,
    exact: true,
    page_key: "Expenditure Category",
    component: ExpenditureCategoryList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.EXPENDITURE_CATEGORY_DETAILS}/:id?`,
    exact: true,
    page_key: "Expenditure Category",
    component: ExpenditureCategoryDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_EXPENDITURE_LIST}/:id?`,
    exact: true,
    page_key: "Society Expenditure List",
    component: SocietyExpenditureList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.TRANSACTION}/:status?/:id?`,
    exact: true,
    page_key: "Transaction",
    component: Transaction,
    layout: layout1,
    authenticate: false,
  },

  {
    path: `${ROUTES.DAYBOOK}/:id?`,
    exact: true,
    page_key: "Daybook",
    component: DayBook,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.BANK_ACCOUNT_LIST,
    exact: true,
    page_key: "Bank Account List",
    component: BankAccountList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.BANK_ACCOUNT_DETAILS}/:id?`,
    exact: true,
    page_key: "Bank Account Details",
    component: BankAccountDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    page_key: "Forgot Password",
    component: ForgotPassword,
    // layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.BORROWING_PAYMENT}/:id?`,
    exact: true,
    page_key: "Borrowing Payment",
    component: BorrowingPayment,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DIVIDEND_DETAILS}/:id?/:status?`,
    exact: true,
    page_key: "Dividend Details",
    component: DividendDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DIVIDEND_LIST}`,
    exact: true,
    page_key: "Dividend List",
    component: DividendList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.BULK_DEMAND_COLLECTION_LIST}`,
    exact: true,
    page_key: "Bulk Demand Collection List",
    component: BulkDemandCollectionList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DEPOSIT_CLOSURE}`,
    exact: true,
    page_key: "Deposit Closure",
    component: DepositClosure,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.LOAN_CLOSURE}`,
    exact: true,
    page_key: "Loan Closure",
    component: LoanClosure,
    layout: layout1,
    authenticate: false,
  },
  // {
  //   path: `${ROUTES.DEPOSIT_INTEREST_PAYOUT}`,
  //   exact: true,
  //   page_key: "Deposit Interest Payout",
  //   component: DepositInterestPayout,
  //   layout: layout1,
  //   authenticate: false,
  // },
  {
    path: `${ROUTES.LOAN_DETAILS_VIEW}`,
    exact: true,
    page_key: "Loan Details View",
    component: LoanDetailsView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.CLOSED_MEMBERS_LIST}`,
    exact: true,
    page_key: "Closed Members List",
    component: ClosedMembersList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.ADMIN_DASHBOARD}`,
    exact: true,
    page_key: "Admin Dashboard",
    component: OfficerAdminDashboardTabs,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.NOT_RESPONDING_MEMBERS}`,
    exact: true,
    page_key: "Not Responding Members",
    component: NotRespondingMembers,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_DEPOSIT_PAYOUT}`,
    exact: true,
    page_key: "Society Deposit Interest Payout",
    component: SocietyDepositInterestPayout,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_DEPOSIT_INTEREST_PAYOUT_DETAILS}/:id?`,
    exact: true,
    page_key: "Society Deposit Interest Payout Details",
    component: SocietyDepositInterestPayoutDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.OVERDUE}`,
    exact: true,
    page_key: "Overdue",
    component: Overdue,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.OVERDUE_LOAN}`,
    exact: true,
    page_key: "Overdue",
    component: OverdueLoan,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.OVERDUE_DEPOSIT_LIST}`,
    exact: true,
    page_key: "Overdue Deposit List",
    component: OverdueDepositList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.LIABILITY}`,
    exact: true,
    page_key: "Liability",
    component: Liability,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DIVIDEND_LIABILITY}`,
    exact: true,
    page_key: "Dividend Liability",
    component: DividendLiability,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DEPOSIT_LIABILITY}`,
    exact: true,
    page_key: "Deposit Liability",
    component: DepositLiability,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.ACCUMULATED_LIABILITY}`,
    exact: true,
    page_key: "Accumulated Liability",
    component: AccumulatedInterestLiability,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.BORROWING_LOAN_LIABILITY}`,
    exact: true,
    page_key: "Borrowing Loan Liability",
    component: BorrowingLiability,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.MEMBER_SHARE_LIST}/:id?`,
    exact: true,
    page_key: "Member Share List",
    component: MemberShareList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.MEMBER_SHARE_DETAILS}/:id?`,
    exact: true,
    page_key: "Member Share List",
    component: MemberShareDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SHARE_CAPITALS}`,
    exact: true,
    page_key: "Share Capitals",
    component: ShareCapitals,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DEPOSIT_PRODUCTS}`,
    exact: true,
    page_key: "Deposit Products",
    component: DepositProducts,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.MEMBER_TRANSACTIONS}`,
    exact: true,
    page_key: "Member Transactions",
    component: MemberTransactionList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SEARCH_RESULTS}`,
    exact: true,
    page_key: "Search Results",
    component: SearchResult,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GL_ACCOUNT_TRANSACTION}/:id?/:from_date?/:to_date?`,
    exact: true,
    page_key: "GL Account Transaction",
    component: GlAccountTransactionList,
    layout: layout1,
    authenticate: false,
  },
  // {
  //   path: `${ROUTES.BALANCE_SHEET}`,
  //   exact: true,
  //   page_key: "Balance Sheet",
  //   component: BalanceSheet,
  //   layout: layout1,
  //   authenticate: false,
  // },
  {
    path: `${ROUTES.BALANCE_SHEET}`,
    exact: true,
    page_key: "Balance Sheet",
    component: BalanceSheetNew,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.PROFIT_AND_LOSS_ACCT}`,
    exact: true,
    page_key: "Profit Loss Acct",
    component: ProfitAndLossAccount,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.PROFIT_AND_LOSS_ACCT_NEW}`,
    exact: true,
    page_key: "Profit Loss Acct",
    component: LossToPDF,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.LOAN_REQUEST_VIEW}/:id?/:product_id?/:status?`,
    exact: true,
    page_key: "Loan Request View",
    component: GetOneLoanRequestView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.LOAN_TAB_VIEW}/:id?/:product_id?/:status?`,
    exact: true,
    page_key: "Loan Details",
    component: LoanDetailsTabView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DAY_BOOK}`,
    exact: true,
    page_key: "Day Book",
    component: ReceptionPaymentTable,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DEMAND_VIEW}/:id?`,
    exact: true,
    page_key: "Demand View",
    component: DemandGenerationView,
    layout: DemandViewLayout,

    authenticate: false,
  },
  {
    path: ROUTES.SUSPENSE_ACCOUNT,
    exact: true,
    page_key: "Suspense Account",
    component: SuspenseAccount,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.RESOLUTIONS,
    exact: true,
    page_key: "Resolution List",
    component: ResolutionsListPage,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GENERATED_DEMAND_FOR_SOCIETY}/:id?/:sub_department_id?/:status?`,
    exact: true,
    page_key: "Society Demand",
    component: GeneratedDemandForSubDepartment,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DEMAND_FORMAT_PAGE,
    exact: true,
    page_key: "Demand Format",
    component: DemandFormatPage,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.RECEIPT_LIST,
    exact: true,
    page_key: "Receipt",
    component: ReceiptList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.PAYMENT_LIST,
    exact: true,
    page_key: "Payments",
    component: PaymentsList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DEMAND_FORMAT_DETAILS,
    exact: true,
    page_key: "Demand Format Details",
    component: DemandFormatPageDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.FEEDBACK,
    exact: true,
    page_key: "Feedbacks",
    component: FeedbackList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GRIEVANCE_CATEGORY_LIST}`,
    exact: true,
    page_key: "Grievance Category",
    component: GrievanceCategoryList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GRIEVANCE_CLASSIFICATION_LIST}`,
    exact: true,
    page_key: "Grievance Classification",
    component: GrievanceClassificationList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GRIEVANCE_LIST}/:id?`,
    exact: true,
    page_key: "Grievance",
    component: GrievanceList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.USERS}/:id?`,
    exact: true,
    page_key: "Users",
    component: UsersList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_USER_DETAILS_LIST}/:id?`,
    exact: true,
    page_key: "Users",
    component: SocietyAdminTab,
    layout: layout1,
    authenticate: false,
  },

  {
    path: ROUTES.MEMBER_CHANGE_PASSWORD,
    exact: true,
    page_key: "Member Change Password",
    component: ChangeMemberPassword,
    layout: MemberLayout,
    authenticate: false,
  },
  {
    path: ROUTES.MEMBER_LEDGER,
    exact: true,
    page_key: "Member Ledger",
    component: MemberLedger,
    layout: MemberLayout,
    authenticate: false,
  },
  {
    path: ROUTES.MEMBER_PROFILE,
    exact: true,
    page_key: "Member Profile",
    component: MemberProfile,
    authenticate: false,
    layout: MemberLayout,
  },
  {
    path: `${ROUTES.MEMBER_PROFILE_LOAN}/:id?`,
    exact: true,
    page_key: "Loan",
    component: MemberProfileLoans,
    layout: MemberLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.MEMBER_PROFILE_DASHBOARD}/:id?`,
    exact: true,
    page_key: "Dashboard",
    component: MemberProfileDashboard,
    layout: MemberLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.MEMBER_PROFILE_DEPOSITS}/:id?`,
    exact: true,
    page_key: "Deposits",
    component: MemberProfileDeposits,
    layout: MemberLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.INVESTMENT_LIST}`,
    exact: true,
    page_key: "Investment",
    component: InvestmentList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SALARY_ALLOWANCES_LIST}`,
    exact: true,
    page_key: "Salary Allowance",
    component: SalaryAllowanceList,
    layout: layout1,
    authenticate: false,
  },

  {
    path: `${ROUTES.FIXED_DEPOSIT_LIST}/:id?/:status?`,
    exact: true,
    page_key: "Fixed Deposits",
    component: FixedDepositList,
    layout: layout1,
    authenticate: false,
  },

  {
    path: `${ROUTES.FIXED_DEPOSIT_PRODUCT}/:id?`,
    exact: true,
    page_key: "Fixed Deposit Product",
    component: FixedDepositProduct,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.FIXED_DEPOSIT_DETAILS_TAB_VIEW}/:product_id?/:id?`,
    exact: true,
    page_key: "Fixed Deposit",
    component: FixedDepositTabView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.RECURRING_DEPOSIT_LIST}/:id?/:status?`,
    exact: true,
    page_key: "Recurring Deposits",
    component: RecurringDepositList,
    layout: layout1,
    authenticate: false,
  },

  {
    path: `${ROUTES.RECURRING_DEPOSIT_PRODUCT}/:id?`,
    exact: true,
    page_key: "Recurring Deposit Product",
    component: RecurringDepositProduct,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.RECURRING_DEPOSIT_DETAILS_TAB_VIEW}/:product_id?/:id?`,
    exact: true,
    page_key: "Recurring Deposit",
    component: RecurringDepositTabView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_}/:id?/:status?`,
    exact: true,
    page_key: "Staff Loan",
    component: StaffLoanList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STAFF_LOAN_APPLY_DETAILS}/:product_id?`,
    exact: true,
    page_key: "Staff Loan Apply",
    component: StaffLoanApplyDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STAFF_PAYROLL_LIST}`,
    exact: true,
    page_key: "Staff Payroll",
    component: StaffPayrollList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STOCK_LIST}/:id?`,
    exact: true,
    page_key: "Stock",
    component: StockList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.ASSET_STOCK_LIST}/:id?`,
    exact: true,
    page_key: "Stock",
    component: AssetStockList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STAFF_PAYROLL_VIEW}/:id?`,
    exact: true,
    page_key: "Staff Payroll",
    component: StaffPayrollView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STAFF_LOAN_PAYMENT_HISTORY}/:id?`,
    exact: true,
    page_key: "Staff Loan Payment",
    component: StaffLoanPaymentHistory,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.INITIALIZE_GL_ACCOUNT}/:id?`,
    exact: true,
    page_key: "Initialize GL Account",
    component: InitializeGlAccount,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.BORROWING_REPAYMENT}/:id?`,
    exact: true,
    page_key: "Borrowing Repayment",
    component: BorrowingRepaymentList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.LOAN_AND_DEPOSIT}/:id?`,
    exact: true,
    page_key: "Loan on Deposit",
    component: LoanAndDepositPage,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.INVESTMENT_HISTORY}/:id?`,
    exact: true,
    page_key: "Investment History",
    component: InvestmentHistory,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.RCS_MEMBER_LIST}/:status?`,
    exact: true,
    page_key: "Member List",
    component: RCSMemberList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.DEPOSIT_VIEW}/:id?`,
    exact: true,
    page_key: "Deposit",
    component: DepositView,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STAFF_LIST}/:id?`,
    exact: true,
    page_key: "Staff",
    component: StaffList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STAFF_AND_LOAN_DETAILS}/:staff_id?`,
    exact: true,
    page_key: "Staff Loans",
    component: StaffLoanDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.OVERDUE_DETAILS}/:staff_id?`,
    exact: true,
    page_key: "Overdue Details",
    component: OverdueDetails,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.FIXED_DEPOSIT_INTEREST_DISBURSEMENT}/:product_id?`,
    exact: true,
    page_key: "Fixed Deposit Interest Disbursement",
    component: FixedDepositInterestPayableSheet,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_LISTS}/:status?`,
    exact: true,
    page_key: "Societies List",
    component: SocietiesList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SURETY_LOAN_LIST}/:status?`,
    exact: true,
    page_key: "Surety Loan",
    component: SuretyLoanList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.JEWEL_LOAN_LIST}/:status?`,
    exact: true,
    page_key: "Jewel Loan",
    component: JewelLoanList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_STAFF_LOAN_LIST}/:status?`,
    exact: true,
    page_key: "Staff Loan",
    component: SocietyStaffLoanList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_FD_LOAN_LIST}/:status?`,
    exact: true,
    page_key: "FD Loan",
    component: SocietyFDLoanList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_RD_LOAN_LIST}/:status?`,
    exact: true,
    page_key: "RD Loan",
    component: SocietyRDLoanList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_DEPOSIT_LIST}/:id?/:status?`,
    exact: true,
    page_key: "Deposit",
    component: DashboardDeposits,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STAFF_LOAN_LIST}/:id?/:status?`,
    exact: true,
    page_key: "Staff Loan",
    component: StaffLoanList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.RECURRING_DEPOSIT_INTEREST_DISBURSEMENT}/:product_id?`,
    exact: true,
    page_key: "Recurring Deposit Interest Disbursement",
    component: RecurringDepositInterestPayableSheet,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.YEAR_WISE_INTEREST_PAYOUT_LIST}/:product_id?`,
    exact: true,
    page_key: "Deposit Interest Payouts",
    component: YearWiseInterestPayableList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.NON_MEMBER_DUEBY}`,
    exact: true,
    page_key: "non-member-dueby",
    component: NonMemberDueBy,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.NON_MEMBER_DUETO}`,
    exact: true,
    page_key: "Non Member Dueto",
    component: NonMemberDueTo,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.JEWEL_LOAN_SETTINGS}`,
    exact: true,
    page_key: "Jewel Loan Settings",
    component: SocietyJewelLoanSettingList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SB_LIST}`,
    exact: true,
    page_key: "SB Account",
    component: SBAccountList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SB_INTEREST_PAYABLE_SHEET}/:id?`,
    exact: true,
    page_key: "SB Interest Payable Sheet List",
    component: SBInterestPayableSheetList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SB_INTEREST_FREQUENCY_LIST}`,
    exact: true,
    page_key: "SB Interest Frequency List",
    component: SBAccountInterestFrequencyList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.STATIONERY_CATEGORY_LIST}`,
    exact: true,
    page_key: "Stationery Category",
    component: StationeryCategoryList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_SHARE_CAPITAL_LIST}`,
    exact: true,
    page_key: "Society Share Capital",
    component: SocietyShareCapitalList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.GL_AMOUNT_TRANSFER}`,
    exact: true,
    page_key: "GL amount transfer",
    component: GLAmountTransfer,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.YEAR_END_INTEREST_PAID}`,
    exact: true,
    page_key: "Year end interest paid",
    component: YearEndInterestPaid,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.YEAR_END_INTEREST_RECEIVED}`,
    exact: true,
    page_key: "Year end interest received",
    component: YearEndInterestReceived,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.YEAR_END_INTEREST_PAID_PAGE}`,
    exact: true,
    page_key: "Year end interest paid page",
    component: YearEndInterestPaidPage,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.YEAR_END_INTEREST_RECEIVED_PAGE}`,
    exact: true,
    page_key: "Year end interest received page",
    component: YearEndInterestReceivedPage,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.YEAR_END_INTEREST_RECEIVED_ADD}/:id?`,
    exact: true,
    page_key: "Year end interest received add",
    component: YearEndInterestPaidGLAdd,
    layout: HomeLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.SOCIETY_BANK_ACCOUNTS}`,
    exact: true,
    page_key: "Society Bank Account",
    component: SocietyBankAccountList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SUB_DAY_BOOK}`,
    exact: true,
    page_key: "Sub Day Book",
    component: SubDayBook,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.MEMBER_CONVERSION_LIST}`,
    exact: true,
    page_key: "Member Conversion",
    component: MemberConvenienceList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.MEMBER_RECURRING_DEPOSIT}/:id?`,
    exact: true,
    page_key: "member-recurring-deposit",
    component: MemberRDList,
    layout: MemberLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.MEMBER_FIXED_DEPOSIT}/:id?`,
    exact: true,
    page_key: "member-fixed-deposit",
    component: MemberFDLoanList,
    layout: MemberLayout,
    authenticate: false,
  },
  {
    path: ROUTES.RECEIPT_AND_CHARGES,
    exact: true,
    page_key: "Receipts & Charges",
    component: ReceiptAndCharges,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.PAY_OFFICER_BULK,
    exact: true,
    page_key: "Pay Officer Bulk Receipt",
    component: PayOfficerList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.PAY_OFFICER_BULK_DETAILS}/:id`,
    exact: true,
    page_key: "Pay Officer Bulk Receipt Details",
    component: PayOfficerBulkBreakUp,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.LDR_LIST,
    exact: true,
    page_key: "Loan Disbursement Register",
    component: LdrList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SALARY_DEDUCTION,
    exact: true,
    page_key: "Salary Deduction",
    component: SalaryDeductionList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GENERAL_SETTINGS,
    exact: true,
    page_key: "General Settings",
    component: GeneralSettingsList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SOCIETY_JEWEL_LOAN_SETTINGS,
    exact: true,
    page_key: "Global Jewel Loan Settings",
    component: JewelLoanSettingList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.OFFICER,
    exact: true,
    page_key: "Officers",
    component: OfficerList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SOCIETY_JEWEL_LOAN_TYPE,
    exact: true,
    page_key: "Jewel Loan Type",
    component: JewelLoanTypeList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.RCS_OFFICER_LIST,
    exact: true,
    page_key: "Rcs Officers",
    component: RcsOfficersList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TUTORIAL_VIDEOS,
    exact: true,
    page_key: "tutorial videos",
    component: TutorialVideos,
    layout: layout1,
    authenticate: false,
  },
];

export default myRoutes;
