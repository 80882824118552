import { Form, Pagination, Select, Space, Table } from "antd";
import { Box, Button, HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "services/redux";
import {
  get_migration_districts,
  get_migration_society_list_download_excel_query,
  get_migration_society_list_query,
} from "services/redux/slices/dashboard/graphql";
import { useHistory } from "react-router-dom";
import { exportToExcel } from "helpers/functions";
import { status_options_society } from "helpers/constants";
import moment from "moment";

const MigrationSocietyList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const [sorterInfo, setSorterInfo] = useState({
    field: "",
    order: "",
  });

  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [page_title, set_page_title] = useState("");

  const [items, set_items] = useState();
  const user = localStorage.getItem("user");
  const roles = localStorage.getItem("roles");

  const queryParams = new URLSearchParams(location.search);

  let [status, set_status] = useState(queryParams.get("status"));
  const [district_id, set_district_id] = useState(
    queryParams.get("district_id")
  );

  const [district_ids, set_district_ids] = useState(district_id);
  const [status_value, set_status_value] = useState("");

  const {
    items: migration_society_list,
    pagination,
    loading: migration_society_list_loading,
  } = useDynamicSelector("getMigrationSocieties");
  const {
    items: migration_society_list_download,
    loading: migration_society_list_download_loading,
  } = useDynamicSelector("getMigrationSocietiesDownloadExcel");

  const { items: districts, loading: district_loading } =
    useDynamicSelector("getDistricts");

  useEffect(() => {
    if (migration_society_list) {
      set_items(null);
      let _items = migration_society_list?.map((item, index) => ({
        index_key: (page_number - 1) * page_limit + index + 1,
        ...item,
      }));
      set_items(_items);
    }
  }, [migration_society_list, status]);

  const get_migration_districts_list = () => {
    let keys = [{ key: "getDistricts", loading: true }];
    let variable = {};
    dispatch(dynamicRequest(keys, get_migration_districts, variable));
  };

  const get_migration_society_list = (field, order) => {
    let keys = [{ key: "getMigrationSocieties", loading: true }];
    let variable = {
      status: status_value || status,
      page_number: page_number,
      page_limit: page_limit,
      district_id: district_ids,
      field: field || sorterInfo?.field || "",
      order: order || sorterInfo?.order || "",
    };
    dispatch(dynamicRequest(keys, get_migration_society_list_query, variable));
  };

  const get_migration_society_download_excel_list = () => {
    let keys = [{ key: "getMigrationSocietiesDownloadExcel", loading: true }];
    let variable = {
      status: status_value || status,
      district_id: district_ids,
      field: sorterInfo?.field || "",
      order: sorterInfo?.order || "",
    };
    dispatch(
      dynamicRequest(
        keys,
        get_migration_society_list_download_excel_query,
        variable
      )
    );
  };

  useEffect(() => {
    if ((status || status_value) === "validated") {
      set_page_title(t("validated"));
    } else if ((status || status_value) === "live") {
      set_page_title(t("live"));
    } else if ((status || status_value) === "back_log_entry") {
      set_page_title(t("back_log_entry"));
    } else if ((status || status_value) === "test_credentials_given") {
      set_page_title(t("test_credentials_given"));
    } else if ((status || status_value) === "uploaded_with_error") {
      set_page_title(t("uploaded_with_error"));
    } else if ((status || status_value) === "not_started") {
      set_page_title(t("not_started"));
    } else if ((status || status_value) === "reverted") {
      set_page_title(t("reverted"));
    } else if ((status || status_value) === "uploaded_with_od_error") {
      set_page_title(t("uploaded_with_od_error"));
    } else if ((status || status_value) === "null") {
      set_page_title(t("total"));
    }
  }, [status, status_value]);

  useEffect(() => {
    dispatch(dynamicClear("getMigrationSocietiesDownloadExcel"));

    if (district_id) {
      form.setFieldsValue({
        district_id: district_ids || district_id || [],
      });
    }

    if (status != "null") {
      form.setFieldsValue({
        status: status_value || status,
      });
    } else if (status === "null") {
      form.resetFields(["status"]);
    } else {
      form.setFieldsValue({
        status: status_value ?? form.resetFields(["status"]),
      });
    }

    get_migration_districts_list();
  }, [district_id, form, status_value, status]);

  useEffect(() => {
    get_migration_society_list();
  }, [page_limit, page_number, status, district_ids, status_value]);

  const fetchData = (val, value) => {
    set_page_number(val);
    set_page_limit(value);
  };

  const handleChangeDistrict = (values) => {
    set_district_ids(values);
    set_district_id(null);
    form.setFieldsValue({ district_id: values });
  };

  const handle_back = () => {
    const queryParams = new URLSearchParams();
    queryParams.set("choose_active_key", "migration");
    history.push({
      pathname: "/admin-dashboard",
      search: `?${queryParams.toString()}`,
    });
  };

  const handle_table_change = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    const sortOrder =
      order === "ascend" ? "ASC" : order === "descend" ? "DESC" : "";
    setSorterInfo({ field, order: sortOrder });
    get_migration_society_list(field, sortOrder);
  };

  const columns = [
    {
      title: t("table:s_no"),
      dataIndex: "",
      // render:()=>{},
      render: (_, __, index) => (page_number - 1) * page_limit + index + 1,
    },
    {
      title: t("table:district"),
      dataIndex: "district_name",
      key: "district_name",
      sortable: true,
      align: "left",
      sorter: (a, b) => null,
    },
    {
      title: t("table:society_name"),
      dataIndex: "name",
      key: "name",
      sortable: true,
      align: "left",
      sorter: (a, b) => null,
    },
    {
      title: t("table:society_code"),
      dataIndex: "username",
      key: "username",
      sortable: true,
      align: "left",
      sorter: (a, b) => null,
      // render: (text) => text.toUpperCase(),
    },

    status === "back_log_entry" && {
      title: t("table:accounting_date"),
      dataIndex: "current_accounting_day",
      key: "current_accounting_day",
      sortable: true,
      align: "left",
      sorter: (a, b) => null,
      render: (data) => (data ? moment(data).format("DD/MM/YYYY") : ""),
    },
    // status === "back_log_entry" && {
    //   title: t("table:last_login"),
    //   dataIndex: "last_login_datetime",
    //   key: "last_login_datetime",
    //   sortable: true,
    //   align: "left",
    //   sorter: (a, b) => null,
    //   render: (data) => (data ? moment(data).format("DD/MM/YYYY hh:mm A") : ""),
    // },
    status === "validated" && {
      title: t("table:validated_date"),
      dataIndex: "uploaded_time",
      key: "validated_date",
      sortable: true,
      align: "left",
      sorter: (a, b) => null,
      render: (data) => (data ? moment(data).format("DD/MM/YYYY") : ""),
    },
    status === "uploaded_with_error" && {
      title: t("table:last_uploaded_date"),
      dataIndex: "uploaded_time",
      key: "last_uploaded_date",
      sortable: true,
      align: "left",
      sorter: (a, b) => null,
      render: (data) => (data ? moment(data).format("DD/MM/YYYY") : ""),
    },
    status === "uploaded_with_error" && {
      title: t("table:error_count"),
      dataIndex: "error_count",
      key: "error_count",
      sortable: true,
      align: "left",
      sorter: (a, b) => null,
      render: (data) => {
        if (!data && data !== 0) {
          return "Reverted Test Credentials";
        }
        return <h5>{data}</h5>;
      },
    },
    status === "test_credentials_given" && {
      title: t("table:test_credentials_given_date"),
      dataIndex: "test_credentials_given_date",
      key: "test_credentials_given_date",
      sortable: true,
      align: "left",
      sorter: (a, b) => null,
      render: (data) => (data ? moment(data).format("DD/MM/YYYY") : ""),
    },

    // status === "back_log_entry" && [
    //   {
    //     title: t("table:accounting_date"),
    //     dataIndex: "accounting_date",
    //     key: "accounting_date",
    //     sortable: false,
    //     align: "left",
    //     // render: (text) => text.toUpperCase(),
    //   },
    //   {
    //     title: t("table:lost_login"),
    //     dataIndex: "lost_login",
    //     key: "lost_login",
    //     sortable: false,
    //     align: "left",
    //     // render: (text) => text.toUpperCase(),
    //   },
    // ],

    // status === "uploaded_with_error" || status === "uploaded_with_od_error"
    //   ? {
    //       title: t("table:error_count"),
    //       dataIndex: "error_count",
    //       key: "errors",
    //       sortable: false,
    //       align: "left",
    //       // render: (text) => {
    //       //   const errors = JSON.parse(text);
    //       //   return <ul>{errors?.length || 0}</ul>;
    //       // },
    //       // sorter: (a, b) => {
    //       //   const aLength = JSON.parse(a.errors || "[]").length;
    //       //   const bLength = JSON.parse(b.errors || "[]").length;
    //       //   return aLength - bLength;
    //       // },
    //     }
    //   : null,
  ].filter(Boolean);

  useEffect(() => {
    if (migration_society_list_download) {
      let arranged_data = arrangeXLReportData(migration_society_list_download);
      exportToExcel(arranged_data ?? [], "MigrationSocieties.xlsx");
    }
    dispatch(dynamicClear("getMigrationSocietiesDownloadExcel"));
  }, [migration_society_list_download]);

  const handle_excel_download = async () => {
    dispatch(dynamicClear("getMigrationSocietiesDownloadExcel"));
    get_migration_society_download_excel_list();
  };

  const arrangeXLReportData = (data_items) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S No"]: (page_number - 1) * page_limit + i + 1,
        ["District Name"]: data_items[i]?.district_name,
        ["Society Name"]: data_items[i]?.name,
        ["Society Code"]: data_items[i]?.username.toUpperCase(),
      };

      // Include additional columns based on the status
      if (status === "back_log_entry") {
        row_data["Accounting Date"] = data_items[i]?.current_accounting_day
          ? moment(data_items[i]?.current_accounting_day).format("DD/MM/YYYY")
          : "";
      }
      if (status === "validated") {
        row_data["Validated Date"] = data_items[i]?.uploaded_time
          ? moment(data_items[i]?.uploaded_time).format("DD/MM/YYYY")
          : "";
      }
      if (status === "uploaded_with_error") {
        row_data["Last Uploaded Date"] = data_items[i]?.uploaded_time
          ? moment(data_items[i]?.uploaded_time).format("DD/MM/YYYY")
          : "";
        row_data["Error Count"] = data_items[i]?.error_count || 0;
      }
      if (status === "test_credentials_given") {
        row_data["Test Credentials Given Date"] = data_items[i]
          ?.test_credentials_given_date
          ? moment(data_items[i]?.test_credentials_given_date).format(
              "DD/MM/YYYY"
            )
          : "";
      }

      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  const handleChangeStatus = (value) => {
    set_status(value);
  };

  // const handleClearStatus = (value) => {
  //   set_clear_flag(true);
  //   // set_status_value(null);
  //   set_status(value);

  //   form.resetFields(["status"]);
  // };

  return (
    <div>
      <Box>
        <Text variant={"society_list_text"}>
          {`${page_title} Society List`}
        </Text>
      </Box>

      {/* <HStack
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        {JSON.parse(roles)?.[0]?.name === "officer" && (
          <Form form={form} layout="horizontal">
            <Form.Item name="status" width={"300px"}>
              <Select
                width={"300px"}
                showSearch
                allowClear
                value={status_value}
                onChange={handleChangeStatus}
                placeholder={t("select_status")}
                options={status_options_society?.map((d) => ({
                  label: d.name,
                  value: d.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="district_id" width={"300px"}>
              <Select
                width={300}
                showSearch
                allowClear
                value={district_ids}
                options={districts?.map((d) => ({
                  label: d.name,
                  value: d.id,
                }))}
                onChange={handleChangeDistrict}
                loading={district_loading}
                placeholder={t("select_district")}
              />
            </Form.Item>
          </Form>
        )}

        <Button
          onPress={handle_excel_download}
          colorScheme={"primary"}
          variant={"outline"}
          style={{
            marginLeft: "10px",
            height: "33px",
            width: "130px",
            marginRight: "5px",
          }}
          isLoading={migration_society_list_download_loading}
        >
          {t("download_excel")}
        </Button>
        <Button
          onPress={handle_back}
          colorScheme={"primary"}
          variant={"outline"}
          style={{
            marginLeft: "10px",
            height: "33px",
            width: "100px",
            marginRight: "5px",
          }}
        >
          {t("back")}
        </Button>
      </HStack> */}

      <HStack
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        {JSON.parse(roles)?.[0]?.name === "officer" && (
          <HStack>
            <Form form={form} layout="inline">
              <Form.Item name="status">
                <Select
                  showSearch
                  allowClear
                  value={status_value}
                  onChange={handleChangeStatus}
                  placeholder={t("select_status")}
                  options={status_options_society?.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }))}
                  style={{ width: 200 }}
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item name="district_id">
                <Select
                  showSearch
                  allowClear
                  value={district_ids}
                  options={districts?.map((d) => ({
                    label: d.name,
                    value: d.id,
                  }))}
                  onChange={handleChangeDistrict}
                  loading={district_loading}
                  placeholder={t("select_district")}
                  style={{ width: 200 }}
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Form>
          </HStack>
        )}

        <Button
          onPress={handle_excel_download}
          colorScheme={"primary"}
          variant={"outline"}
          style={{
            marginLeft: "10px",
            height: "33px",
            width: "130px",
            marginRight: "5px",
          }}
          isLoading={migration_society_list_download_loading}
        >
          {t("download_excel")}
        </Button>
        <Button
          onPress={handle_back}
          colorScheme={"primary"}
          variant={"outline"}
          style={{
            marginLeft: "10px",
            height: "33px",
            width: "100px",
            marginRight: "5px",
          }}
        >
          {t("back")}
        </Button>
      </HStack>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Table
          className="migration-table"
          rowKey={(record) => record.id}
          loading={migration_society_list_loading}
          columns={columns}
          dataSource={items}
          pagination={false}
          onChange={handle_table_change}
          sortDirections={["ascend", "descend"]}
          defaultSortOrder={sorterInfo.order}
          defaultSortColumn={sorterInfo.field}
          showSorterTooltip={{ target: "sorter-icon" }}
        />
        <HStack justifyContent={"flex-end"} marginTop={"10px"}>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={page_number}
            hideOnSinglePage={false}
            onChange={fetchData}
          />
        </HStack>
      </Box>
    </div>
  );
};

export default MigrationSocietyList;
