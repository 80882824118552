import React, { useEffect } from "react";
import { Button, Table, Form } from "antd";
import { amountFormats } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { HStack } from "native-base";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import {
  bulk_upload_demand_collection,
  pay_officer_bulk_collection,
} from "../../../services/redux/slices/dynamic_entity/graphql/graphql_bulk_collection";
import { showToast } from "@helpers/toast";

const BulkUploadConfirmation = ({
  excel_response,
  close,
  form,
  first_modal,
  fetch_data,
}) => {
  const url = Form.useWatch("url", form);
  const receipt_gl_account_id = Form.useWatch("receipt_gl_account_id", form);
  const {
    loading: createLoading,
    status: bulk_status,
    error: bulk_error,
  } = useDynamicSelector("PayOfficerbulkCollection");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let columns = [
    // {
    //   title: t("table:receipt_number"),
    //   dataIndex: "Receipt.No",
    //   key: "Receipt.No",
    //   align: "left",
    //   flex: 1,
    // },
    {
      title: t("table:payofficer_code"),
      dataIndex: "Pay Officer Code",
      key: "Pay Officer Code",
      align: "left",
      flex: 1,
    },
    {
      title: t("table:amount"),
      dataIndex: "Amount",
      key: "amount",
      render: (record) => amountFormats(record),
      align: "right",
      flex: 1,
    },
  ];

  const handle_ok = () => {
    let variables = {
      url: url?.[0]?.url,
      receipt_gl_account_id: receipt_gl_account_id,
    };
    let keys = [{ key: "PayOfficerbulkCollection", loading: true }];
    dispatch(dynamicRequest(keys, pay_officer_bulk_collection, variables, "M"));
  };

  useEffect(() => {
    if (bulk_error) {
      showToast({ type: "error", message: t("error_message") });
      dispatch(dynamicClear("PayOfficerbulkCollection"));
    } else if (bulk_status === "Success") {
      showToast({
        type: "success",
        message: `${t(
          "Pay Officer Bulk Receipt upload successfully waits a few minutes to be refuted."
        )}`,
      });
      close();
      fetch_data();
      first_modal();
      dispatch(dynamicClear("PayOfficerbulkCollection"));
    } else if (bulk_status === "Success") {
      showToast({
        type: "success",
        message: `${t("error_message")}`,
      });
      dispatch(dynamicClear("PayOfficerbulkCollection"));
    }
  }, [bulk_status, bulk_error]);
  console.log("excel_response", excel_response);
  return (
    <div>
      <Table
        columns={columns}
        dataSource={excel_response}
        pagination={false}
        summary={() => {
          let total = excel_response.reduce(
            (sum, acc) => (sum += Number(acc.Amount || 0)),
            0
          );
          return (
            <Table.Summary.Row>
              {/* <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell> */}
              <Table.Summary.Cell colSpan={1}>Total</Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                {amountFormats(total)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
      <HStack justifyContent={"flex-end"} mt={"30px"} space={"10px"}>
        <Button type="primary" loading={createLoading} onClick={handle_ok}>
          OK
        </Button>
        <Button
          danger
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>
      </HStack>
    </div>
  );
};

export default BulkUploadConfirmation;
