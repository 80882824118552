import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";
import Dashboard from "./admin_dashboard";
import MigrationDashboard from "./migration_dashboard";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "services/redux";

const OfficerAdminDashboardTabs = () => {
  const location = useLocation();
  const { key: dynamicKey } = useDynamicSelector("dashboard_active_key");
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const choose_active_key = queryParams.get("choose_active_key");
  const [defaultActiveKey, setDefaultActiveKey] = useState("migration");

  const tabItems = [
    {
      key: "migration",
      label: "Migration",
      children: <MigrationDashboard />,
    },
    {
      key: "live",
      label: "Live",
      children: <Dashboard />,
    },
  ];

  const onChange = (key) => {
    setDefaultActiveKey(key);
  };

  useEffect(() => {
    if (choose_active_key) {
      setDefaultActiveKey(choose_active_key);
    }
  }, [choose_active_key]);

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Tabs
        activeKey={defaultActiveKey}
        onChange={onChange}
        items={tabItems}
        style={{ width: "100%" }}
        centered
        animated={{ inkBar: true, tabPane: true }}
        tabBarStyle={{ borderBottom: "none" }}
      />
    </div>
  );
};

export default OfficerAdminDashboardTabs;
