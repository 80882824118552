import {
  useDynamicSelector,
  get_all_rd_products,
  dynamicRequest,
  get_all_gl_bank_accounts_query,
  create_fixed_deposit,
  create_recurring_deposit,
} from "@services/redux";
import { get_all_nominees_list_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_nominee";
import Form from "@views/components/ui/antd_form";
import { Button, Card, Col, Radio, Row } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { amountFormat } from "../../../../helpers/utils";
import {
  TenureDaysType,
  mobileNumberRegex,
} from "../../../../helpers/constants";
import { Form as AntdForm } from "antd";
import { useParams } from "react-router-dom";
import {
  disabled_range,
  disabled_range_current_and_previous,
  disabledRangeBeforeAccountingDate,
  to_disabled_range,
} from "helpers/functions";
import moment from "moment";

const MemberRDDetails = (props) => {
  const dispatch = useDispatch();
  const { id } = props;
  const { id: member_id } = useParams();
  const [form] = AntdForm.useForm();
  const { t } = useTranslation();

  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const date_old = getSocietyAccountingDay?.current_accounting_day;
  const accountingDate = moment(date_old).startOf("day");

  const [tenureDays, setTenureDays] = useState(0);
  const [product_id, setProductId] = useState();
  const [opening_date, set_opening_date] = useState(null);
  const [rdProduct, setRdProduct] = useState({});
  const [showNominee, setShowNominee] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const send_in_demand = AntdForm.useWatch("send_in_demand", form);
  console.log("send_in_demand", send_in_demand);

  const [payment_to_id, setPaymentToId] = useState();
  const { items: rd_product_list } = useDynamicSelector(
    "getRecurringDepositProducts"
  );
  const { items: bank_accounts } = useDynamicSelector("getGlBankAccounts");
  const { items: nominee_list } = useDynamicSelector("getNominees");

  const { loading } = useDynamicSelector("createRecurringDeposit");
  const getRdProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getGlBankAccounts = () => {
    let key = [{ key: "getGlBankAccounts", loading: true }];
    let query = get_all_gl_bank_accounts_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getMemberNomineesList = () => {
    let key = [{ key: "getNominees", loading: true }];
    let query = get_all_nominees_list_query;
    let variables = { member_id };
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    getRdProductList();
    getGlBankAccounts();
    getMemberNomineesList();
    setIsShow(true);
    setShowNominee(false);
  }, []);
  useEffect(() => {
    let temp;
    temp = rd_product_list?.find((x) => x.id === product_id);
    form.setFieldsValue({ tenure_type: temp?.min_tenure_months });
    setRdProduct(temp);
  }, [product_id]);

  const handleSubmit = (values) => {
    console.log("values", values);
    showNominee ? delete values.nominee_id : delete values.nominee;
    values.member_id = member_id;
    delete values.payment_to;
    showNominee && (values.nominee.member_id = member_id);
    values.member_id = member_id;
    let variables = {
      json: {
        data: {
          ...values,
          member_id,
          opening_date,
          send_in_demand: send_in_demand ?? false,
        },
        payment_to: send_in_demand ? "" : payment_to_id,
      },
    };
    let keys = [{ key: "createRecurringDeposit", loading: true }];
    dispatch(dynamicRequest(keys, create_recurring_deposit, variables, "M"));
  };
  const onValueChange = (val, value) => {
    if (val.tenure_in_months) setTenureDays(val.tenure_in_months);
    if (value.recurring_deposit_product_id)
      setProductId(value.recurring_deposit_product_id);
    if (value.payment_to) {
      setPaymentToId(value.payment_to);
    }
    if (value.opening_date) {
      set_opening_date(value.opening_date);
    }
  };

  const relationship = [
    {
      id: "Father",
      name: "Father",
    },
    {
      id: "Mother",
      name: "Mother",
    },
    {
      id: "Spouse",
      name: "Spouse",
    },
    {
      id: "Son",
      name: "Son",
    },
    {
      id: "Daughter",
      name: "Daughter",
    },
    {
      id: "Brother",
      name: "Brother",
    },
    {
      id: "Sister",
      name: "Sister",
    },
    {
      id: "Other",
      name: "Other",
    },
  ];

  const compounding_frequency = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Quarterly",
      value: "quarterly",
    },
    {
      label: "Half-Yearly",
      value: "half_yearly",
    },
    {
      label: "Annual",
      value: "annually",
    },
    {
      label: "On Maturity",
      value: "on_maturity",
    },
  ];

  return (
    <Box flex={1}>
      <Form
        form={form}
        initialValues={""}
        onSubmit={handleSubmit}
        onValuesChange={onValueChange}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Select
              field={"recurring_deposit_product_id"}
              label={t("product")}
              options={rd_product_list}
              rules={[
                {
                  required: true,
                  message: t("error:product_is_required"),
                },
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Number
              field={"amount"}
              label={t("monthly_subscription_amount")}
              rules={[
                {
                  required: true,
                  message: t("error:monthly_subscription_amount_is_required"),
                },
                {
                  validator: async (rule, value) => {
                    if (
                      value < rdProduct?.min_allowed_amount ||
                      value > rdProduct?.max_allowed_amount
                    ) {
                      throw new Error(
                        "Amount must be between minimum amount and maximum amount."
                      );
                    }
                  },
                },
              ]}
            />
          </Col>
          <Col span={24}>
            {rdProduct && (
              <Card>
                <VStack space={"5"}>
                  <Box flex={1}>
                    <HStack space={"5"}>
                      <Box flex={1}>{t("name")}</Box>
                      <Box flex={1}>{rdProduct?.name}</Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space={"5"}>
                      <Box flex={1}>{t("code")}</Box>
                      <Box flex={1}>{rdProduct?.code}</Box>
                    </HStack>
                  </Box>

                  <Box flex={1}>
                    <HStack space={"5"}>
                      <Box flex={1}>{t("minimum_amount")}</Box>
                      <Box flex={1}>
                        {amountFormat(rdProduct?.min_allowed_amount)}
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space={"5"}>
                      <Box flex={1}>{t("maximum_amount")}</Box>
                      <Box flex={1}>
                        {amountFormat(rdProduct?.max_allowed_amount)}
                      </Box>
                    </HStack>
                  </Box>

                  <Box flex={1}>
                    <HStack space={"5"}>
                      <Box flex={1}>{t("minimum_tenure")}</Box>
                      <Box flex={1}>
                        <Text>
                          {rdProduct?.min_tenure} {rdProduct.min_tenure_months}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space={"5"}>
                      <Box flex={1}>{t("maximum_tenure")} </Box>
                      <Box flex={1}>
                        <Text>
                          {rdProduct?.max_tenure} {rdProduct.max_tenure_months}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>

                  <Box flex={1}>
                    <HStack space={"5"}>
                      <Box flex={1}>{t("rate_of_interest")}</Box>
                      <Box flex={1}>{rdProduct?.rate_of_interest}</Box>
                    </HStack>
                  </Box>
                  <Box flex={1}>
                    <HStack space={"5"}>
                      <Box flex={1}>{t("interest_payment_frequency")}</Box>
                      <Box flex={1}>
                        {rdProduct?.interest_payment_frequency}
                      </Box>
                    </HStack>
                  </Box>
                </VStack>
              </Card>
            )}
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12}>
                <Box flex={1}>
                  <Form.Number
                    notShow={true}
                    field={"tenure"}
                    label={t("form:tenure")}
                    rules={[
                      {
                        required: true,
                        message: t("error:tenure_days_required"),
                      },
                      {
                        validator: async (rule, value) => {
                          if (
                            value < rdProduct?.min_tenure ||
                            value > rdProduct?.max_tenure
                          ) {
                            throw new Error(
                              "Tenure must be between minimum tenure and maximum tenure."
                            );
                          }
                        },
                      },
                    ]}
                  />
                </Box>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Select
                  field={"tenure_type"}
                  label={t("type")}
                  options={TenureDaysType}
                  labelField={"label"}
                  valueField={"value"}
                  rules={[
                    {
                      required: true,
                      message: t("error:tenure_type_required"),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Select
              field={"interest_payment_frequency"}
              label={t("form:interest_frequency")}
              options={compounding_frequency}
              labelField={"label"}
              valueField={"value"}
              isClearable={true}
              rules={[
                {
                  required: true,
                  message: t("error:interest_frequency_is_required"),
                },
              ]}
            />
          </Col>
          {/* {tenureDays && tenureDays < rdProduct?.min_days ? (
            <Text bold color={"red.500"}>
              {t("tenure_days_cannot_be_less_than_minimum_tenure_days")}
            </Text>
          ) : (
            ""
          )}
          {tenureDays > rdProduct?.max_days && (
            <Text bold color={"red.500"}>
              {t("tenure_days_cannot_be_greater_than_maximum_tenure_days")}
            </Text>
          )} */}
          {!send_in_demand && (
            <Col xs={24} sm={24} md={12}>
              <Form.Select
                field={"payment_to"}
                label={t("form:receipt_bank_account")}
                options={bank_accounts}
                labelField={"name"}
                valueField={"id"}
                rules={[
                  {
                    required: true,
                    message: t("error:receipt_bank_account_is_required"),
                  },
                ]}
              />
            </Col>
          )}

          {/* <Col
            xs={24}
            sm={24}
            md={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              textAlign: "center",
              alignContent: "flex-end",
              justifyContent: "start",
            }}
          >
            <Form.CheckBox
              field={"include_rd_in_demand"}
              label={t("form:include_rd_in_demand")}
            />
          </Col> */}
          <Col
            xs={24}
            sm={24}
            md={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              textAlign: "center",
              alignContent: "flex-end",
              justifyContent: "start",
            }}
          >
            <Form.CheckBox
              field={"send_in_demand"}
              label={t("form:send_in_demand")}
            />
          </Col>
          {send_in_demand && (
            <Col xs={24} sm={24} md={12}>
              <Form.Date
                field={"opening_date"}
                disabledDate={(current) => current && current < accountingDate}
                label={t("form:opening_date")}
                defaultPickerValue={moment(accountingDate)}
                rules={[
                  {
                    required: true,
                    message: t("error:opening_date_is_required"),
                  },
                ]}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={24}>
            <HStack space="5" justifyContent={"center"}>
              <Radio.Group
                style={{
                  borderRadius: "5px",
                }}
                size="small"
                buttonStyle={{
                  borderRadius: "5px",
                }}
              >
                <Radio.Button
                  value="small"
                  style={{
                    background: isShow ? "#683fe9" : "",
                    borderColor: isShow ? "#683fe9" : "",
                    color: isShow ? "white" : "#683fe9",
                    borderBottomLeftRadius: "8px",
                    borderTopLeftRadius: "8px",
                  }}
                  onClick={() => {
                    setIsShow(true);
                    setShowNominee(false);
                  }}
                >
                  {t("select_nominee")}
                </Radio.Button>
                <Radio.Button
                  value="default"
                  onClick={() => {
                    setShowNominee(true);
                    setIsShow(false);
                  }}
                  style={{
                    background: showNominee ? "#683fe9" : "",
                    borderColor: showNominee ? "#683fe9" : "",
                    color: showNominee ? "white" : "#683fe9",
                    borderBottomRightRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                >
                  {t("add_nominee")}
                </Radio.Button>
              </Radio.Group>
            </HStack>
          </Col>

          {!showNominee && isShow && (
            <Col xs={24} sm={24} md={12}>
              <Form.Select
                field={"nominee_id"}
                label={t("nominee")}
                options={nominee_list}
                labelField={"name"}
                valueField={"id"}
                rules={[
                  {
                    required: true,
                    message: t("error:nominee_is_required"),
                  },
                ]}
              />
            </Col>
          )}
          {showNominee && (
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12}>
                <Form.TextBox
                  field={["nominee", "name"]}
                  label={t("form:nominee_name")}
                  rules={[
                    {
                      required: true,
                      message: t("nominee_name_required"),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.TextBox
                  field={["nominee", "mobile_number"]}
                  label={t("form:nominee_mobile_number")}
                  rules={[
                    {
                      required: true,
                      message: t("error:nominee_mobile_number_required"),
                    },
                    {
                      pattern: mobileNumberRegex,
                      message: t("error:enter_valid_mobile_number"),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Select
                  field={["nominee", "relationship"]}
                  label={t("nominee_relationship")}
                  options={relationship}
                  labelField={"name"}
                  valueField={"id"}
                  rules={[
                    {
                      required: true,
                      message: t("error:nominee_relationship_required"),
                    },
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.TextArea
                  field={["nominee", "address"]}
                  label={t("form:nominee_address")}
                  rules={[
                    {
                      required: true,
                      message: t("error:nominee_address_required"),
                    },
                  ]}
                />
              </Col>
            </Row>
          )}
        </Row>
        <HStack space={"10px"} justifyContent="flex-end" alignItems={"end"}>
          <Button loading={loading} type="primary" htmlType="submit">
            {t("submit")}
          </Button>
          <Button
            isLoading={false}
            width="100px"
            danger
            colorScheme="primary"
            variant="solid"
            onClick={props.close}
          >
            {t("close")}
          </Button>
        </HStack>
      </Form>
    </Box>
  );
};

export default MemberRDDetails;
