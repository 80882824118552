import React, { useEffect, useRef, useState } from "react";
import { Box, HStack, Text, VStack } from "native-base";
import { useTranslation } from "react-i18next";
import {
  getDepartmentListSelector,
  getDemandGenerationListSelector,
  get_departments,
  get_demand_generations,
  get_society_deposit_product_list,
  query_accounting_date,
  useDynamicSelector,
  dynamicRequest,
  dynamicClear,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";

import { Button, DatePicker, Dropdown } from "antd";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/ant_table";
import Modal from "@views/components/ui/modal";
import { showToast } from "@helpers/toast";
import { amountFormat } from "@helpers/utils";
import DownloadDemandAsZip from "../download_demand/downloading_demand_zip";
import SelectFilter from "@views/components/modules/select_filter/select_filter";
import * as XLSX from "xlsx";
import RecordCollectionExcel from "../../collection/record_collection_excel";
import { formatStatus } from "../../../../helpers/constants";
import BulkMailConfirmation from "./bulk_mail_confirmation";
import {
  get_all_demand_formats,
  get_all_rd_products,
} from "../../../../services/redux";
import DCBReportDownload from "../download_demand/dcb_report";
// import ReportDownload from "./bulk_demand_excel_report";
import { upperCase } from "lodash";
import { useDownloadExcel } from "react-export-table-to-excel";
import { usePageComponentAccess } from "../../../../helpers/auth";
import ReportDownload from "./payofficer_collection_excel";
import {
  demandDisabledRange,
  demand_disabled_range,
  disabled_range,
} from "../../../../helpers/functions";

const DemandGenerationList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const moment = require("moment");
  const current_month = moment().format("MMMM");

  let society_id = localStorage.getItem("society_id");
  const [query, setQuery] = useState({});
  const [confirmation_modal, setConfirmationModal] = useState(false);
  const [month, setMonth] = useState();
  const [current_month_demand, setCurrentMonthDemand] = useState("existing");
  const [sub_department, setSubDepartment] = useState([]);
  const [department_id, setDepartmentId] = useState("");
  console.log("department_id", department_id);
  const [sub_department_id, setSubDepartmentId] = useState([]);
  const [showDownload, setShowDownload] = useState(false);
  const [excel_modal_open, setExcelModalOpen] = useState(false);
  const [email_modal_open, setEmailModalOpen] = useState(false);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const [alreadySelectedData, setAlreadySelectedData] = useState([]);
  const [values, set_values] = useState([]);
  const [show_report_modal, set_show_report_modal] = useState(false);
  const { items: departments } = useSelector(getDepartmentListSelector);
  const { loading, items, total_items } = useSelector(
    getDemandGenerationListSelector
  );
  const printRef = useRef(null);
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const { status: createStatus, error: createError } = useDynamicSelector(
    "uploadCollectionDemand"
  );
  const {
    loading: create_mail_queue_loading,
    status: create_mail_queue_status,
    error: create_mail_queue_error,
  } = useDynamicSelector("createMailQueue");

  useEffect(() => {
    dispatch(get_departments());
    getAccountingDate();
    dispatch(get_society_deposit_product_list({ society_id: society_id }));
    getAllDemandsFormatContent();
    getRdLoanProductList();
  }, []);

  useEffect(() => {
    if (sub_department?.length === 1) {
      setSubDepartmentId(sub_department?.[0]?.id);
    } else {
      setSubDepartmentId(null);
    }
  }, [sub_department]);

  const getRdLoanProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const getAllDemandsFormatContent = () => {
    let keys = [{ key: "get_demandPage_formats", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_all_demand_formats, variables));
  };

  useEffect(() => {
    setQuery({
      sub_department_id_list: sub_department_id,
      date: month,
      department_id: department_id,
    });
  }, [sub_department_id, month, department_id]);

  useEffect(() => {
    let temp = [];
    if (department_id) {
      temp = departments?.find((x) => x.id === department_id)?.sub_departments;
    }
    temp = temp.map((list) => {
      return {
        id: list?.id,
        name: list?.code ? `${list?.code} - ${list?.name}` : `${list.name}`,
      };
    });
    setSubDepartment(temp);
  }, [department_id]);

  useEffect(() => {
    if (createError) {
      showToast({
        type: "error",
        message: createError?.message?.includes("Couldn't approve")
          ? createError?.message
          : t("something_went_wrong"),
      });
      dispatch(dynamicClear("uploadCollectionDemand"));
      handleExcelModalClose();
    } else if (createStatus === "Success") {
      showToast({
        type: "success",
        message: `${t("collection_upload_success")}`,
      });

      handleExcelModalClose();

      dispatch(dynamicClear("uploadCollectionDemand"));
    } else if (create_mail_queue_status === "Success") {
      get_demand_generations({
        page_limit: page_limit,
        page_number: page_number,
        date: month,
      });
    }
  }, [createStatus, createError, create_mail_queue_status]);

  const handleView = (item) => {
    return (
      <a
        target="_blank"
        href={`/generated-demand-for-society/${item?.id}/${item?.sub_department_id}`}
      >
        {t("view")}
      </a>
    );
  };

  const handleConfirmationModalOpen = () => {
    setConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModal(false);
  };

  const handleExcelModalOpen = () => {
    setExcelModalOpen(true);
  };
  const handleExcelModalClose = () => {
    setExcelModalOpen(false);
  };

  const handleEMailModalOpen = () => {
    setEmailModalOpen(true);
  };
  const handle_report_modal = () => {
    set_show_report_modal((prevState) => !prevState);
  };
  const handleEmailModalClose = () => {
    setEmailModalOpen(false);
    setAlreadySelectedData([]);
    setAlreadySelectedRows([]);
  };

  const disabledDate = (current) => {
    return (
      current &&
      (current < moment("2024-03-01") ||
        current > moment(getSocietyAccountingDay?.current_accounting_day))
    );
  };

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];

    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const formatDate = (date) => {
    if (!date) {
      return "-";
    }
    date = new Date(Number(date));
    return moment(date).format("DD/MM/YYYY");
  };

  const formatMonth = (value) => {
    return moment.unix(value / 1000).format("MMMM");
  };

  const formatDepartment = (values) => {
    return (
      <VStack space={"2px"}>
        <Text bold>{values?.department.name}</Text>
        <HStack>
          <Text>{values?.name?.trim()}</Text>
          {values?.code && <Text>{` - ${values?.code}`}</Text>}
        </HStack>
      </VStack>
    );
  };

  let column1 = [
    // {
    //   header: t("table:department"),
    //   dataIndex: "sub_department.department.name",
    //   key: "sub_department",
    //   sortable: false,
    //   // formatDisplay: formatDate,
    //   align: "left",
    //   flex: 1,
    // },
    {
      header: t("table:department_subdepartment"),
      dataIndex: "sub_department",
      key: "department",
      formatDisplay: formatDepartment,
      sortable: false,
      align: "left",
      flex: 1,
    },
    {
      header: t("table:generated_on"),
      dataIndex: "demand_date",
      key: "generated_accounting_date",
      sortable: false,
      formatDisplay: formatDate,
      align: "left",
      width: 130,
    },
    {
      header: t("table:for_the_month"),
      dataIndex: "demand_date",
      key: "demand_date",
      sortable: false,
      formatDisplay: formatMonth,
      align: "left",
      width: 130,
    },
    // {
    //   header: t("table:email_status"),
    //   dataIndex: "mail_queue_status",
    //   key: "month",
    //   sortable: false,
    //   formatDisplay: (record) => formatStatus(record?.[0]?.status),
    //   align: "left",
    //   width: 140,
    // },
    // {
    //   header: t("table:generated_on"),
    //   dataIndex: "generated_date",
    //   key: "month",
    //   sortable: false,
    //   formatDisplay: formatDate,
    //   align: "left",
    //   width: 100,
    // },
    {
      header: t("table:total_members"),
      dataIndex: "total_members",
      key: "total_members",
      sortable: false,
      align: "left",
      width: 100,
    },
    {
      header: t("table:total_demand"),
      dataIndex: "total_demand",
      key: "total_demand_amount",
      sortable: false,
      align: "left",
      width: 200,
      formatDisplay: amountFormat,
    },

    {
      header: t("table:View"),
      dataIndex: "",
      key: "",
      sortable: false,
      align: "left",
      flex: 1,
      formatDisplay: handleView,
    },
  ];

  const handleDownloadExcel = () => {
    setShowDownload(true);
  };
  useEffect(() => {
    if (items) {
      const combinedJsonData = items.reduce((acc, currentItem) => {
        let jsonData = JSON.parse(currentItem.demand_output);
        jsonData.department = currentItem.sub_department;
        let re_generate_value = jsonData?.map((list) => {
          return {
            ...list,
            department: currentItem?.sub_department,
          };
        });
        acc = acc.concat(re_generate_value);
        return acc;
      }, []);
      let jsonData = combinedJsonData;
      set_values(jsonData);

      // const worksheet = XLSX.utils.json_to_sheet(inputFormat);
      // const columnWidths = {};
      // Object.keys(worksheet).forEach((key) => {
      //   const column = key.replace(/[0-9]/g, ""); // Extract column letters
      //   const width =
      //     (worksheet[key].v ? worksheet[key].v.toString().length : 10) + 2; // Calculate width based on cell value
      //   columnWidths[column] = Math.max(columnWidths[column] || 0, width); // Store maximum width for each column
      // });

      // // Set column widths
      // worksheet["!cols"] = Object.keys(columnWidths).map((column) => ({
      //   wch: columnWidths[column],
      // }));

      // const workbook = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // const outputFilePath = `Demand.xlsx`;

      // XLSX.writeFile(workbook, outputFilePath, {
      //   bookType: "xlsx",
      //   type: "buffer",
      // });
      // });
    }
  }, [items]);
  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };
  useEffect(() => {
    get_demand_generations({
      page_limit: page_limit,
      page_number: page_number,
      date: month,
    });
  }, [page_limit, page_number, month, create_mail_queue_status]);

  useEffect(() => {
    setMonth(getSocietyAccountingDay?.current_accounting_day);
  }, [getSocietyAccountingDay]);

  const handleDCBReport = (type, fn, dl, excelData) => {
    var elt = document.getElementById("dcb-report");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "Demand" });

    // Get the first sheet in the workbook
    var ws = wb.Sheets[wb.SheetNames[0]];

    // Loop through each cell in the sheet and apply text-align style
    for (var cell in ws) {
      if (ws.hasOwnProperty(cell)) {
        // Check if it's a cell with content (not a header, etc.)
        if (cell[0] === "!" || cell[0] === "_") continue;

        // Apply text-align style to each cell
        ws[cell].s = {
          alignment: { horizontal: "center" /* or "left" or "right" */ },
        };

        // Convert x?.unique_id to string if it exists in the sheet
        excelData.forEach((report) => {
          report.answers.forEach((x) => {
            if (ws[cell].v === x.unique_id) {
              ws[cell].t = "s"; // Indicate that the cell contains a string
              ws[cell].v = "'" + x.unique_id.toFixed(2).toString(); // Convert to string and prepend with apostrophe
            }
          });
        });
      }
    }

    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(wb, fn || `Report.` + (type || "xlsx"));
  };

  const handleDCBReport2 = (type, fn, dl, excelData) => {
    var elt = document.getElementById("dcb-report2");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "Demand" });

    // Get the first sheet in the workbook
    var ws = wb.Sheets[wb.SheetNames[0]];

    // Loop through each cell in the sheet and apply text-align style
    for (var cell in ws) {
      if (ws.hasOwnProperty(cell)) {
        // Check if it's a cell with content (not a header, etc.)
        if (cell[0] === "!" || cell[0] === "_") continue;

        // Apply text-align style to each cell
        ws[cell].s = {
          alignment: { horizontal: "center" /* or "left" or "right" */ },
        };

        // Convert x?.unique_id to string if it exists in the sheet
        excelData.forEach((report) => {
          report.answers.forEach((x) => {
            if (ws[cell].v === x.unique_id) {
              ws[cell].t = "s"; // Indicate that the cell contains a string
              ws[cell].v = "'" + x.unique_id.toFixed(2).toString(); // Convert to string and prepend with apostrophe
            }
          });
        });
      }
    }

    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(wb, fn || `Report.` + (type || "xlsx"));
  };
  const { onDownload } = useDownloadExcel({
    currentTableRef: printRef.current,
    filename: `Demand - ${upperCase(moment(month).format("MMM/YYYY"))}`,
    sheet: "Demand",
    excelData: values,
    handleDownload: handleDCBReport,
  });

  const handleSampleCollection = () => {
    let inputFormat = values.map((list) => {
      return {
        ["Pay Officer Code"]: list?.department?.code,
        ["Mem.No"]: list?.member_number,
        ["Amount"]: calculateTotalDemandValue(list),
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(inputFormat);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `Demand - ${upperCase(moment(month).format("MMM/YYYY"))}.xlsx`
    );
  };

  const print_items = [
    {
      key: "normal",
      label: "Simple ",
    },
    {
      key: "expand",
      label: "Detailed",
    },
  ];

  useEffect(() => {
    if (show_report_modal) {
      const interval = setInterval(handle_report_modal, 2000);
      onDownload();
      return () => clearInterval(interval);
    }
  }, [show_report_modal]);
  const onclick_download = (value) => {
    if (value?.key === "normal") {
      handleSampleCollection();
    } else {
      handle_report_modal();
    }
  };

  const total_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0;
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
      loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) -
          Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal;
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal;
    } else {
      return monthly_principal;
    }
  };

  const calculateTotalDemandValue = (record) => {
    let deposits = record?.deposits;
    let loans = record?.surety_loans;
    let member_due = record?.member_duetos;

    let total = deposits?.reduce((sum, deposit) => {
      const amount = Number(deposit?.amount || 0);
      const arrearAmount =
        Number(deposit?.arrear_amount || 0) +
        Number(deposit?.current_month_balance || 0);

      return sum + amount + arrearAmount;
    }, 0);
    record?.recurring_deposit?.forEach((rd) => {
      total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
    });
    loans?.forEach((loan) => {
      if (loan?.surety_loan_demands) {
        loan.surety_loan_demands?.forEach((demand) => {
          total +=
            Number(total_principal(loan) || 0) +
            Number(demand?.interest || 0) +
            Number(demand?.penal_interest || 0) +
            Number(demand?.arrear_monthly_principal || 0) +
            Number(demand?.arrear_interest || 0) +
            Number(demand?.arrear_penal_interest || 0) +
            Number(demand?.decree_interest || 0);
        });
      }
    });

    member_due?.forEach((dueto) => {
      total += dueto?.balance_amount || 0;
    }, 0);

    return total;
  };

  return (
    <>
      <Box width="100%" overflow={"hidden"}>
        <HStack space="3">
          <Box flex={1}>
            <Text bold>{t("department")}</Text>
            <SelectFilter options={departments} setField={setDepartmentId} />
          </Box>
          <Box flex={1}>
            <Text bold>{t("sub_department")}</Text>
            <SelectFilter
              value={sub_department_id}
              options={sub_department}
              setField={setSubDepartmentId}
            />
          </Box>
          <Box flex={1}>
            <Text bold>{t("month")}</Text>
            <DatePicker
              defaultValue={moment(month)}
              value={moment(month)}
              format="MMMM"
              picker="month"
              disabledDate={demandDisabledRange()}
              onChange={(e) => {
                setMonth(e);
              }}
              allowClear={false}
            />
          </Box>

          {items?.length ? (
            <>
              <Box alignSelf={"center"} mt="5">
                <DownloadDemandAsZip data={items} />
              </Box>

              <Box alignSelf={"center"} mt="5">
                <Dropdown.Button
                  type="primary"
                  menu={{
                    items: print_items,
                    onClick: onclick_download,
                  }}
                  className="demand-button"
                  style={{ backgroundColor: "#683fe9 !import" }}
                  loading={show_report_modal}
                >
                  {t("download_demand_excel")}
                </Dropdown.Button>
              </Box>
              <Box alignSelf={"center"} mt="5">
                <Button
                  onClick={() => handleExcelModalOpen()}
                  type="primary"
                  className="demand-button"
                >
                  {t("bulk_upload")}
                </Button>
              </Box>
              {/* <Box alignSelf={"center"} mt="5">
                <Button onClick={() => handleEMailModalOpen()} type="primary" className="demand-button" disabled={alreadySelectedRows?.length === 0 ? true : false}>
                  {t("bulk_email")}
                </Button>
              </Box> */}
              {/* <Box alignSelf={"center"} mt="5">
              </Box> */}
            </>
          ) : (
            ""
          )}
        </HStack>

        <Wrapper>
          <Table
            width="100%"
            rowKey="id"
            group="demand"
            loading={loading}
            data={items}
            columns={column1}
            hasSearch={false}
            hasPagination={false}
            fetch={get_demand_generations}
            query={query}
            // rowSelection={{
            //   selectedRowKeys: alreadySelectedRows,
            //   onChange: (keys, value) => {
            //     setAlreadySelectedRows(keys);
            //     setAlreadySelectedData(value);
            //   },
            //   // selections: [
            //   //   Table.SELECTION_ALL,
            //   //   Table.SELECTION_NONE,
            //   //   Table.SELECTION_INVERT,
            //   // ],
            // }}
            headerActions={[]}
            emptyMessage={t("table:empty_demand")}
            headerColor={"white"}
          />
        </Wrapper>
      </Box>

      {/* <HStack justifyContent={"flex-end"} mt="5">
        <Pagination
          // showTotal={}
          // total={demand?.total_count}
          showSizeChanger={true}
          page_number={page_number}
          hideOnSinglePage={false}
          onChange={fetchData}
          // defaultPageSize={parseInt(pageSizeOptions[0])}
          // pageSizeOptions={pageSizeOptions}
        />
      </HStack> */}

      <Modal
        isOpen={email_modal_open}
        onClose={handleEmailModalClose}
        header={t("bulk_email_processing_confirmation")}
        component={
          <BulkMailConfirmation
            close={handleEmailModalClose}
            data_source={alreadySelectedData}
            handleView={handleView}
            formatDepartment={formatDepartment}
            formatMonth={formatMonth}
            formatDate={formatDate}
            fetch_data={get_demand_generations({
              page_limit: page_limit,
              page_number: page_number,
              date: month,
            })}
          />
        }
        source={{}}
        width={"1000px"}
      />
      <Modal
        isOpen={excel_modal_open}
        onClose={handleExcelModalClose}
        header={t("bulk_processing")}
        component={<RecordCollectionExcel close={handleExcelModalClose} />}
        source={{}}
        width={"1000px"}
      />
      <div style={{ display: "none" }} id="dcb-report" ref={printRef}>
        {show_report_modal && <ReportDownload items={values} />}
      </div>
    </>
  );
};
export default DemandGenerationList;
