import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import { upperCase } from "lodash";
import html2pdf from "html2pdf.js";
import { showToast } from "./toast";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";

const current_date_time = () => {
  let date = new Date();
  let hours = date.getHours();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let seconds = date.getSeconds();
  seconds = seconds < 10 ? "0" + seconds : seconds;
  return `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()} ${hours}:${minutes}:${seconds} ${ampm}`;
};

export const dateRangeBetweenAccountingDate = (current) => {
  const endDate = moment(getSocietyAccountingDay?.current_accounting_day);

  const startDate = moment(endDate).startOf("month");

  return current < startDate || current > endDate;
};

export const calculateTotalValue = (record) => {
  let total = record?.deposits?.reduce((sum, deposit) => {
    const amount = Number(deposit?.amount) || 0;
    const arrearAmount = Number(deposit?.arrear_amount) || 0;
    return sum + amount + arrearAmount;
  }, 0);
  record.surety_loans?.forEach((loan) => {
    loan.surety_loan_demands?.forEach((demand) => {
      total +=
        Number(demand.monthly_principal || 0) +
        Number(demand.interest || 0) +
        Number(demand.penal_interest || 0);
    });
  });
  return total;
};

export function getMenuItemsIfLengthMatches(menu, item) {
  const { loan, fd, rd, jl, staff_loan } = item;
  let filteredMenu = [...menu]; // Create a copy of the menu

  if (!loan || !loan.length) {
    filteredMenu = filteredMenu.filter(
      (menuItem) => !menuItem.title.includes("Loans")
    );
  }

  if (!fd || !fd.length) {
    filteredMenu = filteredMenu.filter(
      (menuItem) => !menuItem.title.includes("Fixed Deposit")
    );
  }

  if (!rd || !rd.length) {
    filteredMenu = filteredMenu.filter(
      (menuItem) => !menuItem.title.includes("Recurring Deposit")
    );
  }

  if (!jl || !jl.length) {
    filteredMenu = filteredMenu.filter(
      (menuItem) => !menuItem.title.includes("Jewel Loan")
    );
  }

  if (!staff_loan || !staff_loan.length) {
    filteredMenu = filteredMenu.filter(
      (menuItem) => !menuItem.title.includes("Staff Loans")
    );
  }

  return filteredMenu;
}

export function exportToExcel(data, file_name) {
  if (!data.length > 0) return;

  const style = {
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
  };

  const ws = XLSX.utils.json_to_sheet(data, {
    header: Object.keys(data[0]),
    styles: { header: [style], cell: [style] },
  });

  // Initialize arrays to store the maximum width and height for each column and row
  const column_widths = Array(Object.keys(data[0]).length).fill(0);
  const row_heights = Array(data.length).fill(0);

  // Iterate over each row of data to determine the maximum width and height needed for each column and row
  data.forEach((row, rowIndex) => {
    Object.entries(row).forEach(([key, cell], columnIndex) => {
      const cell_text_width = cell ? cell.toString().length * 1.5 : 0; // Adjust multiplier as needed
      if (cell_text_width > column_widths[columnIndex]) {
        column_widths[columnIndex] = cell_text_width;
      }
      const cell_text_height = cell ? cell.toString().split("\n").length : 1; // Counting lines for multi-line text
      if (cell_text_height > row_heights[rowIndex]) {
        row_heights[rowIndex] = cell_text_height;
      }
    });
  });

  // Set column widths and row heights in the worksheet
  ws["!cols"] = column_widths.map((width) => ({ width }));
  ws["!rows"] = row_heights.map((height) => ({ hpx: height * 15 })); // Assuming each line height is 15 pixels

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const blob = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${file_name}-${current_date_time()}.xlsx`);
}

export const arrangeXLReportData = (data_items, excelObject) => {
  const rearranged_data = [];
  for (let i = 0; i < data_items?.length; i++) {
    let row_data = {};
    for (const key in excelObject) {
      if (excelObject.hasOwnProperty(key)) {
        row_data[key] = excelObject[key];
      }
    }
    rearranged_data.push(row_data);
  }
  return rearranged_data;
};

export const calculateAmountTotal = ({ data, location }) => {
  return data?.reduce((acc, obj) => {
    const keys = location?.split(".");
    let value = obj;
    for (const key of keys) {
      value = value[key];
    }
    if (!isNaN(value)) {
      acc += value;
    }
    return acc;
  }, 0);
};

export const disabledDate = (current) => {
  // Disable all dates after the current month
  return current && current > moment().endOf("month");
};

export const common_generatePdf = async ({
  file_name,
  society_header,
  address,
  receipt_type,
  mode,
  data,
  journal_entry_payments,
  accounting_day,
}) => {
  let filteredPayments;
  if (receipt_type === "Bank") {
    filteredPayments = journal_entry_payments?.filter(
      (entry) => entry?.credit_debit === "credit"
    );
  } else if (receipt_type != "Bank") {
    filteredPayments = journal_entry_payments?.filter(
      (entry) => entry?.credit_debit === "debit"
    );
  }
  const glAccountNames = filteredPayments?.map(
    (entry) => entry?.gl_account?.name
  );

  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: "a4",
    left: 3,
    right: 10,
    top: 10,
    bottom: 5,
    autoPrint: {
      variant: "non-conform",
      dialog: false,
    },
  });

  const ref = { current: null };
  ref.current = doc;

  let margin = {
    header: 1,
    footer: 1,
  };

  const footerContent1 = "Payer's Signature";
  const footerContent2 = "Secretary's Signature";
  const headerContent = `${
    society_header ??
    `DIRECTORATE OF PUBLIC HEALTH AND PREVENTIVE MEDICINE STAFF 
              CO-OP THRIFT AND CREDIT SOCIETY LTD - TIXC237`
  }`;
  const address1 = address
    ? `${address}`
    : `          359, ANNA SALAI, TEYNAMPET, D.M.S. CAMPUS, CHENNAI-600 006`;

  // Header
  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text(headerContent, margin.header, 10);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(9);
  doc.text(address1, margin.header, 20);
  doc.setFont("helvetica", "bold");
  doc.setDrawColor(0);
  const textWidth = doc.getTextWidth(
    receipt_type === "Bank" ? "Adjustment Receipt" : "Cash Receipt"
  );
  const rectWidth = textWidth + 5;
  const textX = 50 + (rectWidth - textWidth) / 2;

  doc.setTextColor(128);
  doc.rect(50, 29, rectWidth, 10);
  doc.setTextColor(0);
  doc.text(
    receipt_type === "Bank" ? "Adjustment Receipt" : "Cash Receipt",
    textX,
    35
  );

  //Main
  doc.text(`Date: ${accounting_day}`, 100, 45);

  // // Member details
  const fields = {
    ["Member Name"]: data?.name,
    ["Member No"]: data?.member_number,
    ["Receipt No"]: data?.receipt_voucher_no,
  };

  doc.setFontSize(12);
  let y = 51;
  let maxLabelWidth = 0;
  Object.keys(fields).forEach((label) => {
    const labelWidth = doc.getTextWidth(`${label}: `);
    if (labelWidth > maxLabelWidth) {
      maxLabelWidth = labelWidth;
    }
  });

  Object.entries(fields).forEach(([label, value]) => {
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(`${label}    `, 1, y);
    doc.setFont("helvetica", "normal");
    doc.text(`: ${upperCase(value)}`, 1 + maxLabelWidth + 5, y);
    y += 6;
  });

  doc.setFont("helvetica", "bold");
  doc.setFontSize(9);
  doc.text("Particulars", margin.footer, doc.internal.pageSize.height - 130);
  doc.text("Rs.", 100, doc.internal.pageSize.height - 130);
  doc.setFont("helvetica", "normal");

  doc.setDrawColor(230, 230, 230);
  // const lineHeight = 5;
  // const gap = 5;

  // let y2 = 82;
  // for (let i = 0; i < 11; i++) {
  //   doc.line(1, y2, 126, y2);
  //   doc.text("name", x, y);
  //   y2 += lineHeight + gap;
  // }
  const lineHeight = 5;
  const gap = 5;
  let y2 = 82;

  for (let i = 0; i < 11; i++) {
    doc.line(1, y2, 126, y2);
    if (filteredPayments?.length > 0) {
      doc.text(
        i === 0 ? "" : glAccountNames[i - 1] || "",
        1,
        y2 - lineHeight / 2
      );
      doc.text(
        i === 0 ? "" : glAccountNames[i - 1] || "",
        90,
        y2 - lineHeight / 2
      );
    }
    y2 += lineHeight + gap;
  }

  // Footer
  doc.setFontSize(9);
  doc.setFont("helvetica", "bold");
  doc.text(footerContent1, margin.footer, doc.internal.pageSize.height - 6);
  doc.text(footerContent2, 92, doc.internal.pageSize.height - 6);

  if (mode === "print") {
    doc.autoPrint();
    doc.output("dataurlnewwindow");
    // common_download_pdf({ filename: file_name, ref });
  } else if (mode === "download") {
    doc.save(`${file_name ?? "Receipt"}.pdf`);
  }
};

export const common_download_pdf = ({ filename, ref, mode = "landscape" }) => {
  const pdfOptions = {
    filename: `${filename}.pdf`,
    jsPDF: { unit: "mm", format: "a4", orientation: mode },

    html2canvas: { scale: 2 },
    image: { type: "jpeg", quality: 1 },
    margin: [10, 10, 10, 10],
    pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  };

  // prevent content from breaking while print
  const style = `
    <style>
      body {
        font-size: 10px;
      }
      .page-break {
        page-break-before: always;
      }
    </style>
  `;

  const container = document.createElement("div");
  container.innerHTML = style + ref.current.outerHTML;

  // Convert to a PDF
  html2pdf()
    .set(pdfOptions)
    .from(container)
    .toPdf()
    .get("pdf")
    .then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const fontSize = 7;

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(fontSize);
        pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 25, pageHeight - 8);
      }
    })
    .save();
};

//corrected
export const common_print_pdf = ({ filename, ref }) => {
  const pdfOptions = {
    filename: `${filename}.pdf`,
    jsPDF: { unit: "mm", format: "A4", orientation: "landscape" },
    html2canvas: { scale: 1 },
    image: { type: "jpeg", quality: 0.98 },
    pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    margin: [10, 10, 10, 10],
    html2canvas: {
      allowTaint: true,
      useCORS: true,
      logging: true,
      letterRendering: true,
    },
    style: `
    body {
      font-size: 10px;
    }
    .page-break {
      page-break-before: always;
    }
    body {
      font-size: 7px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      page-break-inside: avoid; 
      /* Avoid page break inside table */
    }
    table, th, td {
      border: 1px solid black;
      /* Avoid page break inside table cells */
      page-break-inside: avoid; 
    }
    th, td {
      padding: 5px;
      text-align: left;
      page-break-inside: avoid; 
      /* Avoid page break inside table cells */
    }
    tr {
      page-break-inside: avoid; 
      /* Avoid page break inside table rows */

      page-break-after: auto; 
      /* Ensure rows do not break unexpectedly */
    }
    .no-break {
      page-break-inside: avoid; /* Class to avoid breaking inside specific elements */
      break-inside: avoid;
    }
  `,
  };

  html2pdf()
    .set(pdfOptions)
    .from(ref.current)
    .toPdf()
    .get("pdf")
    .then((pdf) => {
      const totalPages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const fontSize = 7;

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(fontSize);
        pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 25, pageHeight - 2);
      }
    })
    .output("blob")
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const iframe = document.createElement("iframe");
      iframe.style.visibility = "hidden";
      iframe.src = url;
      document.body.appendChild(iframe);

      iframe.onload = function () {
        const handlePrint = () => {
          window.focus();
          iframe.contentWindow.print();
        };

        const handleFocus = () => {
          document.body.removeChild(iframe);
          window.removeEventListener("focus", handleFocus);
        };

        window.addEventListener("focus", handleFocus);
        handlePrint();
      };
    });
};

// new
// export const common_print_pdf = ({ filename, ref }) => {
//   const pdfOptions = {
//     filename: `${filename}.pdf`,
//     jsPDF: { unit: "mm", format: "A4", orientation: "landscape" },
//     html2canvas: { scale: 2 },
//     image: { type: "jpeg", quality: 0.98 },
//     margin: [1, 5],
//     style: `
//       body {
//         font-size: 8px;
//       }
//     `,
//   };

//   html2pdf()
//     .set(pdfOptions)
//     .from(ref.current)
//     .toPdf()
//     .get("pdf")
//     .then((pdf) => {
//       const totalPages = pdf.internal.getNumberOfPages();
//       const pageWidth = pdf.internal.pageSize.getWidth();
//       const pageHeight = pdf.internal.pageSize.getHeight();
//       const fontSize = 7;

//       for (let i = 1; i <= totalPages; i++) {
//         pdf.setPage(i);
//         pdf.setFontSize(fontSize);
//         pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 25, pageHeight - 8);
//       }
//     })
//     .output("blob")
//     .then((blob) => {
//       const url = URL.createObjectURL(blob);
//       const iframe = document.createElement("iframe");
//       iframe.style.visibility = "hidden";
//       iframe.src = url;
//       document.body.appendChild(iframe);

//       iframe.onload = function () {
//         const handlePrint = () => {
//           window.focus();
//           iframe.contentWindow.print();
//         };

//         const handleFocus = () => {
//           document.body.removeChild(iframe);
//           window.removeEventListener("focus", handleFocus);
//         };

//         window.addEventListener("focus", handleFocus);
//         handlePrint();
//       };
//     });
// };

// old
// export const common_print_pdf = ({ filename, ref }) => {
//   const pdfOptions = {
//     filename: `${filename}.pdf`,
//     jsPDF: { unit: "mm", format: "A4", orientation: "landscape" },
//     html2canvas: { scale: 2 },
//     image: { type: "jpeg", quality: 0.98 },
//     margin: [1, 5],
//     style: `
//       body {
//         font-size: 8px;
//       }
//     `,
//   };

//   html2pdf()
//     .set(pdfOptions)
//     .from(ref.current)
//     .get("pdf")
//     .then((pdf) => {
//       const totalPages = pdf.internal.getNumberOfPages();
//       const pageWidth = pdf.internal.pageSize.getWidth();
//       const pageHeight = pdf.internal.pageSize.getHeight();
//       const fontSize = 7;

//       for (let i = 1; i <= totalPages; i++) {
//         pdf.setPage(i);
//         pdf.setFontSize(fontSize);
//         pdf.text(`Page ${i} of ${totalPages}`, pageWidth - 25, pageHeight - 8);
//       }
//     })
//     .output("blob")
//     .then(function (blob) {
//       const url = URL.createObjectURL(blob);
//       const iframe = document.createElement("iframe");
//       iframe.style.visibility = "hidden";
//       iframe.src = url;
//       document.body.appendChild(iframe);

//       iframe.onload = function () {
//         const handlePrint = () => {
//           window.focus();
//           iframe.contentWindow.print();
//         };

//         const handleFocus = () => {
//           document.body.removeChild(iframe);
//           window.removeEventListener("focus", handleFocus);
//         };

//         window.addEventListener("focus", handleFocus);
//         handlePrint();
//       };
//     });
// };

export const disabled_range = (current) => {
  const from_date = moment(window.localStorage.getItem("accounting_from_date"));
  const to_date = moment(window.localStorage.getItem("accountingDate"));
  // return current && (current < from_date || current > to_date);
  if (from_date && current < moment(from_date).startOf("day")) {
    return true;
  }
  if (to_date && current > moment(to_date).endOf("day")) {
    return true;
  }
};

export const to_disabled_range = (current, from_date) => {
  const to_date = moment(window.localStorage.getItem("accountingDate"));
  if (
    from_date &&
    (current < moment(from_date).startOf("day") ||
      current > moment(to_date).endOf("day"))
  ) {
    return true;
  }
};

export const disabled_range_current_and_previous = (current) => {
  const current_accounting_day = window.localStorage.getItem("accountingDate");
  return (
    current &&
    (current < moment("2024-03-01") || current > moment(current_accounting_day))
  );
};

export const disabled_range_after_accounting_date = () => {
  const current_accounting_day = window.localStorage.getItem("accountingDate");
  return current_accounting_day.isAfter(current_accounting_day, "day");
};

export const disabledRangeBeforeAccountingDate = () => {
  const accountingDate = dayjs(window.localStorage.getItem("accountingDate"));

  return (current) => {
    return (
      accountingDate && accountingDate.isSameOrBefore(accountingDate, "day")
    );
  };
};
export const demandDisabledRange = () => {
  const startDate = moment("19/03/2024", "DD/MM/YYYY");

  return (current) => {
    const currentDate = moment(current);
    const rangeEndDate = moment(window.localStorage.getItem("accountingDate"));

    return (
      currentDate && (currentDate < startDate || currentDate > rangeEndDate)
    );
  };
};

export const receipt_page_title = (receipt_voucher_no) => {
  let title = receipt_voucher_no?.split("/");
  let first_name = "";
  let last_name = "";
  if (title?.[2] === "A") {
    first_name = "Adjustment";
  } else if (title?.[2] === "C") {
    first_name = "Cash";
  }
  if (title?.[1] === "V") {
    last_name = " Voucher";
  } else if (title?.[1] === "R") {
    last_name = " Receipt";
  }
  return first_name + last_name;
};

export const rejection_reasons = {
  NoConsolidationAllowed: "No Consolidation Allowed",
  ConsolidationPeriodNotReached: "Consolidation Period Not Reached",
  RequestAboveBorrowingPower: "Request Above Borrowing Power",
  RequestedAboveSocietyLimit: "Requested Above Society Limit",
  AboveMaximumAllowedPeriod: "Above Maximum Allowed Period",
  BelowMinimumAllowedPeriod: "Below Minimum Allowed Period",
  InvalidRequest: "Invalid Request",
  MemberGrossSalaryNotUpdated: "Member Gross Salary Not Updated",
  SuretyGrossSalaryNotUpdated: "Surety Gross Salary Not Updated",
  MemberOfficeDeductionsNotUpdated: "Member Office Deductions Not Updated",
  SuretyOfficeDeductionsNotUpdated: "Surety Office Deductions Not Updated",
  LoanAmountLesserThanOutstanding: "Loan Amount Lesser Than Outstanding",
  NoSurety: "No Surety",
  MemberDOBNotUpdated: "Member DOB Not Updated",
  SuretyDOBNotUpdated: "Surety DOB Not Updated",
  SuretyHasAlreadyActedAsSuretyForOthers:
    "Surety Has Already Acted As Surety For Others",
  LoanAmountShouldBeInLimit: "Loan Amount Should Be In Limit",
  NoOfInstallmentsShouldBeInLimit: "No Of Installments Should Be In Limit",
  NoNewLoanPeriodBeforeRetirement: "No New Loan Period Before Retirement",
  LoanMaturityBeforeRetirementMonth: "Loan Maturity Before Retirement Month",
  PreviousLoanOverdue: "Previous Loan Overdue",
  SuretyShareNotEnough: "Surety Share Not Enough",
  NoLoanForSomePeriodAfterOfDOJ: "No Loan For Some Period After Of DOJ",
  MemberHasOverDue: "Member Has Over Due",
  SuretyHasOverDue: "Surety Has OverDue",
  RequestAboveSuretyBorrowingPower: "Request Above Surety Borrowing Power",
};

export function getDayOfWeek(dateString) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date(dateString);
  return daysOfWeek[date.getDay()];
}

export const commonDateFormat = (date) => {
  return moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};

export const formatted_print = (table_class_name) => {
  const contentToPrint = document.getElementById(table_class_name);

  if (contentToPrint) {
    const printFrame = document.createElement("iframe");
    printFrame.style.position = "absolute";
    printFrame.style.width = "0";
    printFrame.style.height = "0";
    printFrame.style.border = "none";

    document.body.appendChild(printFrame);

    const printDocument =
      printFrame.contentDocument || printFrame.contentWindow.document;

    printDocument.write("<html><head><title>Print</title></head><body>");
    printDocument.write(
      "<style>@page { size: landscape;} body { font-family: Arial, sans-serif;padding:-20px; } table { border-collapse: collapse; width: 100%; } th, td { border: 1px solid #dddddd; padding: 20px; }</style>"
    );
    // printDocument.write(nativeBaseStyles);
    printDocument.write(contentToPrint.innerHTML);
    printDocument.write("</body></html>");
    printDocument.close();

    printFrame.contentWindow.print();

    // Remove the iframe after printing
    document.body.removeChild(printFrame);
  }
};

export function format_amount_decimal(value) {
  let cleanedValue = value.toString().replace(/,/g, "");
  let numberValue = parseFloat(cleanedValue);
  let roundedValue = numberValue.toFixed(2); // Round to 2 decimal places
  if (roundedValue.endsWith(".00")) {
    return parseInt(roundedValue); // Remove decimal part if it's .00
  } else if (roundedValue.endsWith("0")) {
    return parseFloat(roundedValue); // Keep one decimal if it's .10, .20, etc.
  } else {
    return parseFloat(roundedValue); // Keep two decimals if necessary
  }
}

export function indian_currency_format(amount) {
  if (amount) {
    let amountStr = amount.toString();
    let [integerPart, fractionalPart] = amountStr.split(".");

    // Format the integer part
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);
    if (otherDigits) {
      lastThreeDigits = "," + lastThreeDigits;
    }
    otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    let formattedInt = otherDigits + lastThreeDigits;

    // Combine the integer and fractional parts
    if (fractionalPart) {
      return "₹" + formattedInt + "." + fractionalPart;
    } else {
      return "₹" + formattedInt;
    }
  }
}
export function daybook_format_amount_decimal(value) {
  // Remove commas and convert to number
  let cleanedValue = value.toString().replace(/,/g, "");
  let numberValue = parseFloat(cleanedValue);
  // Format to 2 decimal places
  let formattedValue = numberValue.toFixed(2);
  // Add commas for thousands separator
  formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedValue;
}

export const formatFloat = (value) => {
  if (value === null || value === undefined) return value;
  const roundedValue = Math.round(parseFloat(value) * 10) / 10;
  return roundedValue.toFixed(1);
};

export const handle_excel_download = async (
  district_wise_count_list,
  show_percentage,
  is_show_aging
) => {
  const rearranged_data = district_wise_count_list?.map((item, index) => {
    const baseData = {
      ["S No"]: index + 1,
      ["District Name"]: item?.district_name,
      ["Total Societies"]: item?.total_society_count,
      ["Not Started"]: item?.not_started_process,
      ["Uploaded With Error (including manual check failed)"]:
        item?.uploaded_with_error_count,
      ["Validated (including manual check)"]:
        item?.uploaded_without_error_count,
      ["Test Credentials Given"]: item?.testing_society_count,
      ["Backlog Entry Started"]: item?.live_society_count,
      ["Live"]: item?.accounting_date_live_society_count,
    };

    if (show_percentage) {
      baseData["Total Societies"] = item?.total_society_count;
      baseData["Not Started"] =
        item?.not_started_process !== 0
          ? formatFloat(item?.not_started_process)
          : item?.not_started_process;
      baseData["Uploaded With Error (including manual check failed)"] =
        item?.uploaded_with_error_count !== 0
          ? formatFloat(item?.uploaded_with_error_count)
          : item?.uploaded_with_error_count;
      baseData["Validated (including manual check)"] =
        item?.uploaded_without_error_count !== 0
          ? formatFloat(item?.uploaded_without_error_count)
          : item?.uploaded_without_error_count;
      baseData["Test Credentials Given"] =
        item?.testing_society_count !== 0
          ? formatFloat(item?.testing_society_count)
          : item?.testing_society_count;
      baseData["Backlog Entry Started"] =
        item?.live_society_count !== 0
          ? formatFloat(item?.live_society_count)
          : item?.live_society_count;
      baseData["Live"] =
        item?.accounting_date_live_society_count !== 0
          ? formatFloat(item?.accounting_date_live_society_count)
          : item?.accounting_date_live_society_count;
    }

    if (is_show_aging) {
      baseData["Validated Aging"] = Math.round(item?.avg_days_to_complete);
      baseData["Test Credentials Given Aging"] = Math.round(
        item?.avg_day_to_verify
      );
    }

    return baseData;
  });

  const rowCount = rearranged_data.length;

  const totals = rearranged_data.reduce(
    (acc, curr) => {
      acc["Total Societies"] += curr["Total Societies"] || 0;
      acc["Not Started"] +=
        curr["Not Started"] !== 0 ? parseFloat(curr["Not Started"]) || 0 : 0;
      acc["Uploaded With Error (including manual check failed)"] +=
        curr["Uploaded With Error (including manual check failed)"] !== 0
          ? parseFloat(
              curr["Uploaded With Error (including manual check failed)"]
            ) || 0
          : 0;
      acc["Validated (including manual check)"] +=
        curr["Validated (including manual check)"] !== 0
          ? parseFloat(curr["Validated (including manual check)"]) || 0
          : 0;
      acc["Test Credentials Given"] +=
        curr["Test Credentials Given"] !== 0
          ? parseFloat(curr["Test Credentials Given"]) || 0
          : 0;
      acc["Backlog Entry Started"] +=
        curr["Backlog Entry Started"] !== 0
          ? parseFloat(curr["Backlog Entry Started"]) || 0
          : 0;
      acc["Live"] += curr["Live"] !== 0 ? parseFloat(curr["Live"]) || 0 : 0;
      acc["Validated Aging"] +=
        curr["Validated Aging"] !== 0
          ? parseFloat(curr["Validated Aging"]) || 0
          : 0;
      acc["Test Credentials Given Aging"] +=
        curr["Test Credentials Given Aging"] !== 0
          ? parseFloat(curr["Test Credentials Given Aging"]) || 0
          : 0;
      return acc;
    },
    {
      ["Total Societies"]: 0,
      ["Not Started"]: 0,
      ["Uploaded With Error (including manual check failed)"]: 0,
      ["Validated (including manual check)"]: 0,
      ["Test Credentials Given"]: 0,
      ["Backlog Entry Started"]: 0,
      ["Live"]: 0,
      ["Validated Aging"]: 0,
      ["Test Credentials Given Aging"]: 0,
    }
  );

  const totalsRow = {
    ["S No"]: "",
    ["District Name"]: show_percentage ? "" : "Total",
    ["Total Societies"]: show_percentage ? "" : totals["Total Societies"],
    ["Not Started"]: show_percentage ? "" : totals["Not Started"],
    ["Uploaded With Error (including manual check failed)"]: show_percentage
      ? ""
      : totals["Uploaded With Error (including manual check failed)"],
    ["Validated (including manual check)"]: show_percentage
      ? ""
      : totals["Validated (including manual check)"],
    ["Test Credentials Given"]: show_percentage
      ? ""
      : totals["Test Credentials Given"],
    ["Backlog Entry Started"]: show_percentage
      ? ""
      : totals["Backlog Entry Started"],
    ["Live"]: show_percentage ? "" : totals["Live"],
  };

  rearranged_data.push(totalsRow);

  const worksheet = XLSX.utils.json_to_sheet(rearranged_data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Migration Data");

  const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
    const address = XLSX.utils.encode_cell({ c: C, r: 0 });
    if (!worksheet[address]) continue;
    worksheet[address].s = {
      font: {
        bold: true,
        color: { rgb: "FFFFFF" },
      },
      fill: {
        fgColor: { rgb: "0000FF" },
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
        wrapText: true,
      },
    };
  }

  const totalRowIndex = rearranged_data.length - 1;
  for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
    const address = XLSX.utils.encode_cell({ c: C, r: totalRowIndex });
    if (!worksheet[address]) continue;
    worksheet[address].s = {
      font: {
        bold: true,
      },
    };
  }

  worksheet["!cols"] = [
    { wch: 5 },
    { wch: 20 },
    { wch: 10 },
    { wch: 10 },
    { wch: 25 },
    { wch: 20 },
    { wch: 15 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 15 },
  ];

  for (let R = 0; R <= totalRowIndex; ++R) {
    for (let C = 0; C <= headerRange.e.c; ++C) {
      const address = XLSX.utils.encode_cell({ c: C, r: R });
      if (!worksheet[address]) continue;
      worksheet[address].s = {
        alignment: {
          horizontal: "right", // Right alignment for all data cells
        },
        font: R === 0 ? { bold: true } : {}, // Bold font for headers
      };
    }
  }

  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const year = now.getFullYear();
  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedDateTime = `${day}.${month}.${year} - ${hours}${ampm}`;
  const filename = show_percentage
    ? `District-wise ECS Migration Status Percentage Report(${formattedDateTime}).xlsx`
    : `District-wise ECS Migration Status Report(${formattedDateTime}).xlsx`;

  XLSX.writeFile(workbook, filename);
};

export const error_message_validate_toast = (error_obj) => {
  if (error_obj && error_obj?.message.includes("prisma")) {
    showToast({ type: "error", message: "Something went wrong" });
  } else {
    showToast({ type: "error", message: message });
  }
};

export const is_token_expired = () => {
  let token = localStorage.getItem("user")?.token;
  console.log("user", token);
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decodedToken.exp < currentTime; // Token expired if exp is less than current time
  } catch (error) {
    return true; // If token decoding fails, consider it expired
  }
};
