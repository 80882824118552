import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, HStack, VStack, Text, Pressable, SimpleGrid } from "native-base";
import {
  useDynamicSelector,
  dynamicRequest,
  query_get_areas,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { countFormat } from "@helpers/utils";
// import Search from "./Search_box";
// import { VStack, HStack, Spacer } from 'react-native-stacks';
import { useTranslation } from "react-i18next";
import { dashboard_query } from "@services/redux/slices/dashboard/graphql";
import user_icon from "@assets/images/group.png";
import no_of_society from "@assets/icons/number_of_societies.png";
import sundry_icon from "@assets/images/sundry-creditors-new.png";
import sundry_debtors_icon from "@assets/images/sundry-debtors-new.png";
import share_capital_icon from "@assets/images/member-share-capital-new.png";
import thirty_days_icon from "@assets/images/number-30.png";
import sixty_days_icon from "@assets/images/60.png";
import ninety_days_icon from "@assets/images/ninety.png";
import three_month from "@assets/images/3m-new.png";
import six_month from "@assets/images/number-6.png";
import nine_month from "@assets/images/1y-new.png";
import fifteen_month from "@assets/images/5y-new.png";
import cash_balance from "@assets/images/cash_balance_new.png";
import three_year from "@assets/images/3y-new.png";
import deposit_icon from "@assets/images/fds-new.png";
import { useParams } from "react-router";
import OverdueCard from "./overdue_card";
import DepositCard from "./dashboard_deposits/deposit_card";
import LoanCard from "./loan_card";
import DashboardCard from "./admin_dashboard_card";
import JewelLoanCard from "./dashboard_jewel_loan/Jewel_loan_card";
import StaffLoanCard from "./dashboard_staff_loan/staff_loan_card";
import FDLoanCard from "./dashboard_fd_loan/fd_loan_card";
import RDLoanCard from "./dashboard_rd_loan/rd_loan_card";
import DashboardLoadingCard from "./dashboard_loading_card";
import savings_account_icon from "@assets/images/savings-account.png";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const society_ids = useParams();
  let society_id = localStorage.getItem("society_id");
  let society_name = localStorage.getItem("society");
  const [area_list, setAreasList] = useState([]);
  const [page_limit, setPageLimit] = useState(10);
  const [page_number, setPageNumber] = useState(1);
  const [sub_area, subAreaList] = useState(null);
  const { items: get_all_areas } = useDynamicSelector("get_areas");
  const { society_id: societyId, active_key } = props;
  let user = localStorage.getItem("user");
  const data = JSON.parse(user);
  const get_role = data?.roles?.[0]?.name;
  const level_id = data?.areas?.[0]?.level_id;
  const areasId = data?.areas?.[0]?.id;
  const {
    society,
    deposit,
    jl,
    rd,
    fd,
    loan,
    overdue,
    staff_loan,
    loading: dashboard_loading,
  } = useDynamicSelector("getSocietyDashboard");

  const { items: get_dashboard_societies } = useDynamicSelector(
    "getDashboardSocieties"
  );

  const getDashboard = () => {
    let key = [{ key: "getSocietyDashboard", loading: true }];
    let query = dashboard_query;
    let variables = {
      // society_id: society_id || societyId,
      area_id: areasId,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (areasId) getDashboard();
  }, [areasId, active_key]);

  const handleRedirectMembers = () => {
    history.push(ROUTES.MEMBER_LIST);
  };

  const handleRedirectRCSMemberList = (value) => {
    const queryParams = new URLSearchParams(value);
    queryParams.set("6xJ27BtlM0c&ab", value);
    if (value)
      history.push({ pathname: `${ROUTES.RCS_MEMBER_LIST}/${queryParams}` });
    else history.push(ROUTES.RCS_MEMBER_LIST);
  };
  const handleRedirectLoans = (id, status_url) => {
    history.push({
      pathname: `${ROUTES.LOANS_LIST}/${id}/${status_url}`,
    });
  };

  const handleRedirectSocietyDetails = (value) => {
    const queryParams = new URLSearchParams(value);
    queryParams.set("6xJ27BtlM0c&ab", value);
    history.push(`${ROUTES.SOCIETY_LISTS}/${queryParams}`);
  };
  const handleDeposits = (value) => {
    history.push(`${ROUTES.DEPOSIT_LIST}/${value.id}`);
  };

  const handleRedirectSuspenseAccount = () => {
    history.push(`${ROUTES.SUSPENSE_ACCOUNT}`);
  };

  const handleRedirectSundryCreditors = () => {
    history.push(`${ROUTES.SUNDRY_CREDITORS}`);
  };

  const handleRedirectSundryDebtors = () => {
    history.push(`${ROUTES.SUNDRY_DEBTORS}`);
  };

  const handleRedirectTransactions = (value) => {
    history.push({ pathname: `${ROUTES.TRANSACTION}/${value}` });
  };

  const handleRedirectStaffLoans = (id, status) => {
    history.push({ pathname: `${ROUTES.STAFF_LOAN_LIST}/${id}/${status}` });
  };

  const handleRedirectFDLoans = (id, status) => {
    history.push({ pathname: `${ROUTES.FIXED_DEPOSIT_LIST}/${id}/${status}` });
  };

  const handleRedirectRDLoans = (id, status) => {
    history.push({
      pathname: `${ROUTES.RECURRING_DEPOSIT_LIST}/${id}/${status}`,
    });
  };
  const handleRedirectJewelLoans = (id, status) => {
    history.push({ pathname: `${ROUTES.GOLD_LOAN_LIST}/${id}/${status}` });
  };

  const onLoanMemberSearch = (values) => {
    if (values && values.length > 3) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(get_members({ search_string: values, is_member: true }));
      }, 500);
    }
  };
  const getAllAreas = () => {
    let keys = [{ key: "get_areas", loading: true }];
    let variables = { id: areasId };
    dispatch(dynamicRequest(keys, query_get_areas, variables));
  };
  useEffect(() => {
    let temp = get_all_areas?.map((s) => {
      return {
        value: s?.id,
        label: s?.name,
      };
    });
    subAreaList(null);
    setAreasList(temp);

    const list = get_all_areas?.map((list) => {
      return {
        value: list?.id,
        label: list?.name,
      };
    });

    subAreaList(list);
  }, [get_all_areas]);
  useEffect(() => {
    getAllAreas();
  }, []);

  const handleChangeDistrict = (e) => {
    let key = [{ key: "getSocietyDashboard", loading: true }];
    let query = dashboard_query;
    let variables = {
      // id: societyId,
      area_id: e,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const thirtyDaysOverdue = overdue?.find(
    (item) => item.overdue_group === "30_days_overdue"
  );
  const sixtyDaysOverdue = overdue?.find(
    (item) => item.overdue_group === "60_days_overdue"
  );
  const ninetyDaysOverdue = overdue?.find(
    (item) => item.overdue_group === "90_days_overdue"
  );

  const threeToSixMonthsOverdue = overdue?.find(
    (item) => item.overdue_group === "3_6_months_overdue"
  );
  const sixToTwelveMonthsOverdue = overdue?.find(
    (item) => item.overdue_group === "6_12_months_overdue"
  );
  const oneToThreeYears = overdue?.find(
    (item) => item.overdue_group === "1_3_years_overdue"
  );

  const threeToFiveYears = overdue?.find(
    (item) => item.overdue_group === "3_5_years_overdue"
  );

  const fiveAndAboveYears = overdue?.find(
    (item) => item.overdue_group === "5_and_above_years_overdue"
  );

  const handleRedirectSociety = (val, value, id) => {
    const queryParams = new URLSearchParams();
    queryParams.set("6xJ27BtlM0c&ab", `${val}&${value}`);
    if (val?.includes("surety_loan")) {
      history.push({ pathname: `${ROUTES.SURETY_LOAN_LIST}/${queryParams}` });
    } else if (val?.includes("jewel_loan")) {
      history.push({ pathname: `${ROUTES.JEWEL_LOAN_LIST}/${queryParams}` });
    } else if (val?.includes("staff_loan")) {
      history.push({
        pathname: `${ROUTES.SOCIETY_STAFF_LOAN_LIST}/${queryParams}`,
      });
    } else if (val?.includes("fd_loan")) {
      history.push({
        pathname: `${ROUTES.SOCIETY_FD_LOAN_LIST}/${queryParams}`,
      });
    } else if (val?.includes("rd_loan")) {
      history.push({
        pathname: `${ROUTES.SOCIETY_RD_LOAN_LIST}/${queryParams}`,
      });
    } else if (val?.includes("deposit")) {
      history.push({
        pathname: `${ROUTES.SOCIETY_DEPOSIT_LIST}/${queryParams}/${id}`,
      });
    } else history.push(ROUTES.SOCIETY_LIST);
  };

  return (
    <Box width="100%" alignItems="center">
      {/* {societyId ? (
        ""
      ) : (
        <>
          <HStack space={10} mt={5}>
            {get_role === "societychecker" ? (
              ""
            ) : (
              <Box width={"250px"}>
                <Select
                  allowClear
                  showSearch
                  options={sub_area}
                  onChange={handleChangeDistrict}
                  placeholder={"Districts"}
                />
              </Box>
            )}
          </HStack>
        </>
      )} */}
      {dashboard_loading ? (
        <DashboardLoadingCard />
      ) : (
        <>
          <HStack space={10} pb={2} mt="5">
            <DashboardCard
              handleRedirect={() => {
                handleRedirectSociety();
              }}
              icon={no_of_society}
              value={Number(society?.[0]?.total_society_count || 0)}
              name={"Number of Societies"}
              color={"#326DA7"}
            />

            <Box
              minWidth="22vw"
              maxWidth="28vw"
              shadow="5"
              bgImage="linear-gradient(315deg, rgb(59, 183, 143) 0%, rgb(11, 171, 100) 74%)"
              color="white"
              borderRadius="5px"
            >
              <Pressable
                onPress={
                  get_role === "User" || get_role === "officer"
                    ? () => handleRedirectSocietyDetails("total_members")
                    : () => {
                        handleRedirectMembers();
                      }
                }
              >
                <HStack alignItems={"center"} flex={1}>
                  <VStack p={5} flex={1}>
                    <HStack space={"10"} flex={1}>
                      <Box flex={1}>
                        <img src={user_icon} width={"40px"} />
                      </Box>

                      <Box alignItems={"end"}>
                        <Pressable
                          onPress={
                            get_role === "User" || get_role === "officer"
                              ? () =>
                                  handleRedirectSocietyDetails("active_members")
                              : () => {
                                  handleRedirectMembers();
                                }
                          }
                        >
                          <Text
                            fontSize="3xl"
                            color="white"
                            bold
                            textAlign={"right"}
                          >
                            {countFormat(
                              Math.round(
                                Number(society?.[0]?.active_members || 0)
                              )
                            )}
                          </Text>
                          <Text fontSize="md" color="white">
                            {"Active"}
                          </Text>
                        </Pressable>
                      </Box>
                    </HStack>
                    <HStack space={"10"} flex={1}>
                      <Box
                        style={{
                          position: "absolute",
                          bottom: "0px",
                        }}
                      >
                        <Text fontSize="lg" color="white">
                          Members
                        </Text>
                      </Box>

                      <Box flex={1} alignItems={"end"}>
                        <Pressable
                          onPress={
                            get_role === "User" || get_role === "officer"
                              ? () =>
                                  handleRedirectSocietyDetails(
                                    "inactive_members"
                                  )
                              : () => {
                                  handleRedirectMembers();
                                }
                          }
                        >
                          <HStack space={"3"}>
                            <Box flex={1}>
                              <Text fontSize="md" color="white">
                                {"Dormant"}
                              </Text>
                            </Box>
                            <Box width={"20"} alignItems={"end"}>
                              <Text fontSize="md" color="white">
                                {countFormat(
                                  Math.round(
                                    Number(society?.[0]?.inactive_members || 0)
                                  )
                                )}
                              </Text>
                            </Box>
                          </HStack>
                        </Pressable>
                        <Pressable
                          onPress={
                            get_role === "User" || get_role === "officer"
                              ? () =>
                                  handleRedirectSocietyDetails("closed_members")
                              : () => {
                                  handleRedirectMembers();
                                }
                          }
                        >
                          <HStack space={"3"}>
                            <Box flex={1}>
                              <Text fontSize="md" color="white">
                                {"Closed"}
                              </Text>
                            </Box>
                            <Box width={"20"} alignItems={"end"}>
                              <Text fontSize="md" color="white">
                                {countFormat(
                                  Math.round(
                                    Number(society?.[0]?.closed_members || 0)
                                  )
                                )}
                              </Text>
                            </Box>
                          </HStack>
                        </Pressable>
                      </Box>
                    </HStack>
                  </VStack>
                </HStack>
              </Pressable>
            </Box>
            {/* <DashboardCard
              handleRedirect={() => {
                history.push(ROUTES.SOCIETY_LIST);
              }}
              icon={suspense_icon}
              value={
                Number(society?.[0]?.suspense_cr_balance || 0) +
                (society?.[0]?.suspense_dr_balance || 0)
              }
              name={"Suspense"}
              color={"#d06b10"}
            /> */}
            <DashboardCard
              handleRedirect={() => {
                handleRedirectSocietyDetails("share_capital_balance");
              }}
              icon={share_capital_icon}
              value={Number(society?.[0]?.share_capital_balance || 0)}
              name={"Share Capital"}
              color={"#4CC6BA"}
            />
          </HStack>
          <HStack space={10} pb={2} mt="5">
            {/* <DashboardCard
              handleRedirect={() => {
                handleShareCapital();
              }}
              icon={share_capital_icon}
              value={Number(society?.[0]?.share_capital_balance || 0)}
              name={"Share Capital"}
              color={"#4ebbc7"}
            /> */}
            <DashboardCard
              handleRedirect={() => {
                handleRedirectSocietyDetails("sundry_creditors_balance");
              }}
              icon={sundry_icon}
              value={Number(society?.[0]?.sundry_creditors_balance || 0)}
              name={"Sundry Creditors"}
              color={"#da6c69"}
            />
            <DashboardCard
              handleRedirect={() => {
                handleRedirectSocietyDetails("sundry_debtors_balance");
              }}
              icon={sundry_debtors_icon}
              value={Number(society?.[0]?.sundry_debtors_balance || 0)}
              name={"Sundry Debtors"}
              color={"#61afdd"}
            />
            <DashboardCard
              // handleRedirect={handleRedirectSavingsAccount}
              icon={cash_balance}
              value={society?.[0]?.cash_balance}
              name={get_role === "officer" ? "Cash Account" : "Savings Account"}
              color={"#cc79a7"}
            />
          </HStack>
          {loan?.length !== 0 && loan?.length && (
            <Text fontSize={"25px"} bold mt={"50px"} color={"#77716c"}>
              Loans
            </Text>
          )}
          <LoanCard loan={loan} handleRedirectLoans={handleRedirectSociety} />

          {jl?.length !== 0 && jl?.length && (
            <JewelLoanCard
              handleRedirect={handleRedirectSociety}
              loan_product={jl}
              get_role={get_role}
              loan_name={"Jewel Value"}
            />
          )}

          {staff_loan?.length !== 0 && staff_loan?.length && (
            <StaffLoanCard
              handleRedirect={handleRedirectSociety}
              loan_product={staff_loan}
              get_role={get_role}
              loan_name={"Staff Loans"}
            />
          )}

          <>
            <Text fontSize={"2xl"} bold mt={"50px"}>
              Overdue
            </Text>
            <SimpleGrid
              columns={{
                base: 1,
                xs: 1,
                md: 3,
                sm: 2,
                lg: 3,
                xl: 3,
                "2xl": 5,
              }}
              spacingY={"27px"}
              spacingX={"38px"}
              // alignItems={"center"}
              mt={"20px"}
              mb={"20px"}
            >
              <OverdueCard
                overdue_name={"30 Days Overdue"}
                color={"rgb(255, 182, 193)"}
                overdue_icon={thirty_days_icon}
                overdue_amount={thirtyDaysOverdue}
                get_role={get_role}
              />
              <OverdueCard
                overdue_name={"60 Days Overdue"}
                color={"rgb(250, 128, 114)"}
                overdue_icon={sixty_days_icon}
                overdue_amount={sixtyDaysOverdue}
                get_role={get_role}
              />

              <OverdueCard
                overdue_name={"90 Days Overdue"}
                color={"#rgb(240, 128, 128)"}
                overdue_icon={ninety_days_icon}
                overdue_amount={ninetyDaysOverdue}
                get_role={get_role}
              />
              <OverdueCard
                overdue_name={"3-6 Months overdue"}
                color={"rgb(205, 92, 92)"}
                overdue_icon={three_month}
                overdue_amount={threeToSixMonthsOverdue}
                get_role={get_role}
              />
              <OverdueCard
                overdue_name={"6-12 Months overdue"}
                color={"rgb(220, 20, 60)"}
                overdue_icon={six_month}
                overdue_amount={sixToTwelveMonthsOverdue}
                get_role={get_role}
              />
              <OverdueCard
                overdue_name={"1-3 Years overdue"}
                color={"rgb(178, 34, 34)"}
                overdue_icon={nine_month}
                overdue_amount={oneToThreeYears}
                get_role={get_role}
              />
              <OverdueCard
                overdue_name={"3-5 Years overdue"}
                color={"rgb(139, 0, 0)"}
                overdue_icon={three_year}
                overdue_amount={threeToFiveYears}
                get_role={get_role}
              />
              <OverdueCard
                overdue_name={"5 Years and Above overdue"}
                color={"rgb(128, 0, 0)"}
                overdue_icon={fifteen_month}
                overdue_amount={fiveAndAboveYears}
                get_role={get_role}
              />
            </SimpleGrid>
          </>
          <>
            {deposit?.length !== 0 && deposit && (
              <Text fontSize={"2xl"} bold mt={"50px"}>
                Deposits
              </Text>
            )}
            <Box alignItems={"center"} mt="3">
              <DepositCard
                deposit={deposit}
                handleDeposits={handleRedirectSociety}
                deposit_icon={deposit_icon}
              />
            </Box>
          </>
          {fd?.length !== 0 && fd?.length && (
            <Text fontSize={"2xl"} bold mt={"50px"}>
              Fixed Deposits
            </Text>
          )}
          {fd?.length !== 0 && fd?.length && (
            <FDLoanCard
              handleRedirect={handleRedirectSociety}
              loan_product={fd}
              get_role={get_role}
              loan_name={"Fixed Deposits"}
            />
          )}
          {rd?.length !== 0 && rd?.length && (
            <Text fontSize={"2xl"} bold mt={"50px"}>
              Recurring Deposits
            </Text>
          )}
          {rd?.length !== 0 && rd?.length && (
            <RDLoanCard
              handleRedirect={handleRedirectSociety}
              loan_product={rd}
              get_role={get_role}
              loan_name={"Recurring Deposit"}
            />
          )}
        </>
      )}
    </Box>
  );
};
export default Dashboard;
