import {
  demand_generation_collection,
  dynamicRequest,
  getCashGlAccountsListSelector,
  get_cash_gl_accounts,
  query_accounting_date,
  useDynamicSelector,
} from "@services/redux";
import { Button, Radio, Select } from "antd";
import { Box, HStack, Text, VStack } from "native-base";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Form from "@views/components/ui/antd_form";
import { amountFormatWithSymbol } from "@helpers/utils";
import moment from "moment";
import { showToast } from "helpers/toast";

const RecordCollection = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    data,
    group_id,
    close,
    demand_id,
    demand,
    selected_amount,
    old_balance,
  } = props;
  const [initialValues, setInitialValues] = useState({ group_id: group_id });
  const [gl_account_id, setGlAccountId] = useState(null);
  const [member_data, setMemberData] = useState([]);
  const [total_demand_amount, setTotalDemandAmount] = useState(0);
  const [total_collection_amount, setTotalCollectionAmount] = useState(0);
  const { items: gl_accounts } = useSelector(getCashGlAccountsListSelector);
  const { loading: createLoading } = useDynamicSelector(
    "create_demand_collection"
  );
  const { department } = useDynamicSelector("getSubDepartment");
  const getSocietyAccountingDay = useDynamicSelector("getSocietyAccountingDay");
  const payofficer_dueby_dept_items = useDynamicSelector(
    "getPayofficerDuebysDepartment"
  );
  const payofficer_dueby_sub_dept_items = useDynamicSelector(
    "getPayofficerDuebys"
  );

  const getAccountingDate = () => {
    let key = [{ key: "getSocietyAccountingDay", loading: true }];
    let query = query_accounting_date;
    dispatch(dynamicRequest(key, query));
  };

  const dateRangeBetweenAccountingDate = (current) => {
    const endDate = moment("2023-03-31T18:30:00.000Z");
    const startDate = moment(endDate).startOf("month");
    return current > endDate || current < startDate;
  };

  useEffect(() => {
    dispatch(get_cash_gl_accounts());
    getAccountingDate();
  }, []);
  useEffect(() => {
    let total_collection_amount = 0;
    total_collection_amount = data?.reduce((sum, item) => {
      let recoveryAmount = 0;
      if (!item.collection_amount) {
        recoveryAmount = item.recovery_amount
          ? Number(item.recovery_amount)
          : 0;
      }
      return sum + Number(recoveryAmount);
    }, 0);
    setTotalCollectionAmount(total_collection_amount);
  }, [data]);

  const handleSubmit = (values) => {
    if (
      selected_amount?.amount >= total_collection_amount ||
      old_balance >= total_collection_amount
    ) {
      console.log("department", department);
      let value = {
        demand_generation_id: demand_id,
        total_amount_in_demand: demand?.total_demand,
        remarks: values.remarks,
        total_amount_in_collection: total_collection_amount,
        member_collection_process_line_items: data
          ?.filter((x) => !x.collection_amount)
          .map((x) => ({
            member_id: x.id,
            sub_department_id:
              selected_amount?.source === "department"
                ? department?.id
                : x.group_id,
            generated_demand_amount: x.generated_demand_amount,
            credit_entry_ids: values.credit_entry_ids,
            gl_account_id: values.gl_account_id,
            collection_amount: x.recovery_amount,
            sundry_creditors_gl_id: values.sundry_creditors_gl_id,
          })),
      };

      let variables = {
        data: value,
      };
      let keys = [{ key: "create_demand_collection", loading: true }];
      dispatch(
        dynamicRequest(keys, demand_generation_collection, variables, "M")
      );
    } else {
      showToast({
        type: "error",
        message: t("error:collection_amount_error_message"),
      });
    }
  };

  const sum = gl_accounts?.reduce(
    (total, item) =>
      gl_account_id?.includes(item.id) ? total + item.current_balance : total,
    0
  );
  return (
    <Box flex={1}>
      <VStack space={"4"}>
        {/* <HStack space="5" justifyContent={"center"}>
          <Radio.Group
            style={{
              borderRadius: "5px",
            }}
            size="large"
            buttonStyle={{
              borderRadius: "5px",
            }}
          >
            <Radio.Button
              value="large"
              style={{
                background: transaction_type === "now" ? "#683fe9" : "",
                borderColor: transaction_type === "now" ? "#683fe9" : "",
                color: transaction_type === "now" ? "white" : "#683fe9",
                borderBottomLeftRadius: "8px",
                borderTopLeftRadius: "8px",
              }}
              onClick={() => {
                // setIsSelected(true);
                setTransactionType("now");
              }}
            >
              Received Now
            </Radio.Button>
            <Radio.Button
              value="default"
              onClick={() => {
                // setIsDemand(false);
                setTransactionType("due by");
              }}
              style={{
                background: transaction_type === "due by" ? "#683fe9" : "",
                borderColor: transaction_type === "due by" ? "#683fe9" : "",
                color: transaction_type === "due by" ? "white" : "#683fe9",
                borderBottomRightRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              From Due By
            </Radio.Button>
          </Radio.Group>
        </HStack> */}
        <Box>
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            // onValueChange={onValueChange}
            id={"for"}
          >
            <VStack>
              <Box flex={1}>
                <Text bold>
                  {t("total_amount")} :{" "}
                  {amountFormatWithSymbol(Number(total_collection_amount))}
                </Text>
              </Box>
              {/* {transaction_type === "now" && (
                <Box flex={1} px={2}>
                  <Form.Select
                    label={t("bank_account")}
                    field={`gl_account_id`}
                    options={gl_accounts}
                    labelField={"name"}
                    valueField={"id"}
                  />
                </Box>
              )} */}
              {/* {transaction_type !== "now" && (
                <Box flex={1} px={2}>
                  <Form.Select
                    label={t("credit_entry_ids")}
                    field={`credit_entry_ids`}
                    options={gl_accounts}
                    labelField={"name"}
                    valueField={"id"}
                  />
                </Box>
              )} */}

              {/* <Box flex={1} px={2}>
                <Form.Select
                  label={t("sundry_creditors_gl_account_id")}
                  field={`sundry_creditors_gl_id`}
                  options={gl_accounts}
                  labelField={"name"}
                  valueField={"id"}
                />
              </Box> */}
              {/* {transaction_type === "now" && (
                <Box flex={1} px={2}>
                  <Form.TextBox
                    field={"transaction_number"}
                    label={t("transaction_reference")}
                  />
                </Box>
              )} */}
              {/* <Box flex={1} px={2}>
                <Form.Date
                  field={"transaction_number"}
                  label={t("transaction_reference")}
                  disabledDate={dateRangeBetweenAccountingDate}
                />
              </Box> */}
              <Box flex={1} px={2}>
                <Form.TextArea field={"remarks"} label={t("remarks")} />
              </Box>
              <Box alignItems={"center"}>
                <Form.Button
                  isLoading={createLoading}
                  width="100px"
                  colorScheme="primary"
                  variant="solid"
                  FormErrorMessage={t("error:error_message")}
                >
                  {t("save")}
                </Form.Button>
              </Box>
            </VStack>
          </Form>
        </Box>
      </VStack>
    </Box>
  );
};

export default RecordCollection;
