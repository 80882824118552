import AppRouter from "@views/routes/app_router";
import { is_token_expired } from "helpers/functions";
import { useEffect, useLayoutEffect } from "react";

function App() {
  useLayoutEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath !== "/login" && is_token_expired()) {
      window.location.href = "/login";
    }
  }, []);

  if (window.top !== window.self) {
    window.top.location = window.self.location;
  }

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );

        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }

        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }

      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message === "Script error." ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );

        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );

        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }

        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return <AppRouter />;
}
export default App;
