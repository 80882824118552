import React, { memo, useMemo, useRef } from "react";
import { Button, Modal, Pagination, Radio, Spin, Table, Tag } from "antd";
import { useState, useEffect } from "react";
import { Box, HStack, Text, VStack, useBreakpointValue } from "native-base";
import { amountFormat, formatDate } from "@helpers/utils";
import { useTranslation } from "react-i18next";
import { createDemandSelector, create_demand } from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import DemandCollectionList from "../collection/demand_collection_list";
import { getDemandSelector, get_one_demand } from "@services/redux";
import { useParams } from "react-router-dom";
import PDFGenerator from "./download_demand/demand_for_sub_dpt_pdf_download";
import SearchBox from "@views/components/ui/search_box/search_box";
import {
  getSuretyLoanProductListSelector,
  get_surety_loan_products,
} from "./../../../services/redux/slices/loan_product/surety_loan_product_list";
import {
  getSocietyDepositProductListSelector,
  get_society_deposit_product_list,
} from "./../../../services/redux/slices/society_deposit_product/society_deposit_product_list";
import {
  dynamicClear,
  dynamicRequest,
  get_all_rd_products,
  get_demand_clear,
  get_pay_officer_dueby_query,
  query_get_payofficer_dueby,
  useDynamicSelector,
} from "../../../services/redux";
import DemandForSubDeptPrint from "./download_demand/demand_for_sub_dept_print";
import DCBReportDownload from "./download_demand/dcb_report";
import * as XLSX from "xlsx";
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";
import DynamicSortableColumns from "./dynamic_sortable_columns";
import { useDownloadExcel } from "react-export-table-to-excel";
import { usePageComponentAccess } from "../../../helpers/auth";
import { indian_currency_format } from "helpers/functions";

const { Column, ColumnGroup } = Table;

const GeneratedDemandForSubDepartment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, sub_department_id } = useParams();
  const society_id = localStorage.getItem("society_id");
  const moment = require("moment");
  const printRef = useRef(null);
  const dcbReportRef = useRef(null);
  const [is_demand, setIsDemand] = useState("demand");
  const [data, setData] = useState([]);
  const [demand_month, setDemandMonth] = useState(null);
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [columns_model, set_columns_model] = useState(false);
  const [search, setSearch] = useState("");
  const [matched_sub_department, set_matched_sub_department] = useState({});
  const [new_amount, set_new_amount] = useState(null);
  const [selected_amount, set_selected_amount] = useState(null);

  const collection_amount_modal_ref = useRef();

  const { item: demand, loading: demand_loading } =
    useSelector(getDemandSelector);
  const item = useDynamicSelector("demand");
  let { items: loan_products } = useSelector(getSuretyLoanProductListSelector);
  let { items: deposit_products } = useSelector(
    getSocietyDepositProductListSelector
  );

  const { status: createDemandStatus, error: createDemandError } =
    useSelector(createDemandSelector);
  const { items: recurring_deposit_products } = useDynamicSelector(
    "getRecurringDepositProducts"
  );
  const {
    items: payofficer_dueby_items,
    loading: payofficer_dueby_items_loading,
  } = useDynamicSelector("getPayofficerDuebys");

  const selected_collection_amount = useDynamicSelector(
    "selectedCollectionAmount"
  );

  const payofficer_dueby_dept_items = useDynamicSelector(
    "getPayofficerDuebysDepartment"
  );
  const payofficer_dueby_sub_dept_items = useDynamicSelector(
    "getPayofficerDuebys"
  );

  const handlePrint = () => {
    const contentToPrint = document.getElementById("print-table");
    if (contentToPrint) {
      const printFrame = document.createElement("iframe");
      printFrame.style.position = "absolute";
      printFrame.style.width = "0";
      printFrame.style.height = "0";
      printFrame.style.border = "none";

      document.body.appendChild(printFrame);

      const printDocument =
        printFrame.contentDocument || printFrame.contentWindow.document;

      printDocument.write("<html><head><title>Print</title></head><body>");
      printDocument.write(
        "<style>@page { size: landscape;} body { font-family: Arial, sans-serif;padding:-20px; } table { border-collapse: collapse; width: 100%; } th, td { border: 1px solid #dddddd; padding: 20px; }</style>"
      );
      printDocument.write(contentToPrint.innerHTML);
      printDocument.write("</body></html>");
      printDocument.close();

      printFrame.contentWindow.print();

      // Remove the iframe after printing
      document.body.removeChild(printFrame);
    } else {
    }
  };

  const handleDCBReportPrint = () => {
    const element = dcbReportRef.current;

    const opt = {
      margin: 4.5, // Adjust margin as needed
      filename: `DCB Report.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm", // Change unit to millimeters
        format: "a2", // Use A4 format for better coverage
        orientation: "landscape", //
        fontSize: 8, // Set the font size
      },
    };

    html2pdf().set(opt).from(element).save();
  };

  // const handleDCBReport = (type, fn, dl) => {
  //   var elt = document.getElementById("dcb-report");
  //   var wb = XLSX.utils.table_to_book(elt, { sheet: "skills" });
  //   return dl
  //     ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
  //     : XLSX.writeFile(wb, fn || "DCB Report." + (type || "xlsx"));
  // };

  const handleDCBReport = (type, fn, dl, excelData) => {
    var elt = document.getElementById("dcb-report");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "Demand" });

    // Get the first sheet in the workbook
    var ws = wb.Sheets[wb.SheetNames[0]];

    // Loop through each cell in the sheet and apply text-align style
    for (var cell in ws) {
      if (ws.hasOwnProperty(cell)) {
        // Check if it's a cell with content (not a header, etc.)
        if (cell[0] === "!" || cell[0] === "_") continue;

        // Apply text-align style to each cell
        ws[cell].s = {
          alignment: { horizontal: "center" /* or "left" or "right" */ },
        };

        // Convert x?.unique_id to string if it exists in the sheet
        excelData.forEach((report) => {
          report.answers.forEach((x) => {
            if (ws[cell].v === x.unique_id) {
              ws[cell].t = "s"; // Indicate that the cell contains a string
              ws[cell].v = "'" + x.unique_id.toFixed(2).toString(); // Convert to string and prepend with apostrophe
            }
          });
        });
      }
    }

    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(wb, fn || `Report.` + (type || "xlsx"));
  };
  const { onDownload } = useDownloadExcel({
    currentTableRef: dcbReportRef.current,
    filename: `${demand?.sub_department?.name}-${demand?.sub_department?.code} - DCB Report`,
    sheet: "Demand",
    // excelData: values,
    handleDownload: handleDCBReport,
  });

  const getRdLoanProductList = () => {
    let key = [{ key: "getRecurringDepositProducts", loading: true }];
    let query = get_all_rd_products;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_payofficer_dueby_query = () => {
    let key = [{ key: "getPayofficerDuebys", loading: true }];
    let query = get_pay_officer_dueby_query;
    let variables = {
      sub_department_id: sub_department_id,
      type: "payofficer",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetchData = (value, value1) => {
    dispatch(
      get_one_demand({
        json: {
          id: id,
          page_number: value,
          page_limit: value1,
          search_string: search,
        },
      })
    );

    setPageNumber(value);
    setPageLimit(value1);
  };

  const handleSearch = (search_txt) => {
    setSearch(search_txt);
    dispatch(
      get_one_demand({
        json: {
          id: id,
          page_number: 1,
          page_limit: 10,
          search_string: search_txt,
        },
      })
    );
  };

  useEffect(() => {
    get_payofficer_dueby_query();
  }, [sub_department_id]);

  // useEffect(() => {
  //   let matched_sub_department = payofficer_dueby_items?.find((x) => {
  //     x.sub_department?.id === sub_department_id;
  //   });
  //   set_matched_sub_department(matched_sub_department);
  // }, [payofficer_dueby_items]);

  useEffect(() => {
    if (is_demand === "demand")
      dispatch(
        get_one_demand({
          json: {
            id: id,
            // page_number: page_number,
            // page_limit: page_limit,
            search_string: search,
          },
        })
      );
  }, [id, is_demand]);

  useEffect(() => {
    getRdLoanProductList();
    dispatch(get_society_deposit_product_list({ society_id: society_id }));
    dispatch(get_surety_loan_products({ society_id: society_id }));
  }, [id]);
  const calculateTotalDemandValue = (record) => {
    let deposits = record?.deposits;
    let loans = record?.surety_loans;
    let member_due = record?.member_duetos;

    let total = deposits?.reduce((sum, deposit) => {
      const amount = Number(deposit?.amount || 0);
      const arrearAmount =
        Number(deposit?.arrear_amount || 0) +
        Number(deposit?.current_month_balance || 0);

      return sum + amount + arrearAmount;
    }, 0);
    record?.recurring_deposit?.forEach((rd) => {
      total += Number(rd.arrear_amount || 0) + Number(rd.amount || 0);
    });
    loans?.forEach((loan) => {
      if (loan?.surety_loan_demands) {
        loan.surety_loan_demands?.forEach((demand) => {
          total +=
            Number(total_principal(loan) || 0) +
            Number(demand.interest || 0) +
            Number(demand.penal_interest || 0) +
            Number(demand.arrear_monthly_principal || 0) +
            Number(demand.arrear_interest || 0) +
            Number(demand.arrear_penal_interest || 0) +
            Number(demand.decree_interest || 0);
        });
      }
    });

    member_due?.forEach((dueto) => {
      total += dueto?.balance_amount || 0;
    }, 0);

    return total;
  };
  useEffect(() => {
    if (demand?.id) {
      let demand_values = JSON.parse(demand?.demand_output);
      let demand_data = demand_values?.map((list) => {
        return {
          ...list,
          demand_total_value: calculateTotalDemandValue(list),
        };
      });
      setData(demand_data);
    }
    setDemandMonth(demand?.demand_date);
  }, [demand]);

  useEffect(() => {
    if (item?.id) {
      setDemandMonth(item?.demand_date);
    }
  }, [item]);

  const get_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0;
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
      loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) -
          Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal;
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal ? amountFormat(principal) : "-";
    } else {
      return monthly_principal ? amountFormat(monthly_principal) : "-";
    }
  };
  const total_principal = (loan) => {
    let principal = 0;
    let degree_interest = 0;
    let monthly_principal = 0;
    principal =
      loan?.surety_loan_demands?.[0]?.monthly_principal >
      loan?.surety_loan_demands?.[0]?.current_principal_balance
        ? Number(loan?.surety_loan_demands?.[0]?.current_principal_balance) -
          Number(loan?.surety_loan_demands?.[0]?.arrear_monthly_principal)
        : loan?.surety_loan_demands?.[0]?.monthly_principal;
    degree_interest = loan?.surety_loan_demands?.[0]?.decree_interest;
    monthly_principal = loan?.surety_loan_demands?.[0]?.monthly_principal;
    if (degree_interest === 0) {
      return principal;
    } else {
      return monthly_principal;
    }
  };
  function generateColumns(deposit_products) {
    const columns = [
      <Column
        title={t("table:member_number")}
        dataIndex="member_number"
        key="member_number"
        width={100}
        fixed="left"
      />,
      <Column
        title={t("table:name")}
        dataIndex="name"
        key="name"
        width={400}
        fixed="left"
      />,
      <Column
        title={t("table:total_demand")}
        dataIndex="demand_total_value"
        key="demand_total_value"
        width={400}
        fixed="left"
        align="right"
        render={(amount) => {
          return amountFormat(amount);
        }}
      />,
    ];
    loan_products?.map((product) => {
      const loan_products_columns = (
        <ColumnGroup key={product?.id} title={product ? product?.name : "SL"}>
          <Column
            title={t("table:arrear_principal")}
            dataIndex="surety_loans"
            key={`${product?.id}-arrear_principal`}
            align="right"
            render={(suretyLoans) => {
              let principal = 0;
              suretyLoans.forEach((loan) => {
                if (loan.surety_loan_product?.id === product?.id) {
                  principal +=
                    loan?.surety_loan_demands?.[0]?.arrear_monthly_principal;
                }
              });
              return principal ? amountFormat(principal) : "-";
            }}
          />{" "}
          <Column
            title={t("table:principal")}
            dataIndex="surety_loans"
            key={`${product?.id}-principal`}
            align="right"
            render={(suretyLoans) => {
              let principal = 0;
              // let degree_interest = 0;
              // let monthly_principal = 0;

              // suretyLoans.forEach((loan) => {
              //   if (loan?.surety_loan_product?.id === product?.id) {
              //     degree_interest +=
              //       loan.surety_loan_demands?.[0]?.decree_interest;
              //     monthly_principal +=
              //       loan?.surety_loan_demands?.[0]?.monthly_principal;
              //   }
              // });
              suretyLoans.forEach((loan) => {
                if (loan?.surety_loan_product?.id === product?.id) {
                  principal = get_principal(loan);
                }
              });
              // if (degree_interest === 0) {
              //   return principal ? amountFormat(principal) : "-";
              // } else {
              //   return monthly_principal
              //     ? amountFormat(monthly_principal)
              //     : "-";
              // }
              return principal !== 0 ? principal : "-";
            }}
          />
          <Column
            title={t("table:arrear_interest")}
            dataIndex="surety_loans"
            key={`${product?.id}-arrear_interest`}
            align="right"
            render={(suretyLoans) => {
              let interest = 0;
              suretyLoans.forEach((loan) => {
                if (loan?.surety_loan_product?.id === product?.id) {
                  interest += loan.surety_loan_demands?.[0]?.arrear_interest;
                }
              });
              return interest ? amountFormat(interest) : "-";
            }}
          />{" "}
          <Column
            title={t("table:interest")}
            dataIndex="surety_loans"
            key={`${product?.id}-interest`}
            align="right"
            render={(suretyLoans) => {
              let interest = 0;
              let principal = 0;
              suretyLoans.forEach((loan) => {
                if (loan?.surety_loan_product?.id === product?.id) {
                  principal +=
                    loan?.surety_loan_demands?.[0]?.monthly_principal >
                    loan?.surety_loan_demands?.[0]?.current_principal_balance
                      ? loan?.surety_loan_demands?.[0]
                          ?.current_principal_balance
                      : loan?.surety_loan_demands?.[0]?.monthly_principal;
                  if (
                    loan?.surety_loan_demands?.[0]?.fixed_demand_amount &&
                    loan?.surety_loan_demands?.[0]?.fixed_demand_amount !== 0
                  ) {
                    interest +=
                      Number(
                        loan?.surety_loan_demands?.[0]?.fixed_demand_amount
                      ) - Number(principal);
                  } else {
                    interest += Number(loan.surety_loan_demands?.[0]?.interest);
                  }
                }
              });

              return interest ? amountFormat(interest) : "-";
            }}
          />
          <Column
            title={t("table:decree_interest")}
            dataIndex="surety_loans"
            key={`${product?.id}-decree_interest`}
            align="right"
            render={(suretyLoans) => {
              let interest = 0;
              suretyLoans.forEach((loan) => {
                if (loan?.surety_loan_product?.id === product?.id) {
                  interest += loan.surety_loan_demands?.[0]?.decree_interest;
                }
              });
              return interest ? amountFormat(interest) : "-";
            }}
          />
          <Column
            title={t("table:arrear_penal_interest")}
            dataIndex="surety_loans"
            key={`${product?.id}-arrear-penal-interest`}
            align="right"
            render={(suretyLoans) => {
              let penalInterest = 0;
              suretyLoans.forEach((loan) => {
                if (loan?.surety_loan_product?.id === product?.id) {
                  penalInterest +=
                    loan.surety_loan_demands?.[0]?.arrear_penal_interest;
                }
              });
              return penalInterest ? amountFormat(penalInterest) : "-";
            }}
          />{" "}
          <Column
            title={t("table:penal_interest")}
            dataIndex="surety_loans"
            key={`${product?.id}-penal-interest`}
            align="right"
            render={(suretyLoans) => {
              let penalInterest = 0;
              suretyLoans.forEach((loan) => {
                if (loan?.surety_loan_product?.id === product?.id) {
                  penalInterest +=
                    loan.surety_loan_demands?.[0]?.penal_interest;
                }
              });
              return penalInterest ? amountFormat(penalInterest) : "-";
            }}
          />
        </ColumnGroup>
      );
      return columns.push(loan_products_columns);
    });
    // deposit_products?.forEach((deposit_product) => {
    //   const depositColumn = (
    //     <Column
    //       title={deposit_product?.name}
    //       dataIndex="deposits"
    //       key={deposit_product?.id}
    //       align="right"
    //       render={(record) => {
    //         const deposit = record?.find(
    //           (deposit) => deposit.deposit_product?.id === deposit_product?.id
    //         );
    //         const amount = deposit?.amount ?? 0;
    //         const arrearAmount = deposit?.arrear_amount ?? 0;
    //         const totalAmount = amount + arrearAmount;
    //         return totalAmount !== 0 ? amountFormat(totalAmount) : "-";
    //       }}
    //     />
    //   );
    //   columns.push(depositColumn);
    // })
    deposit_products?.map((product) => {
      const depositColumn = (
        <ColumnGroup key={product?.id} title={product ? product?.name : "SL"}>
          <Column
            title={t("table:amount")}
            dataIndex="deposits"
            key={`${product?.id}-amount`}
            align="right"
            render={(record) => {
              const deposit = record?.find(
                (deposit) => deposit.deposit_product?.id === product?.id
              );
              const amount = deposit?.amount ?? 0;
              // const arrearAmount = deposit?.arrear_amount ?? 0;
              // const totalAmount = amount + arrearAmount;
              return amount !== 0 ? amountFormat(amount) : "-";
            }}
          />
          <Column
            title={t("table:arrear_amount")}
            dataIndex="deposits"
            key={`${product?.id}-arrear_amount`}
            align="right"
            render={(record) => {
              const deposit = record?.find(
                (deposit) => deposit.deposit_product?.id === product?.id
              );
              // const amount = deposit?.arrear_amount ?? 0;
              const arrearAmount =
                Number(deposit?.arrear_amount) +
                Number(deposit?.current_month_balance);
              // const totalAmount = amount + arrearAmount;
              return arrearAmount !== 0 ? amountFormat(arrearAmount) : "-";
            }}
          />
        </ColumnGroup>
      );
      columns.push(depositColumn);
    });

    recurring_deposit_products?.forEach((deposit_product) => {
      const recurringDepositColumn = (
        <>
          <Column
            title={deposit_product?.code}
            dataIndex="recurring_deposit"
            key={deposit_product?.id}
            align="right"
            render={(record) => {
              const deposit = record
                ?.filter(
                  (rd) =>
                    rd.recurring_deposit_product?.id === deposit_product?.id
                )
                .map((deposit, index) => {
                  const amount = deposit?.amount ?? 0;
                  const arrearAmount = deposit?.arrear_amount ?? 0;
                  const totalAmount = amount + arrearAmount;
                  return totalAmount !== 0 ? (
                    <h6>{amountFormat(totalAmount)}</h6>
                  ) : (
                    "-"
                  );
                });
              return deposit;
            }}
          />
        </>
      );

      columns.push(recurringDepositColumn);
    });

    columns.push(
      <Column
        title={"Dueto"}
        dataIndex=""
        key={"dueto"}
        align="right"
        render={(record) => {
          let totalBalance = record?.member_duetos.reduce(
            (dueto_sum, dueto) => {
              return dueto_sum + (dueto?.balance_amount || 0);
            },
            0
          );
          return totalBalance !== 0 ? amountFormat(totalBalance) : "-";
        }}
      />
    );

    return columns;
  }

  const tableColumns = useMemo(
    () => generateColumns(deposit_products),
    [deposit_products, loan_products]
  );

  const size = useBreakpointValue({
    base: 600,
    xs: 72,
    sm: 72,
    md: 96,
  });

  const handle_sortable_columns = () => {
    set_columns_model(true);
  };
  const handle_close_column_modal = () => {
    set_columns_model(false);
  };
  // useEffect(() => {
  //   if (demand?.demand_output) {
  //     // const combinedJsonData = items.reduce((acc, currentItem) => {
  //     //   const jsonData = JSON.parse(currentItem.demand_output);
  //     //   acc = acc.concat(jsonData);
  //     //   return acc;
  //     // }, []);
  //     const combinedJsonData = JSON.parse(demand?.demand_output);
  //     let jsonData = combinedJsonData;
  //     const depositProductNames = new Set();
  //     jsonData.forEach((x) => {
  //       x.deposits.forEach((deposit) => {
  //         depositProductNames.add(deposit.deposit_product.name);
  //       });
  //     });

  //     const recurringDepositProductNames = new Set();
  //     jsonData.forEach((x) => {
  //       x.recurring_deposit.forEach((deposit) => {
  //         recurringDepositProductNames.add(
  //           deposit.recurring_deposit_product.name
  //         );
  //       });
  //     });
  //     const suretyLoanProductNames = new Set();
  //     jsonData.forEach((x) => {
  //       x.surety_loans.forEach((loan) => {
  //         loan.surety_loan_demands.forEach((demand) => {
  //           suretyLoanProductNames.add(loan?.surety_loan_product?.name || "SL");
  //         });
  //       });
  //     });

  //     const inputFormat = jsonData.map((x) => {
  //       const depositData = {};
  //       const suretyLoanData = {};

  //       let total_demand = 0;
  //       let total_surety_loans = 0;
  //       let total_deposit = 0;
  //       x.deposits.forEach((deposit) => {
  //         const amount = deposit?.amount ?? 0;
  //         const arrearAmount = deposit?.arrear_amount ?? 0;
  //         const productName = deposit.deposit_product.name;

  //         depositData[`${productName}`] = amount + arrearAmount;
  //         total_deposit = total_deposit += amount + arrearAmount;
  //       });

  //       x.surety_loans.forEach((loan) => {
  //         loan.surety_loan_demands.forEach((demand, index) => {
  //           const productName = loan?.surety_loan_product?.name || "SL";
  //           const interest = demand.interest;
  //           const penalInterest = demand.penal_interest;
  //           const monthlyPrincipal = demand.monthly_principal;
  //           const arrearInterest = demand.arrear_interest;
  //           const arrearPenalInterest = demand.arrear_penal_interest;
  //           const arrearMonthlyPrincipal = demand.arrear_monthly_principal;
  //           total_surety_loans +=
  //             interest +
  //             penalInterest +
  //             monthlyPrincipal +
  //             arrearInterest +
  //             arrearPenalInterest +
  //             arrearMonthlyPrincipal;
  //           suretyLoanData[`${productName} - Arrear Monthly Principal`] =
  //             arrearMonthlyPrincipal;
  //           suretyLoanData[`${productName} - Monthly Principal`] =
  //             monthlyPrincipal;
  //           suretyLoanData[`${productName} - Arrear Interest`] = arrearInterest;
  //           suretyLoanData[`${productName} - Interest`] = interest;
  //           suretyLoanData[`${productName} - Arrear Penal Interest`] =
  //             arrearPenalInterest;
  //           suretyLoanData[`${productName} - Penal Interest`] = penalInterest;
  //         });
  //       });

  //       let total_rd_amount = 0;
  //       const recurringDepositData = {};

  //       x.recurring_deposit.forEach((rd) => {
  //         const amount = rd?.amount ?? 0;
  //         const arrearAmount = rd?.arrear_amount ?? 0;
  //         const productName = rd.recurring_deposit_product.name;

  //         if (!recurringDepositData[productName]) {
  //           recurringDepositData[productName] = 0;
  //         }

  //         recurringDepositData[productName] += amount + arrearAmount;

  //         total_rd_amount += amount + arrearAmount;
  //       });

  //       total_demand = total_surety_loans + total_deposit + total_rd_amount;

  //       const parts = x.member_number.split("/");
  //       const part1 = parts[1].padStart(6, "0");
  //       return {
  //         "Member Number": part1,
  //         Name: x.name,
  //         "Total Demand": amountFormat(total_demand),
  //         ...depositData,
  //         ...suretyLoanData,
  //         ...recurringDepositData,
  //       };
  //     });

  //     const worksheet = XLSX.utils.json_to_sheet(inputFormat);
  //     const columnWidths = {};
  //     Object.keys(worksheet).forEach((key) => {
  //       const column = key.replace(/[0-9]/g, ""); // Extract column letters
  //       const width =
  //         (worksheet[key].v ? worksheet[key].v.toString().length : 10) + 2; // Calculate width based on cell value
  //       columnWidths[column] = Math.max(columnWidths[column] || 0, width); // Store maximum width for each column
  //     });

  //     // Set column widths
  //     worksheet["!cols"] = Object.keys(columnWidths).map((column) => ({
  //       wch: columnWidths[column],
  //     }));

  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  //     const outputFilePath = `Demand.xlsx`;

  //     XLSX.writeFile(workbook, outputFilePath, {
  //       bookType: "xlsx",
  //       type: "buffer",
  //     });
  //     // });
  //   }
  // }, []);

  const calculate_amount_total = () => {
    const totalDemand = item?.total_demand ?? demand?.total_demand;
    const balanceAmount =
      selected_amount?.amount ?? payofficer_dueby_items?.[0]?.balance_amount;

    if (isNaN(totalDemand) || isNaN(balanceAmount)) {
      return null;
    }
    if (totalDemand && balanceAmount && balanceAmount > totalDemand) {
      return (
        <Text bold style={{ color: "green" }}>
          Excess Amount{" "}
          {amountFormat(Number(balanceAmount) - Number(totalDemand))}
        </Text>
      );
    } else if (totalDemand - balanceAmount !== 0) {
      return (
        <Text bold style={{ color: "red" }}>
          Shortage Amount{" "}
          {amountFormat(Number(totalDemand) - Number(balanceAmount))}
        </Text>
      );
    }
    return null;
  };

  const render_amount_by_selection = () => {
    if (selected_amount?.amount) {
      return indian_currency_format(selected_amount?.amount);
    } else if (payofficer_dueby_items?.[0]?.balance_amount > 0) {
      return indian_currency_format(
        payofficer_dueby_items?.[0]?.balance_amount
      );
    }
  };

  return (
    <Box flex={1} overflow={"hidden"}>
      <VStack space="3">
        <Box
          _text={{
            fontSize: "20",
            fontWeight: "bolder",
          }}
        >
          {t("society_demand")}
        </Box>
        <Text fontSize="20" fontWeight="bolder">
          {demand?.sub_department?.department?.name ||
            item?.sub_department?.department?.name}{" "}
          /{" "}
          {demand?.sub_department?.code ||
            item?.sub_department?.department?.code}{" "}
          - {demand?.sub_department?.name || item?.sub_department?.name}
        </Text>
        <HStack space="5" justifyContent={"center"}>
          <Radio.Group
            style={{
              borderRadius: "5px",
            }}
            value={size}
            size="large"
            buttonStyle={{
              borderRadius: "5px",
            }}
          >
            <Radio.Button
              value="large"
              style={{
                background: is_demand === "demand" ? "#683fe9" : "",
                borderColor: is_demand === "demand" ? "#683fe9" : "",
                color: is_demand === "demand" ? "white" : "#683fe9",
                borderBottomLeftRadius: "8px",
                borderTopLeftRadius: "8px",
              }}
              onClick={() => {
                // setIsSelected(true);
                setIsDemand("demand");
                dispatch(dynamicClear("demand"));
              }}
            >
              {t("demand")}
            </Radio.Button>
            <Radio.Button
              value="default"
              onClick={() => {
                setIsDemand("collection");
                dispatch(get_demand_clear());
              }}
              style={{
                background: is_demand === "collection" ? "#683fe9" : "",
                borderColor: is_demand === "collection" ? "#683fe9" : "",
                color: is_demand === "collection" ? "white" : "#683fe9",
                borderBottomRightRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              {t("collection")}
            </Radio.Button>
          </Radio.Group>
        </HStack>

        {(is_demand === "collection" &&
          payofficer_dueby_items?.[0]?.balance_amount > 0) ||
        (is_demand === "collection" &&
          payofficer_dueby_dept_items?.items?.[0]?.balance_amount > 0) ||
        new_amount ? (
          <div className="no-pay-officer-dueby-amount">
            <Tag style={{ width: "440px", padding: "6px" }}>
              <div className="d-flex align-center justify-center">
                {console.log("selected_amount", selected_amount)}
                <b className={"font-16"}>
                  {`Available Pay Officer Amount `}
                  <span>
                    {render_amount_by_selection()}
                    {`${
                      selected_amount?.source === "department"
                        ? " (Department)"
                        : " (Sub department)"
                    }`}
                  </span>
                </b>
              </div>
            </Tag>

            {/* show button based on the two amounts */}
            {payofficer_dueby_sub_dept_items?.items?.[0]?.balance_amount > 0 &&
              payofficer_dueby_dept_items?.items?.[0]?.balance_amount > 0 && (
                <Button
                  onClick={() =>
                    collection_amount_modal_ref?.current.set_is_modal_visible(
                      true
                    )
                  }
                >
                  Change
                </Button>
              )}
          </div>
        ) : (
          is_demand === "collection" && (
            <div className="no-pay-officer-dueby-amount">
              <Tag color="red" style={{ width: "400px", padding: "6px" }}>
                <div className="d-flex align-center justify-center">
                  <b className={"font-16"}>
                    There is no dueby amount for the pay officer
                  </b>
                </div>
              </Tag>
            </div>
          )
        )}

        <>
          <Text bold>{`Demand for the month of ${moment
            .unix(demand_month / 1000)
            .format("MMMM")}`}</Text>
          {item?.total_demand ? (
            <Text bold>
              Total demand amount {amountFormat(item?.total_demand)}
            </Text>
          ) : (
            <Text bold>
              Total demand amount {amountFormat(demand?.total_demand)}
            </Text>
          )}

          {is_demand === "collection" && calculate_amount_total()}
        </>
        <div style={{ display: "none" }}>
          <DemandForSubDeptPrint printRef={printRef} id={id} />
        </div>
        <div style={{ display: "none" }} id="dcb-report">
          <DCBReportDownload id={id} printRef={dcbReportRef} />
        </div>
        <>
          {is_demand === "demand" && (
            <>
              <Text>{`This demand was generated on ${formatDate(
                demand?.demand_date
              )} current demand might vary based on member receipts and payments`}</Text>

              <HStack justifyContent={"space-between"} alignItems={"center"}>
                <HStack space="5">
                  {usePageComponentAccess("Society Demand Download PDF") && (
                    <Box>
                      <PDFGenerator
                        data={data}
                        demand={demand || item}
                        id={id}
                      />
                    </Box>
                  )}
                  {usePageComponentAccess("Society Demand Print") && (
                    <Box>
                      <Button
                        onClick={() => {
                          handlePrint();
                        }}
                      >
                        Print
                      </Button>
                    </Box>
                  )}
                  {usePageComponentAccess("Society Demand DCB Report") && (
                    <Box>
                      <Button
                        onClick={() => {
                          onDownload();
                        }}
                      >
                        DCB Report
                      </Button>
                    </Box>
                  )}

                  {/* <Box>
              <Button
                onClick={() => {
                  handleDCBReportPrint();
                }}
              >
                DCB Report Download
              </Button>
            </Box> */}
                </HStack>
                {/* <Box>
                  <Button
                    onClick={() => {
                      handle_sortable_columns();
                    }}
                    type="primary"
                  >
                    Sort
                  </Button>
                </Box> */}
                <Box>
                  <SearchBox
                    label={t("table:Search")}
                    width={{
                      base: "300px",
                      xs: "200px",
                      sm: "200px",
                      md: "300px",
                    }}
                    onSearch={handleSearch}
                  />
                </Box>
              </HStack>
              <Table
                dataSource={data}
                pagination={false}
                bordered
                loading={demand_loading}
                scroll={{ x: true }}
              >
                {tableColumns}
              </Table>
              <HStack justifyContent={"flex-end"} mt="5">
                <Pagination
                  total={demand?.total_count}
                  showSizeChanger={true}
                  page_number={page_number}
                  hideOnSinglePage={false}
                  onChange={fetchData}
                  // defaultPageSize={parseInt(pageSizeOptions[0])}
                  // pageSizeOptions={pageSizeOptions}
                />
              </HStack>
            </>
          )}

          {is_demand === "collection" && (
            <DemandCollectionList
              sub_department_id={sub_department_id}
              id={id}
              collection_amount_modal_ref={collection_amount_modal_ref}
              set_selected_amount={set_selected_amount}
              selected_amount={selected_amount}
              old_balance={payofficer_dueby_items?.[0]?.balance_amount}
              demand_date={demand_month}
            />
          )}
        </>
      </VStack>
      <Modal
        open={columns_model}
        onCancel={handle_close_column_modal}
        title={"Columns sort"}
        width={800}
        footer={null}
      >
        <DynamicSortableColumns
          demand_response={data}
          onclose={handle_close_column_modal}
          is_reset={true}
        />
      </Modal>
    </Box>
  );
};

export default memo(GeneratedDemandForSubDepartment);
