import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { showToast } from "@helpers/toast";
import { useStorageItem } from "@helpers/storage";
//import { logoutRequest } from "../../redux";

// const BASE_URL = "https://dev.vertace.org/ects-sit/api";
const BASE_URL = "https://dev.vertace.org/ects-sa/api";

const httpLink = createHttpLink({
  uri: BASE_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const user = useStorageItem("user");
  return {
    headers: {
      ...headers,
      token: user ? user.token : "",
    },
  };
});

const logout = async () => {
  showToast({
    type: "error",
    message: `Not authorized`,
  });
  await setTimeout(() => {
    window.localStorage.clear();
    window.location.replace("/login");
  }, 2000);
};

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

export async function QueryRequest(query, variables, dispatch) {
  let response = await client.query({
    query,
    variables,
  });
  if (response?.errors?.length !== 0 && response?.errors && response) {
    let isUnauthorized =
      (await JSON.stringify(response).indexOf("Not authorized")) > -1 &&
      (await response?.errors?.[0]?.status_code?.includes("401"));
    if (isUnauthorized && dispatch) {
      logout();
      return null;
    }
  }
  return response;
}
export async function MutateRequest(mutation, variables, dispatch) {
  let response = null;
  try {
    response = await client.mutate({
      mutation,
      variables,
    });
    if (response?.errors?.length !== 0 && response?.errors && response) {
      let isUnauthorized =
        (await JSON.stringify(response).indexOf("Not authorized")) > -1 &&
        (await response?.errors?.[0]?.status_code?.includes("401"));
      if (isUnauthorized && dispatch) {
        logout();
        return null;
      }
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));
    }
    return { error: "Bad Request" };
  }
}
